import { inject, Injectable } from '@angular/core';
import { grpc } from "@improbable-eng/grpc-web";
import { BillingFrequency, OnboardingStatus } from 'app/core/models';
import { AuthService } from 'app/core/services';
import { environment } from 'environments/environment';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { Int64Value, StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import { firstValueFrom } from 'rxjs';
import { CardPaymentMethodSettings, PingResponse, UpdateBusinessRequest } from './grpc/console_webapi_pb';
import { AppApiClient, ServiceError } from './grpc/console_webapi_pb_service';

@Injectable({
  providedIn: 'root'
})
export class ConsoleApi {
  readonly authService = inject(AuthService);
  client = new AppApiClient(environment.apiUrl);

  ping() {
    return this.request<Empty, PingResponse>(
      (req, meta, callback) => this.client.ping(req, meta, callback),
      new Empty());
  }

  updateBusiness(request: UpdateBusinessRequest) {
    return this.request<UpdateBusinessRequest, Empty>(
      (req, meta, callback) => this.client.updateBusiness(req, meta, callback),
      request
    );
  }

  initUpdateBusinessRequest(
    id: string,
    name: string,
    code: string,
    address: string,
    contactEmail: string,
    financeEmail: string,
    disabled: boolean,
    onboardingStatus: number,
    billingFrequency: number,
    cardPaymentSettingsCardPaymentEnabled: boolean,
    priceGroupId: string
  ) {
    const request = new UpdateBusinessRequest();
    const cardPaymentSettings = new CardPaymentMethodSettings();
    cardPaymentSettings.setEnabled(cardPaymentSettingsCardPaymentEnabled);
    request.setId(id);
    request.setName(name);
    request.setCompanyCode(code);
    request.setCompanyAddress(address);
    request.setContactEmail(contactEmail);
    request.setFinanceEmail(financeEmail ? new StringValue().setValue(financeEmail) : undefined);
    request.setDisabled(disabled);
    request.setBillingFrequency(billingFrequency as BillingFrequency);
    request.setOnboardingStatus(onboardingStatus as OnboardingStatus);
    request.setCardPaymentMethodSettings(cardPaymentSettings);
    request.setMontaPriceGroupId(new Int64Value().setValue(parseInt(priceGroupId)));

    request.setUpdateMask(new FieldMask().setPathsList(['name', 'company_code', 'company_address', 'contact_email', 'finance_email', 'disabled', 'billing_frequency', 'onboarding_status', 'card_payment_method_settings', 'monta_price_group_id']));
    return request;
  }

  async request<RequestType, ResponseType>(
    method: (request: RequestType, metadata: grpc.Metadata, callback: (err: ServiceError | null, response: ResponseType | null) => void) => void,
    request: RequestType
  ): Promise<ResponseType | null> {

    const metadata = new grpc.Metadata();
    const token = await firstValueFrom(this.authService.idToken());
    if (token) {
      metadata.append('Authorization', `Bearer ${token}`);
    }

    return new Promise((resolve, reject) => {
      method(request, metadata, (err, response) => {
        if (err) {
          reject(this.handleGrpcError(err));
        } else if (response) {
          resolve(response);
        } else {
          reject('Unknown error');
        }
      });
    });
  }

  // add more error handling here
  private handleGrpcError(error: ServiceError) {
    if (error.code === grpc.Code.Unauthenticated) {
      return 'Unauthenticated';
    }
    return error.message || 'An unknown error occurred';
  }
}
