// source: console_webapi.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_api_annotations_pb = require('./google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
goog.exportSymbol('proto.org.console.web_api.grpc.BillingFrequency', null, global);
goog.exportSymbol('proto.org.console.web_api.grpc.Business', null, global);
goog.exportSymbol('proto.org.console.web_api.grpc.CardPaymentMethodSettings', null, global);
goog.exportSymbol('proto.org.console.web_api.grpc.CreateBusinessRequest', null, global);
goog.exportSymbol('proto.org.console.web_api.grpc.CreateBusinessResponse', null, global);
goog.exportSymbol('proto.org.console.web_api.grpc.DeleteBusinessRequest', null, global);
goog.exportSymbol('proto.org.console.web_api.grpc.Log', null, global);
goog.exportSymbol('proto.org.console.web_api.grpc.LogEntriesRequest', null, global);
goog.exportSymbol('proto.org.console.web_api.grpc.LogLevel', null, global);
goog.exportSymbol('proto.org.console.web_api.grpc.OnboardingStatus', null, global);
goog.exportSymbol('proto.org.console.web_api.grpc.PingResponse', null, global);
goog.exportSymbol('proto.org.console.web_api.grpc.SignInRequest', null, global);
goog.exportSymbol('proto.org.console.web_api.grpc.SignInResponse', null, global);
goog.exportSymbol('proto.org.console.web_api.grpc.UpdateBusinessRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.console.web_api.grpc.PingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.console.web_api.grpc.PingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.console.web_api.grpc.PingResponse.displayName = 'proto.org.console.web_api.grpc.PingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.console.web_api.grpc.SignInRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.console.web_api.grpc.SignInRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.console.web_api.grpc.SignInRequest.displayName = 'proto.org.console.web_api.grpc.SignInRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.console.web_api.grpc.SignInResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.console.web_api.grpc.SignInResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.console.web_api.grpc.SignInResponse.displayName = 'proto.org.console.web_api.grpc.SignInResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.console.web_api.grpc.CreateBusinessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.console.web_api.grpc.CreateBusinessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.console.web_api.grpc.CreateBusinessRequest.displayName = 'proto.org.console.web_api.grpc.CreateBusinessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.console.web_api.grpc.CreateBusinessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.console.web_api.grpc.CreateBusinessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.console.web_api.grpc.CreateBusinessResponse.displayName = 'proto.org.console.web_api.grpc.CreateBusinessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.console.web_api.grpc.Business = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.console.web_api.grpc.Business, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.console.web_api.grpc.Business.displayName = 'proto.org.console.web_api.grpc.Business';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.console.web_api.grpc.UpdateBusinessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.console.web_api.grpc.UpdateBusinessRequest.displayName = 'proto.org.console.web_api.grpc.UpdateBusinessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.console.web_api.grpc.CardPaymentMethodSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.console.web_api.grpc.CardPaymentMethodSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.console.web_api.grpc.CardPaymentMethodSettings.displayName = 'proto.org.console.web_api.grpc.CardPaymentMethodSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.console.web_api.grpc.DeleteBusinessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.console.web_api.grpc.DeleteBusinessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.console.web_api.grpc.DeleteBusinessRequest.displayName = 'proto.org.console.web_api.grpc.DeleteBusinessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.console.web_api.grpc.LogEntriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.console.web_api.grpc.LogEntriesRequest.repeatedFields_, null);
};
goog.inherits(proto.org.console.web_api.grpc.LogEntriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.console.web_api.grpc.LogEntriesRequest.displayName = 'proto.org.console.web_api.grpc.LogEntriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.console.web_api.grpc.Log = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.console.web_api.grpc.Log, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.console.web_api.grpc.Log.displayName = 'proto.org.console.web_api.grpc.Log';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.console.web_api.grpc.PingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.org.console.web_api.grpc.PingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.console.web_api.grpc.PingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.PingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.console.web_api.grpc.PingResponse}
 */
proto.org.console.web_api.grpc.PingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.console.web_api.grpc.PingResponse;
  return proto.org.console.web_api.grpc.PingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.console.web_api.grpc.PingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.console.web_api.grpc.PingResponse}
 */
proto.org.console.web_api.grpc.PingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.console.web_api.grpc.PingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.console.web_api.grpc.PingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.console.web_api.grpc.PingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.PingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.org.console.web_api.grpc.PingResponse.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.PingResponse} returns this
 */
proto.org.console.web_api.grpc.PingResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.console.web_api.grpc.SignInRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.org.console.web_api.grpc.SignInRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.console.web_api.grpc.SignInRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.SignInRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.console.web_api.grpc.SignInRequest}
 */
proto.org.console.web_api.grpc.SignInRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.console.web_api.grpc.SignInRequest;
  return proto.org.console.web_api.grpc.SignInRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.console.web_api.grpc.SignInRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.console.web_api.grpc.SignInRequest}
 */
proto.org.console.web_api.grpc.SignInRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.console.web_api.grpc.SignInRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.console.web_api.grpc.SignInRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.console.web_api.grpc.SignInRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.SignInRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.org.console.web_api.grpc.SignInRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.SignInRequest} returns this
 */
proto.org.console.web_api.grpc.SignInRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.org.console.web_api.grpc.SignInRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.SignInRequest} returns this
 */
proto.org.console.web_api.grpc.SignInRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.console.web_api.grpc.SignInResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.org.console.web_api.grpc.SignInResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.console.web_api.grpc.SignInResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.SignInResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.console.web_api.grpc.SignInResponse}
 */
proto.org.console.web_api.grpc.SignInResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.console.web_api.grpc.SignInResponse;
  return proto.org.console.web_api.grpc.SignInResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.console.web_api.grpc.SignInResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.console.web_api.grpc.SignInResponse}
 */
proto.org.console.web_api.grpc.SignInResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.console.web_api.grpc.SignInResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.console.web_api.grpc.SignInResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.console.web_api.grpc.SignInResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.SignInResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.org.console.web_api.grpc.SignInResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.SignInResponse} returns this
 */
proto.org.console.web_api.grpc.SignInResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.org.console.web_api.grpc.CreateBusinessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.console.web_api.grpc.CreateBusinessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idempotencyKey: (f = msg.getIdempotencyKey()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    companyCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyAddress: jspb.Message.getFieldWithDefault(msg, 4, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    financeEmail: (f = msg.getFinanceEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    montaPriceGroupId: (f = msg.getMontaPriceGroupId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.console.web_api.grpc.CreateBusinessRequest}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.console.web_api.grpc.CreateBusinessRequest;
  return proto.org.console.web_api.grpc.CreateBusinessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.console.web_api.grpc.CreateBusinessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.console.web_api.grpc.CreateBusinessRequest}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setIdempotencyKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFinanceEmail(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setMontaPriceGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.console.web_api.grpc.CreateBusinessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.console.web_api.grpc.CreateBusinessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdempotencyKey();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFinanceEmail();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMontaPriceGroupId();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue idempotency_key = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.getIdempotencyKey = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.console.web_api.grpc.CreateBusinessRequest} returns this
*/
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.setIdempotencyKey = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.console.web_api.grpc.CreateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.clearIdempotencyKey = function() {
  return this.setIdempotencyKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.hasIdempotencyKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.CreateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string company_code = 3;
 * @return {string}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.getCompanyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.CreateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.setCompanyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string company_address = 4;
 * @return {string}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.getCompanyAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.CreateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.setCompanyAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country_code = 5;
 * @return {string}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.CreateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string contact_email = 6;
 * @return {string}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.CreateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.StringValue finance_email = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.getFinanceEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.console.web_api.grpc.CreateBusinessRequest} returns this
*/
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.setFinanceEmail = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.console.web_api.grpc.CreateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.clearFinanceEmail = function() {
  return this.setFinanceEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.hasFinanceEmail = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int64Value monta_price_group_id = 8;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.getMontaPriceGroupId = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.org.console.web_api.grpc.CreateBusinessRequest} returns this
*/
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.setMontaPriceGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.console.web_api.grpc.CreateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.clearMontaPriceGroupId = function() {
  return this.setMontaPriceGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.console.web_api.grpc.CreateBusinessRequest.prototype.hasMontaPriceGroupId = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.console.web_api.grpc.CreateBusinessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.org.console.web_api.grpc.CreateBusinessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.console.web_api.grpc.CreateBusinessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.CreateBusinessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    business: (f = msg.getBusiness()) && proto.org.console.web_api.grpc.Business.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.console.web_api.grpc.CreateBusinessResponse}
 */
proto.org.console.web_api.grpc.CreateBusinessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.console.web_api.grpc.CreateBusinessResponse;
  return proto.org.console.web_api.grpc.CreateBusinessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.console.web_api.grpc.CreateBusinessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.console.web_api.grpc.CreateBusinessResponse}
 */
proto.org.console.web_api.grpc.CreateBusinessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.console.web_api.grpc.Business;
      reader.readMessage(value,proto.org.console.web_api.grpc.Business.deserializeBinaryFromReader);
      msg.setBusiness(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.console.web_api.grpc.CreateBusinessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.console.web_api.grpc.CreateBusinessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.console.web_api.grpc.CreateBusinessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.CreateBusinessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusiness();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.org.console.web_api.grpc.Business.serializeBinaryToWriter
    );
  }
};


/**
 * optional Business business = 1;
 * @return {?proto.org.console.web_api.grpc.Business}
 */
proto.org.console.web_api.grpc.CreateBusinessResponse.prototype.getBusiness = function() {
  return /** @type{?proto.org.console.web_api.grpc.Business} */ (
    jspb.Message.getWrapperField(this, proto.org.console.web_api.grpc.Business, 1));
};


/**
 * @param {?proto.org.console.web_api.grpc.Business|undefined} value
 * @return {!proto.org.console.web_api.grpc.CreateBusinessResponse} returns this
*/
proto.org.console.web_api.grpc.CreateBusinessResponse.prototype.setBusiness = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.console.web_api.grpc.CreateBusinessResponse} returns this
 */
proto.org.console.web_api.grpc.CreateBusinessResponse.prototype.clearBusiness = function() {
  return this.setBusiness(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.console.web_api.grpc.CreateBusinessResponse.prototype.hasBusiness = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.console.web_api.grpc.Business.prototype.toObject = function(opt_includeInstance) {
  return proto.org.console.web_api.grpc.Business.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.console.web_api.grpc.Business} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.Business.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.console.web_api.grpc.Business}
 */
proto.org.console.web_api.grpc.Business.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.console.web_api.grpc.Business;
  return proto.org.console.web_api.grpc.Business.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.console.web_api.grpc.Business} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.console.web_api.grpc.Business}
 */
proto.org.console.web_api.grpc.Business.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.console.web_api.grpc.Business.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.console.web_api.grpc.Business.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.console.web_api.grpc.Business} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.Business.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.org.console.web_api.grpc.Business.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.Business} returns this
 */
proto.org.console.web_api.grpc.Business.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.org.console.web_api.grpc.UpdateBusinessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.console.web_api.grpc.UpdateBusinessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f),
    idempotencyKey: (f = msg.getIdempotencyKey()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    companyCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    companyAddress: jspb.Message.getFieldWithDefault(msg, 7, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 8, ""),
    financeEmail: (f = msg.getFinanceEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    montaPriceGroupId: (f = msg.getMontaPriceGroupId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    onboardingStatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
    disabled: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    cardPaymentMethodSettings: (f = msg.getCardPaymentMethodSettings()) && proto.org.console.web_api.grpc.CardPaymentMethodSettings.toObject(includeInstance, f),
    billingFrequency: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.console.web_api.grpc.UpdateBusinessRequest;
  return proto.org.console.web_api.grpc.UpdateBusinessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.console.web_api.grpc.UpdateBusinessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setIdempotencyKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFinanceEmail(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setMontaPriceGroupId(value);
      break;
    case 11:
      var value = /** @type {!proto.org.console.web_api.grpc.OnboardingStatus} */ (reader.readEnum());
      msg.setOnboardingStatus(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 13:
      var value = new proto.org.console.web_api.grpc.CardPaymentMethodSettings;
      reader.readMessage(value,proto.org.console.web_api.grpc.CardPaymentMethodSettings.deserializeBinaryFromReader);
      msg.setCardPaymentMethodSettings(value);
      break;
    case 14:
      var value = /** @type {!proto.org.console.web_api.grpc.BillingFrequency} */ (reader.readEnum());
      msg.setBillingFrequency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.console.web_api.grpc.UpdateBusinessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.console.web_api.grpc.UpdateBusinessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
  f = message.getIdempotencyKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCompanyCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCompanyAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFinanceEmail();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMontaPriceGroupId();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getOnboardingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getCardPaymentMethodSettings();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.org.console.web_api.grpc.CardPaymentMethodSettings.serializeBinaryToWriter
    );
  }
  f = message.getBillingFrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
};


/**
 * optional google.protobuf.FieldMask update_mask = 1;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 1));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
*/
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue idempotency_key = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getIdempotencyKey = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
*/
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setIdempotencyKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.clearIdempotencyKey = function() {
  return this.setIdempotencyKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.hasIdempotencyKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string company_code = 5;
 * @return {string}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getCompanyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setCompanyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country_code = 6;
 * @return {string}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string company_address = 7;
 * @return {string}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getCompanyAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setCompanyAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string contact_email = 8;
 * @return {string}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.StringValue finance_email = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getFinanceEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
*/
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setFinanceEmail = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.clearFinanceEmail = function() {
  return this.setFinanceEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.hasFinanceEmail = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int64Value monta_price_group_id = 10;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getMontaPriceGroupId = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
*/
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setMontaPriceGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.clearMontaPriceGroupId = function() {
  return this.setMontaPriceGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.hasMontaPriceGroupId = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OnboardingStatus onboarding_status = 11;
 * @return {!proto.org.console.web_api.grpc.OnboardingStatus}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getOnboardingStatus = function() {
  return /** @type {!proto.org.console.web_api.grpc.OnboardingStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.org.console.web_api.grpc.OnboardingStatus} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setOnboardingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional bool disabled = 12;
 * @return {boolean}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional CardPaymentMethodSettings card_payment_method_settings = 13;
 * @return {?proto.org.console.web_api.grpc.CardPaymentMethodSettings}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getCardPaymentMethodSettings = function() {
  return /** @type{?proto.org.console.web_api.grpc.CardPaymentMethodSettings} */ (
    jspb.Message.getWrapperField(this, proto.org.console.web_api.grpc.CardPaymentMethodSettings, 13));
};


/**
 * @param {?proto.org.console.web_api.grpc.CardPaymentMethodSettings|undefined} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
*/
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setCardPaymentMethodSettings = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.clearCardPaymentMethodSettings = function() {
  return this.setCardPaymentMethodSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.hasCardPaymentMethodSettings = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional BillingFrequency billing_frequency = 14;
 * @return {!proto.org.console.web_api.grpc.BillingFrequency}
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.getBillingFrequency = function() {
  return /** @type {!proto.org.console.web_api.grpc.BillingFrequency} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.org.console.web_api.grpc.BillingFrequency} value
 * @return {!proto.org.console.web_api.grpc.UpdateBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.UpdateBusinessRequest.prototype.setBillingFrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.console.web_api.grpc.CardPaymentMethodSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.org.console.web_api.grpc.CardPaymentMethodSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.console.web_api.grpc.CardPaymentMethodSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.CardPaymentMethodSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultCardId: (f = msg.getDefaultCardId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.console.web_api.grpc.CardPaymentMethodSettings}
 */
proto.org.console.web_api.grpc.CardPaymentMethodSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.console.web_api.grpc.CardPaymentMethodSettings;
  return proto.org.console.web_api.grpc.CardPaymentMethodSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.console.web_api.grpc.CardPaymentMethodSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.console.web_api.grpc.CardPaymentMethodSettings}
 */
proto.org.console.web_api.grpc.CardPaymentMethodSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDefaultCardId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.console.web_api.grpc.CardPaymentMethodSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.console.web_api.grpc.CardPaymentMethodSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.console.web_api.grpc.CardPaymentMethodSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.CardPaymentMethodSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultCardId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.StringValue default_card_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.console.web_api.grpc.CardPaymentMethodSettings.prototype.getDefaultCardId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.console.web_api.grpc.CardPaymentMethodSettings} returns this
*/
proto.org.console.web_api.grpc.CardPaymentMethodSettings.prototype.setDefaultCardId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.console.web_api.grpc.CardPaymentMethodSettings} returns this
 */
proto.org.console.web_api.grpc.CardPaymentMethodSettings.prototype.clearDefaultCardId = function() {
  return this.setDefaultCardId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.console.web_api.grpc.CardPaymentMethodSettings.prototype.hasDefaultCardId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool enabled = 3;
 * @return {boolean}
 */
proto.org.console.web_api.grpc.CardPaymentMethodSettings.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.console.web_api.grpc.CardPaymentMethodSettings} returns this
 */
proto.org.console.web_api.grpc.CardPaymentMethodSettings.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.console.web_api.grpc.DeleteBusinessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.org.console.web_api.grpc.DeleteBusinessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.console.web_api.grpc.DeleteBusinessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.DeleteBusinessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    idempotencyKey: (f = msg.getIdempotencyKey()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.console.web_api.grpc.DeleteBusinessRequest}
 */
proto.org.console.web_api.grpc.DeleteBusinessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.console.web_api.grpc.DeleteBusinessRequest;
  return proto.org.console.web_api.grpc.DeleteBusinessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.console.web_api.grpc.DeleteBusinessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.console.web_api.grpc.DeleteBusinessRequest}
 */
proto.org.console.web_api.grpc.DeleteBusinessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setIdempotencyKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.console.web_api.grpc.DeleteBusinessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.console.web_api.grpc.DeleteBusinessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.console.web_api.grpc.DeleteBusinessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.DeleteBusinessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdempotencyKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.org.console.web_api.grpc.DeleteBusinessRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.DeleteBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.DeleteBusinessRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue idempotency_key = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.console.web_api.grpc.DeleteBusinessRequest.prototype.getIdempotencyKey = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.console.web_api.grpc.DeleteBusinessRequest} returns this
*/
proto.org.console.web_api.grpc.DeleteBusinessRequest.prototype.setIdempotencyKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.console.web_api.grpc.DeleteBusinessRequest} returns this
 */
proto.org.console.web_api.grpc.DeleteBusinessRequest.prototype.clearIdempotencyKey = function() {
  return this.setIdempotencyKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.console.web_api.grpc.DeleteBusinessRequest.prototype.hasIdempotencyKey = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.console.web_api.grpc.LogEntriesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.console.web_api.grpc.LogEntriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.org.console.web_api.grpc.LogEntriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.console.web_api.grpc.LogEntriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.LogEntriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    logsList: jspb.Message.toObjectList(msg.getLogsList(),
    proto.org.console.web_api.grpc.Log.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.console.web_api.grpc.LogEntriesRequest}
 */
proto.org.console.web_api.grpc.LogEntriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.console.web_api.grpc.LogEntriesRequest;
  return proto.org.console.web_api.grpc.LogEntriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.console.web_api.grpc.LogEntriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.console.web_api.grpc.LogEntriesRequest}
 */
proto.org.console.web_api.grpc.LogEntriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.console.web_api.grpc.Log;
      reader.readMessage(value,proto.org.console.web_api.grpc.Log.deserializeBinaryFromReader);
      msg.addLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.console.web_api.grpc.LogEntriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.console.web_api.grpc.LogEntriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.console.web_api.grpc.LogEntriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.LogEntriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.console.web_api.grpc.Log.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Log logs = 1;
 * @return {!Array<!proto.org.console.web_api.grpc.Log>}
 */
proto.org.console.web_api.grpc.LogEntriesRequest.prototype.getLogsList = function() {
  return /** @type{!Array<!proto.org.console.web_api.grpc.Log>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.console.web_api.grpc.Log, 1));
};


/**
 * @param {!Array<!proto.org.console.web_api.grpc.Log>} value
 * @return {!proto.org.console.web_api.grpc.LogEntriesRequest} returns this
*/
proto.org.console.web_api.grpc.LogEntriesRequest.prototype.setLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.console.web_api.grpc.Log=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.console.web_api.grpc.Log}
 */
proto.org.console.web_api.grpc.LogEntriesRequest.prototype.addLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.console.web_api.grpc.Log, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.console.web_api.grpc.LogEntriesRequest} returns this
 */
proto.org.console.web_api.grpc.LogEntriesRequest.prototype.clearLogsList = function() {
  return this.setLogsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.console.web_api.grpc.Log.prototype.toObject = function(opt_includeInstance) {
  return proto.org.console.web_api.grpc.Log.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.console.web_api.grpc.Log} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.Log.toObject = function(includeInstance, msg) {
  var f, obj = {
    level: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 3, ""),
    properties: (f = msg.getProperties()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.console.web_api.grpc.Log}
 */
proto.org.console.web_api.grpc.Log.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.console.web_api.grpc.Log;
  return proto.org.console.web_api.grpc.Log.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.console.web_api.grpc.Log} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.console.web_api.grpc.Log}
 */
proto.org.console.web_api.grpc.Log.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.org.console.web_api.grpc.LogLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestamp(value);
      break;
    case 4:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.console.web_api.grpc.Log.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.console.web_api.grpc.Log.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.console.web_api.grpc.Log} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.console.web_api.grpc.Log.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
};


/**
 * optional LogLevel level = 1;
 * @return {!proto.org.console.web_api.grpc.LogLevel}
 */
proto.org.console.web_api.grpc.Log.prototype.getLevel = function() {
  return /** @type {!proto.org.console.web_api.grpc.LogLevel} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.org.console.web_api.grpc.LogLevel} value
 * @return {!proto.org.console.web_api.grpc.Log} returns this
 */
proto.org.console.web_api.grpc.Log.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.org.console.web_api.grpc.Log.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.Log} returns this
 */
proto.org.console.web_api.grpc.Log.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string timestamp = 3;
 * @return {string}
 */
proto.org.console.web_api.grpc.Log.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.console.web_api.grpc.Log} returns this
 */
proto.org.console.web_api.grpc.Log.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Struct properties = 4;
 * @return {?proto.google.protobuf.Struct}
 */
proto.org.console.web_api.grpc.Log.prototype.getProperties = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 4));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.org.console.web_api.grpc.Log} returns this
*/
proto.org.console.web_api.grpc.Log.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.console.web_api.grpc.Log} returns this
 */
proto.org.console.web_api.grpc.Log.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.console.web_api.grpc.Log.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.org.console.web_api.grpc.LogLevel = {
  LOG_LEVEL_UNDEFINED: 0,
  LOG_LEVEL_DEBUG: 1,
  LOG_LEVEL_INFO: 2,
  LOG_LEVEL_WARNING: 3,
  LOG_LEVEL_ERROR: 4
};

/**
 * @enum {number}
 */
proto.org.console.web_api.grpc.BillingFrequency = {
  BILLING_FREQUENCY_UNDEFINED: 0,
  BILLING_FREQUENCY_DAILY: 1,
  BILLING_FREQUENCY_WEEKLY: 2
};

/**
 * @enum {number}
 */
proto.org.console.web_api.grpc.OnboardingStatus = {
  ONBOARDING_STATUS_UNDEFINED: 0,
  ONBOARDING_STATUS_IN_REVIEW: 1,
  ONBOARDING_STATUS_APPROVED: 2,
  ONBOARDING_STATUS_DECLINED: 3,
  ONBOARDING_STATUS_OFFBOARDED: 4
};

goog.object.extend(exports, proto.org.console.web_api.grpc);
