// source: google/protobuf/descriptor.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = typeof globalThis !== 'undefined' && globalThis || typeof window !== 'undefined' && window || typeof global !== 'undefined' && global || typeof self !== 'undefined' && self || function () {
  return this;
}.call(null) || Function('return this')();
goog.exportSymbol('proto.google.protobuf.DescriptorProto', null, global);
goog.exportSymbol('proto.google.protobuf.DescriptorProto.ExtensionRange', null, global);
goog.exportSymbol('proto.google.protobuf.DescriptorProto.ReservedRange', null, global);
goog.exportSymbol('proto.google.protobuf.Edition', null, global);
goog.exportSymbol('proto.google.protobuf.EnumDescriptorProto', null, global);
goog.exportSymbol('proto.google.protobuf.EnumDescriptorProto.EnumReservedRange', null, global);
goog.exportSymbol('proto.google.protobuf.EnumOptions', null, global);
goog.exportSymbol('proto.google.protobuf.EnumValueDescriptorProto', null, global);
goog.exportSymbol('proto.google.protobuf.EnumValueOptions', null, global);
goog.exportSymbol('proto.google.protobuf.ExtensionRangeOptions', null, global);
goog.exportSymbol('proto.google.protobuf.ExtensionRangeOptions.Declaration', null, global);
goog.exportSymbol('proto.google.protobuf.ExtensionRangeOptions.VerificationState', null, global);
goog.exportSymbol('proto.google.protobuf.FeatureSet', null, global);
goog.exportSymbol('proto.google.protobuf.FeatureSet.EnumType', null, global);
goog.exportSymbol('proto.google.protobuf.FeatureSet.FieldPresence', null, global);
goog.exportSymbol('proto.google.protobuf.FeatureSet.JsonFormat', null, global);
goog.exportSymbol('proto.google.protobuf.FeatureSet.MessageEncoding', null, global);
goog.exportSymbol('proto.google.protobuf.FeatureSet.RepeatedFieldEncoding', null, global);
goog.exportSymbol('proto.google.protobuf.FeatureSet.Utf8Validation', null, global);
goog.exportSymbol('proto.google.protobuf.FeatureSetDefaults', null, global);
goog.exportSymbol('proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault', null, global);
goog.exportSymbol('proto.google.protobuf.FieldDescriptorProto', null, global);
goog.exportSymbol('proto.google.protobuf.FieldDescriptorProto.Label', null, global);
goog.exportSymbol('proto.google.protobuf.FieldDescriptorProto.Type', null, global);
goog.exportSymbol('proto.google.protobuf.FieldOptions', null, global);
goog.exportSymbol('proto.google.protobuf.FieldOptions.CType', null, global);
goog.exportSymbol('proto.google.protobuf.FieldOptions.EditionDefault', null, global);
goog.exportSymbol('proto.google.protobuf.FieldOptions.FeatureSupport', null, global);
goog.exportSymbol('proto.google.protobuf.FieldOptions.JSType', null, global);
goog.exportSymbol('proto.google.protobuf.FieldOptions.OptionRetention', null, global);
goog.exportSymbol('proto.google.protobuf.FieldOptions.OptionTargetType', null, global);
goog.exportSymbol('proto.google.protobuf.FileDescriptorProto', null, global);
goog.exportSymbol('proto.google.protobuf.FileDescriptorSet', null, global);
goog.exportSymbol('proto.google.protobuf.FileOptions', null, global);
goog.exportSymbol('proto.google.protobuf.FileOptions.OptimizeMode', null, global);
goog.exportSymbol('proto.google.protobuf.GeneratedCodeInfo', null, global);
goog.exportSymbol('proto.google.protobuf.GeneratedCodeInfo.Annotation', null, global);
goog.exportSymbol('proto.google.protobuf.GeneratedCodeInfo.Annotation.Semantic', null, global);
goog.exportSymbol('proto.google.protobuf.MessageOptions', null, global);
goog.exportSymbol('proto.google.protobuf.MethodDescriptorProto', null, global);
goog.exportSymbol('proto.google.protobuf.MethodOptions', null, global);
goog.exportSymbol('proto.google.protobuf.MethodOptions.IdempotencyLevel', null, global);
goog.exportSymbol('proto.google.protobuf.OneofDescriptorProto', null, global);
goog.exportSymbol('proto.google.protobuf.OneofOptions', null, global);
goog.exportSymbol('proto.google.protobuf.ServiceDescriptorProto', null, global);
goog.exportSymbol('proto.google.protobuf.ServiceOptions', null, global);
goog.exportSymbol('proto.google.protobuf.SourceCodeInfo', null, global);
goog.exportSymbol('proto.google.protobuf.SourceCodeInfo.Location', null, global);
goog.exportSymbol('proto.google.protobuf.UninterpretedOption', null, global);
goog.exportSymbol('proto.google.protobuf.UninterpretedOption.NamePart', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.FileDescriptorSet = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.protobuf.FileDescriptorSet.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.FileDescriptorSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.FileDescriptorSet.displayName = 'proto.google.protobuf.FileDescriptorSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.FileDescriptorProto = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.protobuf.FileDescriptorProto.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.FileDescriptorProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.FileDescriptorProto.displayName = 'proto.google.protobuf.FileDescriptorProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.DescriptorProto = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.protobuf.DescriptorProto.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.DescriptorProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.DescriptorProto.displayName = 'proto.google.protobuf.DescriptorProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.DescriptorProto.ExtensionRange = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.protobuf.DescriptorProto.ExtensionRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.DescriptorProto.ExtensionRange.displayName = 'proto.google.protobuf.DescriptorProto.ExtensionRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.DescriptorProto.ReservedRange = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.protobuf.DescriptorProto.ReservedRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.DescriptorProto.ReservedRange.displayName = 'proto.google.protobuf.DescriptorProto.ReservedRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.ExtensionRangeOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.google.protobuf.ExtensionRangeOptions.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.ExtensionRangeOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.ExtensionRangeOptions.displayName = 'proto.google.protobuf.ExtensionRangeOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.google.protobuf.ExtensionRangeOptions.extensions = {};

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.google.protobuf.ExtensionRangeOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.protobuf.ExtensionRangeOptions.Declaration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.ExtensionRangeOptions.Declaration.displayName = 'proto.google.protobuf.ExtensionRangeOptions.Declaration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.FieldDescriptorProto = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.protobuf.FieldDescriptorProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.FieldDescriptorProto.displayName = 'proto.google.protobuf.FieldDescriptorProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.OneofDescriptorProto = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.protobuf.OneofDescriptorProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.OneofDescriptorProto.displayName = 'proto.google.protobuf.OneofDescriptorProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.EnumDescriptorProto = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.protobuf.EnumDescriptorProto.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.EnumDescriptorProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.EnumDescriptorProto.displayName = 'proto.google.protobuf.EnumDescriptorProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.EnumDescriptorProto.EnumReservedRange = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.protobuf.EnumDescriptorProto.EnumReservedRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.displayName = 'proto.google.protobuf.EnumDescriptorProto.EnumReservedRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.EnumValueDescriptorProto = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.protobuf.EnumValueDescriptorProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.EnumValueDescriptorProto.displayName = 'proto.google.protobuf.EnumValueDescriptorProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.ServiceDescriptorProto = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.protobuf.ServiceDescriptorProto.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.ServiceDescriptorProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.ServiceDescriptorProto.displayName = 'proto.google.protobuf.ServiceDescriptorProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.MethodDescriptorProto = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.protobuf.MethodDescriptorProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.MethodDescriptorProto.displayName = 'proto.google.protobuf.MethodDescriptorProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.FileOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.google.protobuf.FileOptions.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.FileOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.FileOptions.displayName = 'proto.google.protobuf.FileOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.google.protobuf.FileOptions.extensions = {};

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.google.protobuf.FileOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.MessageOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.google.protobuf.MessageOptions.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.MessageOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.MessageOptions.displayName = 'proto.google.protobuf.MessageOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.google.protobuf.MessageOptions.extensions = {};

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.google.protobuf.MessageOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.FieldOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.google.protobuf.FieldOptions.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.FieldOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.FieldOptions.displayName = 'proto.google.protobuf.FieldOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.google.protobuf.FieldOptions.extensions = {};

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.google.protobuf.FieldOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.FieldOptions.EditionDefault = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.protobuf.FieldOptions.EditionDefault, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.FieldOptions.EditionDefault.displayName = 'proto.google.protobuf.FieldOptions.EditionDefault';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.FieldOptions.FeatureSupport = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.protobuf.FieldOptions.FeatureSupport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.FieldOptions.FeatureSupport.displayName = 'proto.google.protobuf.FieldOptions.FeatureSupport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.OneofOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.google.protobuf.OneofOptions.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.OneofOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.OneofOptions.displayName = 'proto.google.protobuf.OneofOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.google.protobuf.OneofOptions.extensions = {};

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.google.protobuf.OneofOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.EnumOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.google.protobuf.EnumOptions.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.EnumOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.EnumOptions.displayName = 'proto.google.protobuf.EnumOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.google.protobuf.EnumOptions.extensions = {};

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.google.protobuf.EnumOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.EnumValueOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.google.protobuf.EnumValueOptions.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.EnumValueOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.EnumValueOptions.displayName = 'proto.google.protobuf.EnumValueOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.google.protobuf.EnumValueOptions.extensions = {};

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.google.protobuf.EnumValueOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.ServiceOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.google.protobuf.ServiceOptions.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.ServiceOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.ServiceOptions.displayName = 'proto.google.protobuf.ServiceOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.google.protobuf.ServiceOptions.extensions = {};

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.google.protobuf.ServiceOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.MethodOptions = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.google.protobuf.MethodOptions.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.MethodOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.MethodOptions.displayName = 'proto.google.protobuf.MethodOptions';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.google.protobuf.MethodOptions.extensions = {};

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.google.protobuf.MethodOptions.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.UninterpretedOption = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.protobuf.UninterpretedOption.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.UninterpretedOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.UninterpretedOption.displayName = 'proto.google.protobuf.UninterpretedOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.UninterpretedOption.NamePart = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.protobuf.UninterpretedOption.NamePart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.UninterpretedOption.NamePart.displayName = 'proto.google.protobuf.UninterpretedOption.NamePart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.FeatureSet = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 7, null, null);
};
goog.inherits(proto.google.protobuf.FeatureSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.FeatureSet.displayName = 'proto.google.protobuf.FeatureSet';
}

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldInfo>}
 */
proto.google.protobuf.FeatureSet.extensions = {};

/**
 * The extensions registered with this message class. This is a map of
 * extension field number to fieldInfo object.
 *
 * For example:
 *     { 123: {fieldIndex: 123, fieldName: {my_field_name: 0}, ctor: proto.example.MyMessage} }
 *
 * fieldName contains the JsCompiler renamed field name property so that it
 * works in OPTIMIZED mode.
 *
 * @type {!Object<number, jspb.ExtensionFieldBinaryInfo>}
 */
proto.google.protobuf.FeatureSet.extensionsBinary = {};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.FeatureSetDefaults = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.protobuf.FeatureSetDefaults.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.FeatureSetDefaults, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.FeatureSetDefaults.displayName = 'proto.google.protobuf.FeatureSetDefaults';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.displayName = 'proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.SourceCodeInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.protobuf.SourceCodeInfo.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.SourceCodeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.SourceCodeInfo.displayName = 'proto.google.protobuf.SourceCodeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.SourceCodeInfo.Location = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.protobuf.SourceCodeInfo.Location.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.SourceCodeInfo.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.SourceCodeInfo.Location.displayName = 'proto.google.protobuf.SourceCodeInfo.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.GeneratedCodeInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.protobuf.GeneratedCodeInfo.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.GeneratedCodeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.GeneratedCodeInfo.displayName = 'proto.google.protobuf.GeneratedCodeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.google.protobuf.GeneratedCodeInfo.Annotation.repeatedFields_, null);
};
goog.inherits(proto.google.protobuf.GeneratedCodeInfo.Annotation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.google.protobuf.GeneratedCodeInfo.Annotation.displayName = 'proto.google.protobuf.GeneratedCodeInfo.Annotation';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.FileDescriptorSet.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.FileDescriptorSet.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.FileDescriptorSet.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.FileDescriptorSet} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.FileDescriptorSet.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        fileList: jspb.Message.toObjectList(msg.getFileList(), proto.google.protobuf.FileDescriptorProto.toObject, includeInstance)
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.FileDescriptorSet}
 */
proto.google.protobuf.FileDescriptorSet.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.FileDescriptorSet();
  return proto.google.protobuf.FileDescriptorSet.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.FileDescriptorSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.FileDescriptorSet}
 */
proto.google.protobuf.FileDescriptorSet.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.google.protobuf.FileDescriptorProto();
        reader.readMessage(value, proto.google.protobuf.FileDescriptorProto.deserializeBinaryFromReader);
        msg.addFile(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.FileDescriptorSet.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.FileDescriptorSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.FileDescriptorSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.FileDescriptorSet.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFileList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, proto.google.protobuf.FileDescriptorProto.serializeBinaryToWriter);
  }
};

/**
 * repeated FileDescriptorProto file = 1;
 * @return {!Array<!proto.google.protobuf.FileDescriptorProto>}
 */
proto.google.protobuf.FileDescriptorSet.prototype.getFileList = function () {
  return /** @type{!Array<!proto.google.protobuf.FileDescriptorProto>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.FileDescriptorProto, 1)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.FileDescriptorProto>} value
 * @return {!proto.google.protobuf.FileDescriptorSet} returns this
*/
proto.google.protobuf.FileDescriptorSet.prototype.setFileList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.google.protobuf.FileDescriptorProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.FileDescriptorProto}
 */
proto.google.protobuf.FileDescriptorSet.prototype.addFile = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.protobuf.FileDescriptorProto, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.FileDescriptorSet} returns this
 */
proto.google.protobuf.FileDescriptorSet.prototype.clearFileList = function () {
  return this.setFileList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.FileDescriptorProto.repeatedFields_ = [3, 10, 11, 4, 5, 6, 7];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.FileDescriptorProto.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.FileDescriptorProto.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.FileDescriptorProto} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.FileDescriptorProto.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        pb_package: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        dependencyList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
        publicDependencyList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
        weakDependencyList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
        messageTypeList: jspb.Message.toObjectList(msg.getMessageTypeList(), proto.google.protobuf.DescriptorProto.toObject, includeInstance),
        enumTypeList: jspb.Message.toObjectList(msg.getEnumTypeList(), proto.google.protobuf.EnumDescriptorProto.toObject, includeInstance),
        serviceList: jspb.Message.toObjectList(msg.getServiceList(), proto.google.protobuf.ServiceDescriptorProto.toObject, includeInstance),
        extensionList: jspb.Message.toObjectList(msg.getExtensionList(), proto.google.protobuf.FieldDescriptorProto.toObject, includeInstance),
        options: (f = msg.getOptions()) && proto.google.protobuf.FileOptions.toObject(includeInstance, f),
        sourceCodeInfo: (f = msg.getSourceCodeInfo()) && proto.google.protobuf.SourceCodeInfo.toObject(includeInstance, f),
        syntax: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
        edition: (f = jspb.Message.getField(msg, 14)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.FileDescriptorProto}
 */
proto.google.protobuf.FileDescriptorProto.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.FileDescriptorProto();
  return proto.google.protobuf.FileDescriptorProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.FileDescriptorProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.FileDescriptorProto}
 */
proto.google.protobuf.FileDescriptorProto.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */reader.readString();
        msg.setPackage(value);
        break;
      case 3:
        var value = /** @type {string} */reader.readString();
        msg.addDependency(value);
        break;
      case 10:
        var values = /** @type {!Array<number>} */reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()];
        for (var i = 0; i < values.length; i++) {
          msg.addPublicDependency(values[i]);
        }
        break;
      case 11:
        var values = /** @type {!Array<number>} */reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()];
        for (var i = 0; i < values.length; i++) {
          msg.addWeakDependency(values[i]);
        }
        break;
      case 4:
        var value = new proto.google.protobuf.DescriptorProto();
        reader.readMessage(value, proto.google.protobuf.DescriptorProto.deserializeBinaryFromReader);
        msg.addMessageType(value);
        break;
      case 5:
        var value = new proto.google.protobuf.EnumDescriptorProto();
        reader.readMessage(value, proto.google.protobuf.EnumDescriptorProto.deserializeBinaryFromReader);
        msg.addEnumType(value);
        break;
      case 6:
        var value = new proto.google.protobuf.ServiceDescriptorProto();
        reader.readMessage(value, proto.google.protobuf.ServiceDescriptorProto.deserializeBinaryFromReader);
        msg.addService(value);
        break;
      case 7:
        var value = new proto.google.protobuf.FieldDescriptorProto();
        reader.readMessage(value, proto.google.protobuf.FieldDescriptorProto.deserializeBinaryFromReader);
        msg.addExtension$(value);
        break;
      case 8:
        var value = new proto.google.protobuf.FileOptions();
        reader.readMessage(value, proto.google.protobuf.FileOptions.deserializeBinaryFromReader);
        msg.setOptions(value);
        break;
      case 9:
        var value = new proto.google.protobuf.SourceCodeInfo();
        reader.readMessage(value, proto.google.protobuf.SourceCodeInfo.deserializeBinaryFromReader);
        msg.setSourceCodeInfo(value);
        break;
      case 12:
        var value = /** @type {string} */reader.readString();
        msg.setSyntax(value);
        break;
      case 14:
        var value = /** @type {!proto.google.protobuf.Edition} */reader.readEnum();
        msg.setEdition(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.FileDescriptorProto.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.FileDescriptorProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.FileDescriptorProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.FileDescriptorProto.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeString(1, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeString(2, f);
  }
  f = message.getDependencyList();
  if (f.length > 0) {
    writer.writeRepeatedString(3, f);
  }
  f = message.getPublicDependencyList();
  if (f.length > 0) {
    writer.writeRepeatedInt32(10, f);
  }
  f = message.getWeakDependencyList();
  if (f.length > 0) {
    writer.writeRepeatedInt32(11, f);
  }
  f = message.getMessageTypeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(4, f, proto.google.protobuf.DescriptorProto.serializeBinaryToWriter);
  }
  f = message.getEnumTypeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(5, f, proto.google.protobuf.EnumDescriptorProto.serializeBinaryToWriter);
  }
  f = message.getServiceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(6, f, proto.google.protobuf.ServiceDescriptorProto.serializeBinaryToWriter);
  }
  f = message.getExtensionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(7, f, proto.google.protobuf.FieldDescriptorProto.serializeBinaryToWriter);
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(8, f, proto.google.protobuf.FileOptions.serializeBinaryToWriter);
  }
  f = message.getSourceCodeInfo();
  if (f != null) {
    writer.writeMessage(9, f, proto.google.protobuf.SourceCodeInfo.serializeBinaryToWriter);
  }
  f = /** @type {string} */jspb.Message.getField(message, 12);
  if (f != null) {
    writer.writeString(12, f);
  }
  f = /** @type {!proto.google.protobuf.Edition} */jspb.Message.getField(message, 14);
  if (f != null) {
    writer.writeEnum(14, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.google.protobuf.FileDescriptorProto.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.setName = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.clearName = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileDescriptorProto.prototype.hasName = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string package = 2;
 * @return {string}
 */
proto.google.protobuf.FileDescriptorProto.prototype.getPackage = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 2, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.setPackage = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.clearPackage = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileDescriptorProto.prototype.hasPackage = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * repeated string dependency = 3;
 * @return {!Array<string>}
 */
proto.google.protobuf.FileDescriptorProto.prototype.getDependencyList = function () {
  return /** @type {!Array<string>} */jspb.Message.getRepeatedField(this, 3);
};

/**
 * @param {!Array<string>} value
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.setDependencyList = function (value) {
  return jspb.Message.setField(this, 3, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.addDependency = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.clearDependencyList = function () {
  return this.setDependencyList([]);
};

/**
 * repeated int32 public_dependency = 10;
 * @return {!Array<number>}
 */
proto.google.protobuf.FileDescriptorProto.prototype.getPublicDependencyList = function () {
  return /** @type {!Array<number>} */jspb.Message.getRepeatedField(this, 10);
};

/**
 * @param {!Array<number>} value
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.setPublicDependencyList = function (value) {
  return jspb.Message.setField(this, 10, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.addPublicDependency = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.clearPublicDependencyList = function () {
  return this.setPublicDependencyList([]);
};

/**
 * repeated int32 weak_dependency = 11;
 * @return {!Array<number>}
 */
proto.google.protobuf.FileDescriptorProto.prototype.getWeakDependencyList = function () {
  return /** @type {!Array<number>} */jspb.Message.getRepeatedField(this, 11);
};

/**
 * @param {!Array<number>} value
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.setWeakDependencyList = function (value) {
  return jspb.Message.setField(this, 11, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.addWeakDependency = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.clearWeakDependencyList = function () {
  return this.setWeakDependencyList([]);
};

/**
 * repeated DescriptorProto message_type = 4;
 * @return {!Array<!proto.google.protobuf.DescriptorProto>}
 */
proto.google.protobuf.FileDescriptorProto.prototype.getMessageTypeList = function () {
  return /** @type{!Array<!proto.google.protobuf.DescriptorProto>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.DescriptorProto, 4)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.DescriptorProto>} value
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
*/
proto.google.protobuf.FileDescriptorProto.prototype.setMessageTypeList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};

/**
 * @param {!proto.google.protobuf.DescriptorProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.DescriptorProto}
 */
proto.google.protobuf.FileDescriptorProto.prototype.addMessageType = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.protobuf.DescriptorProto, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.clearMessageTypeList = function () {
  return this.setMessageTypeList([]);
};

/**
 * repeated EnumDescriptorProto enum_type = 5;
 * @return {!Array<!proto.google.protobuf.EnumDescriptorProto>}
 */
proto.google.protobuf.FileDescriptorProto.prototype.getEnumTypeList = function () {
  return /** @type{!Array<!proto.google.protobuf.EnumDescriptorProto>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.EnumDescriptorProto, 5)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.EnumDescriptorProto>} value
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
*/
proto.google.protobuf.FileDescriptorProto.prototype.setEnumTypeList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};

/**
 * @param {!proto.google.protobuf.EnumDescriptorProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.EnumDescriptorProto}
 */
proto.google.protobuf.FileDescriptorProto.prototype.addEnumType = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.protobuf.EnumDescriptorProto, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.clearEnumTypeList = function () {
  return this.setEnumTypeList([]);
};

/**
 * repeated ServiceDescriptorProto service = 6;
 * @return {!Array<!proto.google.protobuf.ServiceDescriptorProto>}
 */
proto.google.protobuf.FileDescriptorProto.prototype.getServiceList = function () {
  return /** @type{!Array<!proto.google.protobuf.ServiceDescriptorProto>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.ServiceDescriptorProto, 6)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.ServiceDescriptorProto>} value
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
*/
proto.google.protobuf.FileDescriptorProto.prototype.setServiceList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};

/**
 * @param {!proto.google.protobuf.ServiceDescriptorProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.ServiceDescriptorProto}
 */
proto.google.protobuf.FileDescriptorProto.prototype.addService = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.google.protobuf.ServiceDescriptorProto, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.clearServiceList = function () {
  return this.setServiceList([]);
};

/**
 * repeated FieldDescriptorProto extension = 7;
 * @return {!Array<!proto.google.protobuf.FieldDescriptorProto>}
 */
proto.google.protobuf.FileDescriptorProto.prototype.getExtensionList = function () {
  return /** @type{!Array<!proto.google.protobuf.FieldDescriptorProto>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.FieldDescriptorProto, 7)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.FieldDescriptorProto>} value
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
*/
proto.google.protobuf.FileDescriptorProto.prototype.setExtensionList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};

/**
 * @param {!proto.google.protobuf.FieldDescriptorProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.FieldDescriptorProto}
 */
proto.google.protobuf.FileDescriptorProto.prototype.addExtension$ = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.google.protobuf.FieldDescriptorProto, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.clearExtensionList = function () {
  return this.setExtensionList([]);
};

/**
 * optional FileOptions options = 8;
 * @return {?proto.google.protobuf.FileOptions}
 */
proto.google.protobuf.FileDescriptorProto.prototype.getOptions = function () {
  return /** @type{?proto.google.protobuf.FileOptions} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FileOptions, 8)
  );
};

/**
 * @param {?proto.google.protobuf.FileOptions|undefined} value
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
*/
proto.google.protobuf.FileDescriptorProto.prototype.setOptions = function (value) {
  return jspb.Message.setWrapperField(this, 8, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.clearOptions = function () {
  return this.setOptions(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileDescriptorProto.prototype.hasOptions = function () {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional SourceCodeInfo source_code_info = 9;
 * @return {?proto.google.protobuf.SourceCodeInfo}
 */
proto.google.protobuf.FileDescriptorProto.prototype.getSourceCodeInfo = function () {
  return /** @type{?proto.google.protobuf.SourceCodeInfo} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.SourceCodeInfo, 9)
  );
};

/**
 * @param {?proto.google.protobuf.SourceCodeInfo|undefined} value
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
*/
proto.google.protobuf.FileDescriptorProto.prototype.setSourceCodeInfo = function (value) {
  return jspb.Message.setWrapperField(this, 9, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.clearSourceCodeInfo = function () {
  return this.setSourceCodeInfo(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileDescriptorProto.prototype.hasSourceCodeInfo = function () {
  return jspb.Message.getField(this, 9) != null;
};

/**
 * optional string syntax = 12;
 * @return {string}
 */
proto.google.protobuf.FileDescriptorProto.prototype.getSyntax = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 12, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.setSyntax = function (value) {
  return jspb.Message.setField(this, 12, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.clearSyntax = function () {
  return jspb.Message.setField(this, 12, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileDescriptorProto.prototype.hasSyntax = function () {
  return jspb.Message.getField(this, 12) != null;
};

/**
 * optional Edition edition = 14;
 * @return {!proto.google.protobuf.Edition}
 */
proto.google.protobuf.FileDescriptorProto.prototype.getEdition = function () {
  return /** @type {!proto.google.protobuf.Edition} */jspb.Message.getFieldWithDefault(this, 14, 0);
};

/**
 * @param {!proto.google.protobuf.Edition} value
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.setEdition = function (value) {
  return jspb.Message.setField(this, 14, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileDescriptorProto} returns this
 */
proto.google.protobuf.FileDescriptorProto.prototype.clearEdition = function () {
  return jspb.Message.setField(this, 14, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileDescriptorProto.prototype.hasEdition = function () {
  return jspb.Message.getField(this, 14) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.DescriptorProto.repeatedFields_ = [2, 6, 3, 4, 5, 8, 9, 10];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.DescriptorProto.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.DescriptorProto.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.DescriptorProto} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.DescriptorProto.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        fieldList: jspb.Message.toObjectList(msg.getFieldList(), proto.google.protobuf.FieldDescriptorProto.toObject, includeInstance),
        extensionList: jspb.Message.toObjectList(msg.getExtensionList(), proto.google.protobuf.FieldDescriptorProto.toObject, includeInstance),
        nestedTypeList: jspb.Message.toObjectList(msg.getNestedTypeList(), proto.google.protobuf.DescriptorProto.toObject, includeInstance),
        enumTypeList: jspb.Message.toObjectList(msg.getEnumTypeList(), proto.google.protobuf.EnumDescriptorProto.toObject, includeInstance),
        extensionRangeList: jspb.Message.toObjectList(msg.getExtensionRangeList(), proto.google.protobuf.DescriptorProto.ExtensionRange.toObject, includeInstance),
        oneofDeclList: jspb.Message.toObjectList(msg.getOneofDeclList(), proto.google.protobuf.OneofDescriptorProto.toObject, includeInstance),
        options: (f = msg.getOptions()) && proto.google.protobuf.MessageOptions.toObject(includeInstance, f),
        reservedRangeList: jspb.Message.toObjectList(msg.getReservedRangeList(), proto.google.protobuf.DescriptorProto.ReservedRange.toObject, includeInstance),
        reservedNameList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.DescriptorProto}
 */
proto.google.protobuf.DescriptorProto.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.DescriptorProto();
  return proto.google.protobuf.DescriptorProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.DescriptorProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.DescriptorProto}
 */
proto.google.protobuf.DescriptorProto.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      case 2:
        var value = new proto.google.protobuf.FieldDescriptorProto();
        reader.readMessage(value, proto.google.protobuf.FieldDescriptorProto.deserializeBinaryFromReader);
        msg.addField(value);
        break;
      case 6:
        var value = new proto.google.protobuf.FieldDescriptorProto();
        reader.readMessage(value, proto.google.protobuf.FieldDescriptorProto.deserializeBinaryFromReader);
        msg.addExtension$(value);
        break;
      case 3:
        var value = new proto.google.protobuf.DescriptorProto();
        reader.readMessage(value, proto.google.protobuf.DescriptorProto.deserializeBinaryFromReader);
        msg.addNestedType(value);
        break;
      case 4:
        var value = new proto.google.protobuf.EnumDescriptorProto();
        reader.readMessage(value, proto.google.protobuf.EnumDescriptorProto.deserializeBinaryFromReader);
        msg.addEnumType(value);
        break;
      case 5:
        var value = new proto.google.protobuf.DescriptorProto.ExtensionRange();
        reader.readMessage(value, proto.google.protobuf.DescriptorProto.ExtensionRange.deserializeBinaryFromReader);
        msg.addExtensionRange(value);
        break;
      case 8:
        var value = new proto.google.protobuf.OneofDescriptorProto();
        reader.readMessage(value, proto.google.protobuf.OneofDescriptorProto.deserializeBinaryFromReader);
        msg.addOneofDecl(value);
        break;
      case 7:
        var value = new proto.google.protobuf.MessageOptions();
        reader.readMessage(value, proto.google.protobuf.MessageOptions.deserializeBinaryFromReader);
        msg.setOptions(value);
        break;
      case 9:
        var value = new proto.google.protobuf.DescriptorProto.ReservedRange();
        reader.readMessage(value, proto.google.protobuf.DescriptorProto.ReservedRange.deserializeBinaryFromReader);
        msg.addReservedRange(value);
        break;
      case 10:
        var value = /** @type {string} */reader.readString();
        msg.addReservedName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.DescriptorProto.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.DescriptorProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.DescriptorProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.DescriptorProto.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeString(1, f);
  }
  f = message.getFieldList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, proto.google.protobuf.FieldDescriptorProto.serializeBinaryToWriter);
  }
  f = message.getExtensionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(6, f, proto.google.protobuf.FieldDescriptorProto.serializeBinaryToWriter);
  }
  f = message.getNestedTypeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.google.protobuf.DescriptorProto.serializeBinaryToWriter);
  }
  f = message.getEnumTypeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(4, f, proto.google.protobuf.EnumDescriptorProto.serializeBinaryToWriter);
  }
  f = message.getExtensionRangeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(5, f, proto.google.protobuf.DescriptorProto.ExtensionRange.serializeBinaryToWriter);
  }
  f = message.getOneofDeclList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(8, f, proto.google.protobuf.OneofDescriptorProto.serializeBinaryToWriter);
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(7, f, proto.google.protobuf.MessageOptions.serializeBinaryToWriter);
  }
  f = message.getReservedRangeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(9, f, proto.google.protobuf.DescriptorProto.ReservedRange.serializeBinaryToWriter);
  }
  f = message.getReservedNameList();
  if (f.length > 0) {
    writer.writeRepeatedString(10, f);
  }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.DescriptorProto.ExtensionRange.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.DescriptorProto.ExtensionRange} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.DescriptorProto.ExtensionRange.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        start: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        end: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        options: (f = msg.getOptions()) && proto.google.protobuf.ExtensionRangeOptions.toObject(includeInstance, f)
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.DescriptorProto.ExtensionRange}
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.DescriptorProto.ExtensionRange();
  return proto.google.protobuf.DescriptorProto.ExtensionRange.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.DescriptorProto.ExtensionRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.DescriptorProto.ExtensionRange}
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */reader.readInt32();
        msg.setStart(value);
        break;
      case 2:
        var value = /** @type {number} */reader.readInt32();
        msg.setEnd(value);
        break;
      case 3:
        var value = new proto.google.protobuf.ExtensionRangeOptions();
        reader.readMessage(value, proto.google.protobuf.ExtensionRangeOptions.deserializeBinaryFromReader);
        msg.setOptions(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.DescriptorProto.ExtensionRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.DescriptorProto.ExtensionRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {number} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeInt32(1, f);
  }
  f = /** @type {number} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeInt32(2, f);
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(3, f, proto.google.protobuf.ExtensionRangeOptions.serializeBinaryToWriter);
  }
};

/**
 * optional int32 start = 1;
 * @return {number}
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.getStart = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 1, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.DescriptorProto.ExtensionRange} returns this
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.setStart = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.DescriptorProto.ExtensionRange} returns this
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.clearStart = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.hasStart = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional int32 end = 2;
 * @return {number}
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.getEnd = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 2, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.DescriptorProto.ExtensionRange} returns this
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.setEnd = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.DescriptorProto.ExtensionRange} returns this
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.clearEnd = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.hasEnd = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional ExtensionRangeOptions options = 3;
 * @return {?proto.google.protobuf.ExtensionRangeOptions}
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.getOptions = function () {
  return /** @type{?proto.google.protobuf.ExtensionRangeOptions} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.ExtensionRangeOptions, 3)
  );
};

/**
 * @param {?proto.google.protobuf.ExtensionRangeOptions|undefined} value
 * @return {!proto.google.protobuf.DescriptorProto.ExtensionRange} returns this
*/
proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.setOptions = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.DescriptorProto.ExtensionRange} returns this
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.clearOptions = function () {
  return this.setOptions(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.DescriptorProto.ExtensionRange.prototype.hasOptions = function () {
  return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.DescriptorProto.ReservedRange.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.DescriptorProto.ReservedRange.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.DescriptorProto.ReservedRange} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.DescriptorProto.ReservedRange.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        start: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        end: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.DescriptorProto.ReservedRange}
 */
proto.google.protobuf.DescriptorProto.ReservedRange.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.DescriptorProto.ReservedRange();
  return proto.google.protobuf.DescriptorProto.ReservedRange.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.DescriptorProto.ReservedRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.DescriptorProto.ReservedRange}
 */
proto.google.protobuf.DescriptorProto.ReservedRange.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */reader.readInt32();
        msg.setStart(value);
        break;
      case 2:
        var value = /** @type {number} */reader.readInt32();
        msg.setEnd(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.DescriptorProto.ReservedRange.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.DescriptorProto.ReservedRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.DescriptorProto.ReservedRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.DescriptorProto.ReservedRange.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {number} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeInt32(1, f);
  }
  f = /** @type {number} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeInt32(2, f);
  }
};

/**
 * optional int32 start = 1;
 * @return {number}
 */
proto.google.protobuf.DescriptorProto.ReservedRange.prototype.getStart = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 1, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.DescriptorProto.ReservedRange} returns this
 */
proto.google.protobuf.DescriptorProto.ReservedRange.prototype.setStart = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.DescriptorProto.ReservedRange} returns this
 */
proto.google.protobuf.DescriptorProto.ReservedRange.prototype.clearStart = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.DescriptorProto.ReservedRange.prototype.hasStart = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional int32 end = 2;
 * @return {number}
 */
proto.google.protobuf.DescriptorProto.ReservedRange.prototype.getEnd = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 2, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.DescriptorProto.ReservedRange} returns this
 */
proto.google.protobuf.DescriptorProto.ReservedRange.prototype.setEnd = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.DescriptorProto.ReservedRange} returns this
 */
proto.google.protobuf.DescriptorProto.ReservedRange.prototype.clearEnd = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.DescriptorProto.ReservedRange.prototype.hasEnd = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.google.protobuf.DescriptorProto.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.DescriptorProto} returns this
 */
proto.google.protobuf.DescriptorProto.prototype.setName = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.DescriptorProto} returns this
 */
proto.google.protobuf.DescriptorProto.prototype.clearName = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.DescriptorProto.prototype.hasName = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * repeated FieldDescriptorProto field = 2;
 * @return {!Array<!proto.google.protobuf.FieldDescriptorProto>}
 */
proto.google.protobuf.DescriptorProto.prototype.getFieldList = function () {
  return /** @type{!Array<!proto.google.protobuf.FieldDescriptorProto>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.FieldDescriptorProto, 2)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.FieldDescriptorProto>} value
 * @return {!proto.google.protobuf.DescriptorProto} returns this
*/
proto.google.protobuf.DescriptorProto.prototype.setFieldList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.google.protobuf.FieldDescriptorProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.FieldDescriptorProto}
 */
proto.google.protobuf.DescriptorProto.prototype.addField = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.protobuf.FieldDescriptorProto, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.DescriptorProto} returns this
 */
proto.google.protobuf.DescriptorProto.prototype.clearFieldList = function () {
  return this.setFieldList([]);
};

/**
 * repeated FieldDescriptorProto extension = 6;
 * @return {!Array<!proto.google.protobuf.FieldDescriptorProto>}
 */
proto.google.protobuf.DescriptorProto.prototype.getExtensionList = function () {
  return /** @type{!Array<!proto.google.protobuf.FieldDescriptorProto>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.FieldDescriptorProto, 6)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.FieldDescriptorProto>} value
 * @return {!proto.google.protobuf.DescriptorProto} returns this
*/
proto.google.protobuf.DescriptorProto.prototype.setExtensionList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};

/**
 * @param {!proto.google.protobuf.FieldDescriptorProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.FieldDescriptorProto}
 */
proto.google.protobuf.DescriptorProto.prototype.addExtension$ = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.google.protobuf.FieldDescriptorProto, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.DescriptorProto} returns this
 */
proto.google.protobuf.DescriptorProto.prototype.clearExtensionList = function () {
  return this.setExtensionList([]);
};

/**
 * repeated DescriptorProto nested_type = 3;
 * @return {!Array<!proto.google.protobuf.DescriptorProto>}
 */
proto.google.protobuf.DescriptorProto.prototype.getNestedTypeList = function () {
  return /** @type{!Array<!proto.google.protobuf.DescriptorProto>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.DescriptorProto, 3)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.DescriptorProto>} value
 * @return {!proto.google.protobuf.DescriptorProto} returns this
*/
proto.google.protobuf.DescriptorProto.prototype.setNestedTypeList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.google.protobuf.DescriptorProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.DescriptorProto}
 */
proto.google.protobuf.DescriptorProto.prototype.addNestedType = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.protobuf.DescriptorProto, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.DescriptorProto} returns this
 */
proto.google.protobuf.DescriptorProto.prototype.clearNestedTypeList = function () {
  return this.setNestedTypeList([]);
};

/**
 * repeated EnumDescriptorProto enum_type = 4;
 * @return {!Array<!proto.google.protobuf.EnumDescriptorProto>}
 */
proto.google.protobuf.DescriptorProto.prototype.getEnumTypeList = function () {
  return /** @type{!Array<!proto.google.protobuf.EnumDescriptorProto>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.EnumDescriptorProto, 4)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.EnumDescriptorProto>} value
 * @return {!proto.google.protobuf.DescriptorProto} returns this
*/
proto.google.protobuf.DescriptorProto.prototype.setEnumTypeList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};

/**
 * @param {!proto.google.protobuf.EnumDescriptorProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.EnumDescriptorProto}
 */
proto.google.protobuf.DescriptorProto.prototype.addEnumType = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.protobuf.EnumDescriptorProto, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.DescriptorProto} returns this
 */
proto.google.protobuf.DescriptorProto.prototype.clearEnumTypeList = function () {
  return this.setEnumTypeList([]);
};

/**
 * repeated ExtensionRange extension_range = 5;
 * @return {!Array<!proto.google.protobuf.DescriptorProto.ExtensionRange>}
 */
proto.google.protobuf.DescriptorProto.prototype.getExtensionRangeList = function () {
  return /** @type{!Array<!proto.google.protobuf.DescriptorProto.ExtensionRange>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.DescriptorProto.ExtensionRange, 5)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.DescriptorProto.ExtensionRange>} value
 * @return {!proto.google.protobuf.DescriptorProto} returns this
*/
proto.google.protobuf.DescriptorProto.prototype.setExtensionRangeList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};

/**
 * @param {!proto.google.protobuf.DescriptorProto.ExtensionRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.DescriptorProto.ExtensionRange}
 */
proto.google.protobuf.DescriptorProto.prototype.addExtensionRange = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.protobuf.DescriptorProto.ExtensionRange, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.DescriptorProto} returns this
 */
proto.google.protobuf.DescriptorProto.prototype.clearExtensionRangeList = function () {
  return this.setExtensionRangeList([]);
};

/**
 * repeated OneofDescriptorProto oneof_decl = 8;
 * @return {!Array<!proto.google.protobuf.OneofDescriptorProto>}
 */
proto.google.protobuf.DescriptorProto.prototype.getOneofDeclList = function () {
  return /** @type{!Array<!proto.google.protobuf.OneofDescriptorProto>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.OneofDescriptorProto, 8)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.OneofDescriptorProto>} value
 * @return {!proto.google.protobuf.DescriptorProto} returns this
*/
proto.google.protobuf.DescriptorProto.prototype.setOneofDeclList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};

/**
 * @param {!proto.google.protobuf.OneofDescriptorProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.OneofDescriptorProto}
 */
proto.google.protobuf.DescriptorProto.prototype.addOneofDecl = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.google.protobuf.OneofDescriptorProto, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.DescriptorProto} returns this
 */
proto.google.protobuf.DescriptorProto.prototype.clearOneofDeclList = function () {
  return this.setOneofDeclList([]);
};

/**
 * optional MessageOptions options = 7;
 * @return {?proto.google.protobuf.MessageOptions}
 */
proto.google.protobuf.DescriptorProto.prototype.getOptions = function () {
  return /** @type{?proto.google.protobuf.MessageOptions} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.MessageOptions, 7)
  );
};

/**
 * @param {?proto.google.protobuf.MessageOptions|undefined} value
 * @return {!proto.google.protobuf.DescriptorProto} returns this
*/
proto.google.protobuf.DescriptorProto.prototype.setOptions = function (value) {
  return jspb.Message.setWrapperField(this, 7, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.DescriptorProto} returns this
 */
proto.google.protobuf.DescriptorProto.prototype.clearOptions = function () {
  return this.setOptions(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.DescriptorProto.prototype.hasOptions = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * repeated ReservedRange reserved_range = 9;
 * @return {!Array<!proto.google.protobuf.DescriptorProto.ReservedRange>}
 */
proto.google.protobuf.DescriptorProto.prototype.getReservedRangeList = function () {
  return /** @type{!Array<!proto.google.protobuf.DescriptorProto.ReservedRange>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.DescriptorProto.ReservedRange, 9)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.DescriptorProto.ReservedRange>} value
 * @return {!proto.google.protobuf.DescriptorProto} returns this
*/
proto.google.protobuf.DescriptorProto.prototype.setReservedRangeList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};

/**
 * @param {!proto.google.protobuf.DescriptorProto.ReservedRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.DescriptorProto.ReservedRange}
 */
proto.google.protobuf.DescriptorProto.prototype.addReservedRange = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.google.protobuf.DescriptorProto.ReservedRange, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.DescriptorProto} returns this
 */
proto.google.protobuf.DescriptorProto.prototype.clearReservedRangeList = function () {
  return this.setReservedRangeList([]);
};

/**
 * repeated string reserved_name = 10;
 * @return {!Array<string>}
 */
proto.google.protobuf.DescriptorProto.prototype.getReservedNameList = function () {
  return /** @type {!Array<string>} */jspb.Message.getRepeatedField(this, 10);
};

/**
 * @param {!Array<string>} value
 * @return {!proto.google.protobuf.DescriptorProto} returns this
 */
proto.google.protobuf.DescriptorProto.prototype.setReservedNameList = function (value) {
  return jspb.Message.setField(this, 10, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.DescriptorProto} returns this
 */
proto.google.protobuf.DescriptorProto.prototype.addReservedName = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.DescriptorProto} returns this
 */
proto.google.protobuf.DescriptorProto.prototype.clearReservedNameList = function () {
  return this.setReservedNameList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.ExtensionRangeOptions.repeatedFields_ = [999, 2];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.ExtensionRangeOptions.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.ExtensionRangeOptions.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.ExtensionRangeOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.ExtensionRangeOptions.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        uninterpretedOptionList: jspb.Message.toObjectList(msg.getUninterpretedOptionList(), proto.google.protobuf.UninterpretedOption.toObject, includeInstance),
        declarationList: jspb.Message.toObjectList(msg.getDeclarationList(), proto.google.protobuf.ExtensionRangeOptions.Declaration.toObject, includeInstance),
        features: (f = msg.getFeatures()) && proto.google.protobuf.FeatureSet.toObject(includeInstance, f),
        verification: jspb.Message.getFieldWithDefault(msg, 3, 1)
      };
    jspb.Message.toObjectExtension( /** @type {!jspb.Message} */msg, obj, proto.google.protobuf.ExtensionRangeOptions.extensions, proto.google.protobuf.ExtensionRangeOptions.prototype.getExtension, includeInstance);
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.ExtensionRangeOptions}
 */
proto.google.protobuf.ExtensionRangeOptions.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.ExtensionRangeOptions();
  return proto.google.protobuf.ExtensionRangeOptions.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.ExtensionRangeOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.ExtensionRangeOptions}
 */
proto.google.protobuf.ExtensionRangeOptions.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 999:
        var value = new proto.google.protobuf.UninterpretedOption();
        reader.readMessage(value, proto.google.protobuf.UninterpretedOption.deserializeBinaryFromReader);
        msg.addUninterpretedOption(value);
        break;
      case 2:
        var value = new proto.google.protobuf.ExtensionRangeOptions.Declaration();
        reader.readMessage(value, proto.google.protobuf.ExtensionRangeOptions.Declaration.deserializeBinaryFromReader);
        msg.addDeclaration(value);
        break;
      case 50:
        var value = new proto.google.protobuf.FeatureSet();
        reader.readMessage(value, proto.google.protobuf.FeatureSet.deserializeBinaryFromReader);
        msg.setFeatures(value);
        break;
      case 3:
        var value = /** @type {!proto.google.protobuf.ExtensionRangeOptions.VerificationState} */reader.readEnum();
        msg.setVerification(value);
        break;
      default:
        jspb.Message.readBinaryExtension(msg, reader, proto.google.protobuf.ExtensionRangeOptions.extensionsBinary, proto.google.protobuf.ExtensionRangeOptions.prototype.getExtension, proto.google.protobuf.ExtensionRangeOptions.prototype.setExtension);
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.ExtensionRangeOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.ExtensionRangeOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.ExtensionRangeOptions.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUninterpretedOptionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(999, f, proto.google.protobuf.UninterpretedOption.serializeBinaryToWriter);
  }
  f = message.getDeclarationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, proto.google.protobuf.ExtensionRangeOptions.Declaration.serializeBinaryToWriter);
  }
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(50, f, proto.google.protobuf.FeatureSet.serializeBinaryToWriter);
  }
  f = /** @type {!proto.google.protobuf.ExtensionRangeOptions.VerificationState} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeEnum(3, f);
  }
  jspb.Message.serializeBinaryExtensions(message, writer, proto.google.protobuf.ExtensionRangeOptions.extensionsBinary, proto.google.protobuf.ExtensionRangeOptions.prototype.getExtension);
};

/**
 * @enum {number}
 */
proto.google.protobuf.ExtensionRangeOptions.VerificationState = {
  DECLARATION: 0,
  UNVERIFIED: 1
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.ExtensionRangeOptions.Declaration.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.ExtensionRangeOptions.Declaration} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.ExtensionRangeOptions.Declaration.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        number: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        fullName: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        type: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        reserved: (f = jspb.Message.getBooleanField(msg, 5)) == null ? undefined : f,
        repeated: (f = jspb.Message.getBooleanField(msg, 6)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.ExtensionRangeOptions.Declaration}
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.ExtensionRangeOptions.Declaration();
  return proto.google.protobuf.ExtensionRangeOptions.Declaration.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.ExtensionRangeOptions.Declaration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.ExtensionRangeOptions.Declaration}
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */reader.readInt32();
        msg.setNumber(value);
        break;
      case 2:
        var value = /** @type {string} */reader.readString();
        msg.setFullName(value);
        break;
      case 3:
        var value = /** @type {string} */reader.readString();
        msg.setType(value);
        break;
      case 5:
        var value = /** @type {boolean} */reader.readBool();
        msg.setReserved(value);
        break;
      case 6:
        var value = /** @type {boolean} */reader.readBool();
        msg.setRepeated(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.ExtensionRangeOptions.Declaration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.ExtensionRangeOptions.Declaration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {number} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeInt32(1, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeString(2, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeString(3, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 5);
  if (f != null) {
    writer.writeBool(5, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 6);
  if (f != null) {
    writer.writeBool(6, f);
  }
};

/**
 * optional int32 number = 1;
 * @return {number}
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.getNumber = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 1, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.ExtensionRangeOptions.Declaration} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.setNumber = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.ExtensionRangeOptions.Declaration} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.clearNumber = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.hasNumber = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string full_name = 2;
 * @return {string}
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.getFullName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 2, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.ExtensionRangeOptions.Declaration} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.setFullName = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.ExtensionRangeOptions.Declaration} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.clearFullName = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.hasFullName = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string type = 3;
 * @return {string}
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.getType = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 3, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.ExtensionRangeOptions.Declaration} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.setType = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.ExtensionRangeOptions.Declaration} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.clearType = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.hasType = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional bool reserved = 5;
 * @return {boolean}
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.getReserved = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 5, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.ExtensionRangeOptions.Declaration} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.setReserved = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.ExtensionRangeOptions.Declaration} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.clearReserved = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.hasReserved = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional bool repeated = 6;
 * @return {boolean}
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.getRepeated = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 6, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.ExtensionRangeOptions.Declaration} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.setRepeated = function (value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.ExtensionRangeOptions.Declaration} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.clearRepeated = function () {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.ExtensionRangeOptions.Declaration.prototype.hasRepeated = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * repeated UninterpretedOption uninterpreted_option = 999;
 * @return {!Array<!proto.google.protobuf.UninterpretedOption>}
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.getUninterpretedOptionList = function () {
  return /** @type{!Array<!proto.google.protobuf.UninterpretedOption>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.UninterpretedOption, 999)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.UninterpretedOption>} value
 * @return {!proto.google.protobuf.ExtensionRangeOptions} returns this
*/
proto.google.protobuf.ExtensionRangeOptions.prototype.setUninterpretedOptionList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 999, value);
};

/**
 * @param {!proto.google.protobuf.UninterpretedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.UninterpretedOption}
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.addUninterpretedOption = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 999, opt_value, proto.google.protobuf.UninterpretedOption, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.ExtensionRangeOptions} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.clearUninterpretedOptionList = function () {
  return this.setUninterpretedOptionList([]);
};

/**
 * repeated Declaration declaration = 2;
 * @return {!Array<!proto.google.protobuf.ExtensionRangeOptions.Declaration>}
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.getDeclarationList = function () {
  return /** @type{!Array<!proto.google.protobuf.ExtensionRangeOptions.Declaration>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.ExtensionRangeOptions.Declaration, 2)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.ExtensionRangeOptions.Declaration>} value
 * @return {!proto.google.protobuf.ExtensionRangeOptions} returns this
*/
proto.google.protobuf.ExtensionRangeOptions.prototype.setDeclarationList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.google.protobuf.ExtensionRangeOptions.Declaration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.ExtensionRangeOptions.Declaration}
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.addDeclaration = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.protobuf.ExtensionRangeOptions.Declaration, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.ExtensionRangeOptions} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.clearDeclarationList = function () {
  return this.setDeclarationList([]);
};

/**
 * optional FeatureSet features = 50;
 * @return {?proto.google.protobuf.FeatureSet}
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.getFeatures = function () {
  return /** @type{?proto.google.protobuf.FeatureSet} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FeatureSet, 50)
  );
};

/**
 * @param {?proto.google.protobuf.FeatureSet|undefined} value
 * @return {!proto.google.protobuf.ExtensionRangeOptions} returns this
*/
proto.google.protobuf.ExtensionRangeOptions.prototype.setFeatures = function (value) {
  return jspb.Message.setWrapperField(this, 50, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.ExtensionRangeOptions} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.clearFeatures = function () {
  return this.setFeatures(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.hasFeatures = function () {
  return jspb.Message.getField(this, 50) != null;
};

/**
 * optional VerificationState verification = 3;
 * @return {!proto.google.protobuf.ExtensionRangeOptions.VerificationState}
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.getVerification = function () {
  return /** @type {!proto.google.protobuf.ExtensionRangeOptions.VerificationState} */jspb.Message.getFieldWithDefault(this, 3, 1);
};

/**
 * @param {!proto.google.protobuf.ExtensionRangeOptions.VerificationState} value
 * @return {!proto.google.protobuf.ExtensionRangeOptions} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.setVerification = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.ExtensionRangeOptions} returns this
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.clearVerification = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.ExtensionRangeOptions.prototype.hasVerification = function () {
  return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.FieldDescriptorProto.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.FieldDescriptorProto.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.FieldDescriptorProto} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.FieldDescriptorProto.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        number: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        label: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
        type: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
        typeName: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
        extendee: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        defaultValue: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
        oneofIndex: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
        jsonName: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
        options: (f = msg.getOptions()) && proto.google.protobuf.FieldOptions.toObject(includeInstance, f),
        proto3Optional: (f = jspb.Message.getBooleanField(msg, 17)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.FieldDescriptorProto}
 */
proto.google.protobuf.FieldDescriptorProto.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.FieldDescriptorProto();
  return proto.google.protobuf.FieldDescriptorProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.FieldDescriptorProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.FieldDescriptorProto}
 */
proto.google.protobuf.FieldDescriptorProto.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {number} */reader.readInt32();
        msg.setNumber(value);
        break;
      case 4:
        var value = /** @type {!proto.google.protobuf.FieldDescriptorProto.Label} */reader.readEnum();
        msg.setLabel(value);
        break;
      case 5:
        var value = /** @type {!proto.google.protobuf.FieldDescriptorProto.Type} */reader.readEnum();
        msg.setType(value);
        break;
      case 6:
        var value = /** @type {string} */reader.readString();
        msg.setTypeName(value);
        break;
      case 2:
        var value = /** @type {string} */reader.readString();
        msg.setExtendee(value);
        break;
      case 7:
        var value = /** @type {string} */reader.readString();
        msg.setDefaultValue(value);
        break;
      case 9:
        var value = /** @type {number} */reader.readInt32();
        msg.setOneofIndex(value);
        break;
      case 10:
        var value = /** @type {string} */reader.readString();
        msg.setJsonName(value);
        break;
      case 8:
        var value = new proto.google.protobuf.FieldOptions();
        reader.readMessage(value, proto.google.protobuf.FieldOptions.deserializeBinaryFromReader);
        msg.setOptions(value);
        break;
      case 17:
        var value = /** @type {boolean} */reader.readBool();
        msg.setProto3Optional(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.FieldDescriptorProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.FieldDescriptorProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.FieldDescriptorProto.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeString(1, f);
  }
  f = /** @type {number} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeInt32(3, f);
  }
  f = /** @type {!proto.google.protobuf.FieldDescriptorProto.Label} */jspb.Message.getField(message, 4);
  if (f != null) {
    writer.writeEnum(4, f);
  }
  f = /** @type {!proto.google.protobuf.FieldDescriptorProto.Type} */jspb.Message.getField(message, 5);
  if (f != null) {
    writer.writeEnum(5, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 6);
  if (f != null) {
    writer.writeString(6, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeString(2, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 7);
  if (f != null) {
    writer.writeString(7, f);
  }
  f = /** @type {number} */jspb.Message.getField(message, 9);
  if (f != null) {
    writer.writeInt32(9, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 10);
  if (f != null) {
    writer.writeString(10, f);
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(8, f, proto.google.protobuf.FieldOptions.serializeBinaryToWriter);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 17);
  if (f != null) {
    writer.writeBool(17, f);
  }
};

/**
 * @enum {number}
 */
proto.google.protobuf.FieldDescriptorProto.Type = {
  TYPE_DOUBLE: 1,
  TYPE_FLOAT: 2,
  TYPE_INT64: 3,
  TYPE_UINT64: 4,
  TYPE_INT32: 5,
  TYPE_FIXED64: 6,
  TYPE_FIXED32: 7,
  TYPE_BOOL: 8,
  TYPE_STRING: 9,
  TYPE_GROUP: 10,
  TYPE_MESSAGE: 11,
  TYPE_BYTES: 12,
  TYPE_UINT32: 13,
  TYPE_ENUM: 14,
  TYPE_SFIXED32: 15,
  TYPE_SFIXED64: 16,
  TYPE_SINT32: 17,
  TYPE_SINT64: 18
};

/**
 * @enum {number}
 */
proto.google.protobuf.FieldDescriptorProto.Label = {
  LABEL_OPTIONAL: 1,
  LABEL_REPEATED: 3,
  LABEL_REQUIRED: 2
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.setName = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.clearName = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.hasName = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional int32 number = 3;
 * @return {number}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.getNumber = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 3, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.setNumber = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.clearNumber = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.hasNumber = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Label label = 4;
 * @return {!proto.google.protobuf.FieldDescriptorProto.Label}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.getLabel = function () {
  return /** @type {!proto.google.protobuf.FieldDescriptorProto.Label} */jspb.Message.getFieldWithDefault(this, 4, 1);
};

/**
 * @param {!proto.google.protobuf.FieldDescriptorProto.Label} value
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.setLabel = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.clearLabel = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.hasLabel = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional Type type = 5;
 * @return {!proto.google.protobuf.FieldDescriptorProto.Type}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.getType = function () {
  return /** @type {!proto.google.protobuf.FieldDescriptorProto.Type} */jspb.Message.getFieldWithDefault(this, 5, 1);
};

/**
 * @param {!proto.google.protobuf.FieldDescriptorProto.Type} value
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.setType = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.clearType = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.hasType = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional string type_name = 6;
 * @return {string}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.getTypeName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 6, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.setTypeName = function (value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.clearTypeName = function () {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.hasTypeName = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional string extendee = 2;
 * @return {string}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.getExtendee = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 2, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.setExtendee = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.clearExtendee = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.hasExtendee = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string default_value = 7;
 * @return {string}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.getDefaultValue = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 7, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.setDefaultValue = function (value) {
  return jspb.Message.setField(this, 7, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.clearDefaultValue = function () {
  return jspb.Message.setField(this, 7, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.hasDefaultValue = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional int32 oneof_index = 9;
 * @return {number}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.getOneofIndex = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 9, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.setOneofIndex = function (value) {
  return jspb.Message.setField(this, 9, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.clearOneofIndex = function () {
  return jspb.Message.setField(this, 9, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.hasOneofIndex = function () {
  return jspb.Message.getField(this, 9) != null;
};

/**
 * optional string json_name = 10;
 * @return {string}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.getJsonName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 10, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.setJsonName = function (value) {
  return jspb.Message.setField(this, 10, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.clearJsonName = function () {
  return jspb.Message.setField(this, 10, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.hasJsonName = function () {
  return jspb.Message.getField(this, 10) != null;
};

/**
 * optional FieldOptions options = 8;
 * @return {?proto.google.protobuf.FieldOptions}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.getOptions = function () {
  return /** @type{?proto.google.protobuf.FieldOptions} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FieldOptions, 8)
  );
};

/**
 * @param {?proto.google.protobuf.FieldOptions|undefined} value
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
*/
proto.google.protobuf.FieldDescriptorProto.prototype.setOptions = function (value) {
  return jspb.Message.setWrapperField(this, 8, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.clearOptions = function () {
  return this.setOptions(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.hasOptions = function () {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional bool proto3_optional = 17;
 * @return {boolean}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.getProto3Optional = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 17, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.setProto3Optional = function (value) {
  return jspb.Message.setField(this, 17, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldDescriptorProto} returns this
 */
proto.google.protobuf.FieldDescriptorProto.prototype.clearProto3Optional = function () {
  return jspb.Message.setField(this, 17, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldDescriptorProto.prototype.hasProto3Optional = function () {
  return jspb.Message.getField(this, 17) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.OneofDescriptorProto.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.OneofDescriptorProto.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.OneofDescriptorProto} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.OneofDescriptorProto.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        options: (f = msg.getOptions()) && proto.google.protobuf.OneofOptions.toObject(includeInstance, f)
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.OneofDescriptorProto}
 */
proto.google.protobuf.OneofDescriptorProto.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.OneofDescriptorProto();
  return proto.google.protobuf.OneofDescriptorProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.OneofDescriptorProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.OneofDescriptorProto}
 */
proto.google.protobuf.OneofDescriptorProto.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      case 2:
        var value = new proto.google.protobuf.OneofOptions();
        reader.readMessage(value, proto.google.protobuf.OneofOptions.deserializeBinaryFromReader);
        msg.setOptions(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.OneofDescriptorProto.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.OneofDescriptorProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.OneofDescriptorProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.OneofDescriptorProto.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeString(1, f);
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(2, f, proto.google.protobuf.OneofOptions.serializeBinaryToWriter);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.google.protobuf.OneofDescriptorProto.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.OneofDescriptorProto} returns this
 */
proto.google.protobuf.OneofDescriptorProto.prototype.setName = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.OneofDescriptorProto} returns this
 */
proto.google.protobuf.OneofDescriptorProto.prototype.clearName = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.OneofDescriptorProto.prototype.hasName = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional OneofOptions options = 2;
 * @return {?proto.google.protobuf.OneofOptions}
 */
proto.google.protobuf.OneofDescriptorProto.prototype.getOptions = function () {
  return /** @type{?proto.google.protobuf.OneofOptions} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.OneofOptions, 2)
  );
};

/**
 * @param {?proto.google.protobuf.OneofOptions|undefined} value
 * @return {!proto.google.protobuf.OneofDescriptorProto} returns this
*/
proto.google.protobuf.OneofDescriptorProto.prototype.setOptions = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.OneofDescriptorProto} returns this
 */
proto.google.protobuf.OneofDescriptorProto.prototype.clearOptions = function () {
  return this.setOptions(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.OneofDescriptorProto.prototype.hasOptions = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.EnumDescriptorProto.repeatedFields_ = [2, 4, 5];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.EnumDescriptorProto.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.EnumDescriptorProto.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.EnumDescriptorProto} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.EnumDescriptorProto.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        valueList: jspb.Message.toObjectList(msg.getValueList(), proto.google.protobuf.EnumValueDescriptorProto.toObject, includeInstance),
        options: (f = msg.getOptions()) && proto.google.protobuf.EnumOptions.toObject(includeInstance, f),
        reservedRangeList: jspb.Message.toObjectList(msg.getReservedRangeList(), proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.toObject, includeInstance),
        reservedNameList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.EnumDescriptorProto}
 */
proto.google.protobuf.EnumDescriptorProto.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.EnumDescriptorProto();
  return proto.google.protobuf.EnumDescriptorProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.EnumDescriptorProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.EnumDescriptorProto}
 */
proto.google.protobuf.EnumDescriptorProto.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      case 2:
        var value = new proto.google.protobuf.EnumValueDescriptorProto();
        reader.readMessage(value, proto.google.protobuf.EnumValueDescriptorProto.deserializeBinaryFromReader);
        msg.addValue(value);
        break;
      case 3:
        var value = new proto.google.protobuf.EnumOptions();
        reader.readMessage(value, proto.google.protobuf.EnumOptions.deserializeBinaryFromReader);
        msg.setOptions(value);
        break;
      case 4:
        var value = new proto.google.protobuf.EnumDescriptorProto.EnumReservedRange();
        reader.readMessage(value, proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.deserializeBinaryFromReader);
        msg.addReservedRange(value);
        break;
      case 5:
        var value = /** @type {string} */reader.readString();
        msg.addReservedName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.EnumDescriptorProto.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.EnumDescriptorProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.EnumDescriptorProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.EnumDescriptorProto.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeString(1, f);
  }
  f = message.getValueList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, proto.google.protobuf.EnumValueDescriptorProto.serializeBinaryToWriter);
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(3, f, proto.google.protobuf.EnumOptions.serializeBinaryToWriter);
  }
  f = message.getReservedRangeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(4, f, proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.serializeBinaryToWriter);
  }
  f = message.getReservedNameList();
  if (f.length > 0) {
    writer.writeRepeatedString(5, f);
  }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        start: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        end: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange}
 */
proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.EnumDescriptorProto.EnumReservedRange();
  return proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange}
 */
proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */reader.readInt32();
        msg.setStart(value);
        break;
      case 2:
        var value = /** @type {number} */reader.readInt32();
        msg.setEnd(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {number} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeInt32(1, f);
  }
  f = /** @type {number} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeInt32(2, f);
  }
};

/**
 * optional int32 start = 1;
 * @return {number}
 */
proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.prototype.getStart = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 1, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange} returns this
 */
proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.prototype.setStart = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange} returns this
 */
proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.prototype.clearStart = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.prototype.hasStart = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional int32 end = 2;
 * @return {number}
 */
proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.prototype.getEnd = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 2, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange} returns this
 */
proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.prototype.setEnd = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange} returns this
 */
proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.prototype.clearEnd = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumDescriptorProto.EnumReservedRange.prototype.hasEnd = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.google.protobuf.EnumDescriptorProto.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.EnumDescriptorProto} returns this
 */
proto.google.protobuf.EnumDescriptorProto.prototype.setName = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.EnumDescriptorProto} returns this
 */
proto.google.protobuf.EnumDescriptorProto.prototype.clearName = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumDescriptorProto.prototype.hasName = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * repeated EnumValueDescriptorProto value = 2;
 * @return {!Array<!proto.google.protobuf.EnumValueDescriptorProto>}
 */
proto.google.protobuf.EnumDescriptorProto.prototype.getValueList = function () {
  return /** @type{!Array<!proto.google.protobuf.EnumValueDescriptorProto>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.EnumValueDescriptorProto, 2)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.EnumValueDescriptorProto>} value
 * @return {!proto.google.protobuf.EnumDescriptorProto} returns this
*/
proto.google.protobuf.EnumDescriptorProto.prototype.setValueList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.google.protobuf.EnumValueDescriptorProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.EnumValueDescriptorProto}
 */
proto.google.protobuf.EnumDescriptorProto.prototype.addValue = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.protobuf.EnumValueDescriptorProto, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.EnumDescriptorProto} returns this
 */
proto.google.protobuf.EnumDescriptorProto.prototype.clearValueList = function () {
  return this.setValueList([]);
};

/**
 * optional EnumOptions options = 3;
 * @return {?proto.google.protobuf.EnumOptions}
 */
proto.google.protobuf.EnumDescriptorProto.prototype.getOptions = function () {
  return /** @type{?proto.google.protobuf.EnumOptions} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.EnumOptions, 3)
  );
};

/**
 * @param {?proto.google.protobuf.EnumOptions|undefined} value
 * @return {!proto.google.protobuf.EnumDescriptorProto} returns this
*/
proto.google.protobuf.EnumDescriptorProto.prototype.setOptions = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.EnumDescriptorProto} returns this
 */
proto.google.protobuf.EnumDescriptorProto.prototype.clearOptions = function () {
  return this.setOptions(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumDescriptorProto.prototype.hasOptions = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * repeated EnumReservedRange reserved_range = 4;
 * @return {!Array<!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange>}
 */
proto.google.protobuf.EnumDescriptorProto.prototype.getReservedRangeList = function () {
  return /** @type{!Array<!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.EnumDescriptorProto.EnumReservedRange, 4)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange>} value
 * @return {!proto.google.protobuf.EnumDescriptorProto} returns this
*/
proto.google.protobuf.EnumDescriptorProto.prototype.setReservedRangeList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};

/**
 * @param {!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.EnumDescriptorProto.EnumReservedRange}
 */
proto.google.protobuf.EnumDescriptorProto.prototype.addReservedRange = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.protobuf.EnumDescriptorProto.EnumReservedRange, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.EnumDescriptorProto} returns this
 */
proto.google.protobuf.EnumDescriptorProto.prototype.clearReservedRangeList = function () {
  return this.setReservedRangeList([]);
};

/**
 * repeated string reserved_name = 5;
 * @return {!Array<string>}
 */
proto.google.protobuf.EnumDescriptorProto.prototype.getReservedNameList = function () {
  return /** @type {!Array<string>} */jspb.Message.getRepeatedField(this, 5);
};

/**
 * @param {!Array<string>} value
 * @return {!proto.google.protobuf.EnumDescriptorProto} returns this
 */
proto.google.protobuf.EnumDescriptorProto.prototype.setReservedNameList = function (value) {
  return jspb.Message.setField(this, 5, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.EnumDescriptorProto} returns this
 */
proto.google.protobuf.EnumDescriptorProto.prototype.addReservedName = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.EnumDescriptorProto} returns this
 */
proto.google.protobuf.EnumDescriptorProto.prototype.clearReservedNameList = function () {
  return this.setReservedNameList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.EnumValueDescriptorProto.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.EnumValueDescriptorProto.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.EnumValueDescriptorProto} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.EnumValueDescriptorProto.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        number: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        options: (f = msg.getOptions()) && proto.google.protobuf.EnumValueOptions.toObject(includeInstance, f)
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.EnumValueDescriptorProto}
 */
proto.google.protobuf.EnumValueDescriptorProto.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.EnumValueDescriptorProto();
  return proto.google.protobuf.EnumValueDescriptorProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.EnumValueDescriptorProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.EnumValueDescriptorProto}
 */
proto.google.protobuf.EnumValueDescriptorProto.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {number} */reader.readInt32();
        msg.setNumber(value);
        break;
      case 3:
        var value = new proto.google.protobuf.EnumValueOptions();
        reader.readMessage(value, proto.google.protobuf.EnumValueOptions.deserializeBinaryFromReader);
        msg.setOptions(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.EnumValueDescriptorProto.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.EnumValueDescriptorProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.EnumValueDescriptorProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.EnumValueDescriptorProto.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeString(1, f);
  }
  f = /** @type {number} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeInt32(2, f);
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(3, f, proto.google.protobuf.EnumValueOptions.serializeBinaryToWriter);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.google.protobuf.EnumValueDescriptorProto.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.EnumValueDescriptorProto} returns this
 */
proto.google.protobuf.EnumValueDescriptorProto.prototype.setName = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.EnumValueDescriptorProto} returns this
 */
proto.google.protobuf.EnumValueDescriptorProto.prototype.clearName = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumValueDescriptorProto.prototype.hasName = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional int32 number = 2;
 * @return {number}
 */
proto.google.protobuf.EnumValueDescriptorProto.prototype.getNumber = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 2, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.EnumValueDescriptorProto} returns this
 */
proto.google.protobuf.EnumValueDescriptorProto.prototype.setNumber = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.EnumValueDescriptorProto} returns this
 */
proto.google.protobuf.EnumValueDescriptorProto.prototype.clearNumber = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumValueDescriptorProto.prototype.hasNumber = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional EnumValueOptions options = 3;
 * @return {?proto.google.protobuf.EnumValueOptions}
 */
proto.google.protobuf.EnumValueDescriptorProto.prototype.getOptions = function () {
  return /** @type{?proto.google.protobuf.EnumValueOptions} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.EnumValueOptions, 3)
  );
};

/**
 * @param {?proto.google.protobuf.EnumValueOptions|undefined} value
 * @return {!proto.google.protobuf.EnumValueDescriptorProto} returns this
*/
proto.google.protobuf.EnumValueDescriptorProto.prototype.setOptions = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.EnumValueDescriptorProto} returns this
 */
proto.google.protobuf.EnumValueDescriptorProto.prototype.clearOptions = function () {
  return this.setOptions(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumValueDescriptorProto.prototype.hasOptions = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.ServiceDescriptorProto.repeatedFields_ = [2];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.ServiceDescriptorProto.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.ServiceDescriptorProto.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.ServiceDescriptorProto} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.ServiceDescriptorProto.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        methodList: jspb.Message.toObjectList(msg.getMethodList(), proto.google.protobuf.MethodDescriptorProto.toObject, includeInstance),
        options: (f = msg.getOptions()) && proto.google.protobuf.ServiceOptions.toObject(includeInstance, f)
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.ServiceDescriptorProto}
 */
proto.google.protobuf.ServiceDescriptorProto.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.ServiceDescriptorProto();
  return proto.google.protobuf.ServiceDescriptorProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.ServiceDescriptorProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.ServiceDescriptorProto}
 */
proto.google.protobuf.ServiceDescriptorProto.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      case 2:
        var value = new proto.google.protobuf.MethodDescriptorProto();
        reader.readMessage(value, proto.google.protobuf.MethodDescriptorProto.deserializeBinaryFromReader);
        msg.addMethod(value);
        break;
      case 3:
        var value = new proto.google.protobuf.ServiceOptions();
        reader.readMessage(value, proto.google.protobuf.ServiceOptions.deserializeBinaryFromReader);
        msg.setOptions(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.ServiceDescriptorProto.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.ServiceDescriptorProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.ServiceDescriptorProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.ServiceDescriptorProto.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeString(1, f);
  }
  f = message.getMethodList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, proto.google.protobuf.MethodDescriptorProto.serializeBinaryToWriter);
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(3, f, proto.google.protobuf.ServiceOptions.serializeBinaryToWriter);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.google.protobuf.ServiceDescriptorProto.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.ServiceDescriptorProto} returns this
 */
proto.google.protobuf.ServiceDescriptorProto.prototype.setName = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.ServiceDescriptorProto} returns this
 */
proto.google.protobuf.ServiceDescriptorProto.prototype.clearName = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.ServiceDescriptorProto.prototype.hasName = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * repeated MethodDescriptorProto method = 2;
 * @return {!Array<!proto.google.protobuf.MethodDescriptorProto>}
 */
proto.google.protobuf.ServiceDescriptorProto.prototype.getMethodList = function () {
  return /** @type{!Array<!proto.google.protobuf.MethodDescriptorProto>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.MethodDescriptorProto, 2)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.MethodDescriptorProto>} value
 * @return {!proto.google.protobuf.ServiceDescriptorProto} returns this
*/
proto.google.protobuf.ServiceDescriptorProto.prototype.setMethodList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.google.protobuf.MethodDescriptorProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.MethodDescriptorProto}
 */
proto.google.protobuf.ServiceDescriptorProto.prototype.addMethod = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.protobuf.MethodDescriptorProto, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.ServiceDescriptorProto} returns this
 */
proto.google.protobuf.ServiceDescriptorProto.prototype.clearMethodList = function () {
  return this.setMethodList([]);
};

/**
 * optional ServiceOptions options = 3;
 * @return {?proto.google.protobuf.ServiceOptions}
 */
proto.google.protobuf.ServiceDescriptorProto.prototype.getOptions = function () {
  return /** @type{?proto.google.protobuf.ServiceOptions} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.ServiceOptions, 3)
  );
};

/**
 * @param {?proto.google.protobuf.ServiceOptions|undefined} value
 * @return {!proto.google.protobuf.ServiceDescriptorProto} returns this
*/
proto.google.protobuf.ServiceDescriptorProto.prototype.setOptions = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.ServiceDescriptorProto} returns this
 */
proto.google.protobuf.ServiceDescriptorProto.prototype.clearOptions = function () {
  return this.setOptions(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.ServiceDescriptorProto.prototype.hasOptions = function () {
  return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.MethodDescriptorProto.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.MethodDescriptorProto.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.MethodDescriptorProto} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.MethodDescriptorProto.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        inputType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        outputType: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        options: (f = msg.getOptions()) && proto.google.protobuf.MethodOptions.toObject(includeInstance, f),
        clientStreaming: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
        serverStreaming: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.MethodDescriptorProto}
 */
proto.google.protobuf.MethodDescriptorProto.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.MethodDescriptorProto();
  return proto.google.protobuf.MethodDescriptorProto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.MethodDescriptorProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.MethodDescriptorProto}
 */
proto.google.protobuf.MethodDescriptorProto.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */reader.readString();
        msg.setInputType(value);
        break;
      case 3:
        var value = /** @type {string} */reader.readString();
        msg.setOutputType(value);
        break;
      case 4:
        var value = new proto.google.protobuf.MethodOptions();
        reader.readMessage(value, proto.google.protobuf.MethodOptions.deserializeBinaryFromReader);
        msg.setOptions(value);
        break;
      case 5:
        var value = /** @type {boolean} */reader.readBool();
        msg.setClientStreaming(value);
        break;
      case 6:
        var value = /** @type {boolean} */reader.readBool();
        msg.setServerStreaming(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.MethodDescriptorProto.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.MethodDescriptorProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.MethodDescriptorProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.MethodDescriptorProto.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeString(1, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeString(2, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeString(3, f);
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(4, f, proto.google.protobuf.MethodOptions.serializeBinaryToWriter);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 5);
  if (f != null) {
    writer.writeBool(5, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 6);
  if (f != null) {
    writer.writeBool(6, f);
  }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.google.protobuf.MethodDescriptorProto.prototype.getName = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.MethodDescriptorProto} returns this
 */
proto.google.protobuf.MethodDescriptorProto.prototype.setName = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.MethodDescriptorProto} returns this
 */
proto.google.protobuf.MethodDescriptorProto.prototype.clearName = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MethodDescriptorProto.prototype.hasName = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string input_type = 2;
 * @return {string}
 */
proto.google.protobuf.MethodDescriptorProto.prototype.getInputType = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 2, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.MethodDescriptorProto} returns this
 */
proto.google.protobuf.MethodDescriptorProto.prototype.setInputType = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.MethodDescriptorProto} returns this
 */
proto.google.protobuf.MethodDescriptorProto.prototype.clearInputType = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MethodDescriptorProto.prototype.hasInputType = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string output_type = 3;
 * @return {string}
 */
proto.google.protobuf.MethodDescriptorProto.prototype.getOutputType = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 3, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.MethodDescriptorProto} returns this
 */
proto.google.protobuf.MethodDescriptorProto.prototype.setOutputType = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.MethodDescriptorProto} returns this
 */
proto.google.protobuf.MethodDescriptorProto.prototype.clearOutputType = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MethodDescriptorProto.prototype.hasOutputType = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional MethodOptions options = 4;
 * @return {?proto.google.protobuf.MethodOptions}
 */
proto.google.protobuf.MethodDescriptorProto.prototype.getOptions = function () {
  return /** @type{?proto.google.protobuf.MethodOptions} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.MethodOptions, 4)
  );
};

/**
 * @param {?proto.google.protobuf.MethodOptions|undefined} value
 * @return {!proto.google.protobuf.MethodDescriptorProto} returns this
*/
proto.google.protobuf.MethodDescriptorProto.prototype.setOptions = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.MethodDescriptorProto} returns this
 */
proto.google.protobuf.MethodDescriptorProto.prototype.clearOptions = function () {
  return this.setOptions(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MethodDescriptorProto.prototype.hasOptions = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional bool client_streaming = 5;
 * @return {boolean}
 */
proto.google.protobuf.MethodDescriptorProto.prototype.getClientStreaming = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 5, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.MethodDescriptorProto} returns this
 */
proto.google.protobuf.MethodDescriptorProto.prototype.setClientStreaming = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.MethodDescriptorProto} returns this
 */
proto.google.protobuf.MethodDescriptorProto.prototype.clearClientStreaming = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MethodDescriptorProto.prototype.hasClientStreaming = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional bool server_streaming = 6;
 * @return {boolean}
 */
proto.google.protobuf.MethodDescriptorProto.prototype.getServerStreaming = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 6, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.MethodDescriptorProto} returns this
 */
proto.google.protobuf.MethodDescriptorProto.prototype.setServerStreaming = function (value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.MethodDescriptorProto} returns this
 */
proto.google.protobuf.MethodDescriptorProto.prototype.clearServerStreaming = function () {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MethodDescriptorProto.prototype.hasServerStreaming = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.FileOptions.repeatedFields_ = [999];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.FileOptions.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.FileOptions.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.FileOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.FileOptions.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        javaPackage: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        javaOuterClassname: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
        javaMultipleFiles: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
        javaGenerateEqualsAndHash: (f = jspb.Message.getBooleanField(msg, 20)) == null ? undefined : f,
        javaStringCheckUtf8: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
        optimizeFor: jspb.Message.getFieldWithDefault(msg, 9, 1),
        goPackage: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
        ccGenericServices: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
        javaGenericServices: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
        pyGenericServices: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
        deprecated: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
        ccEnableArenas: jspb.Message.getBooleanFieldWithDefault(msg, 31, true),
        objcClassPrefix: (f = jspb.Message.getField(msg, 36)) == null ? undefined : f,
        csharpNamespace: (f = jspb.Message.getField(msg, 37)) == null ? undefined : f,
        swiftPrefix: (f = jspb.Message.getField(msg, 39)) == null ? undefined : f,
        phpClassPrefix: (f = jspb.Message.getField(msg, 40)) == null ? undefined : f,
        phpNamespace: (f = jspb.Message.getField(msg, 41)) == null ? undefined : f,
        phpMetadataNamespace: (f = jspb.Message.getField(msg, 44)) == null ? undefined : f,
        rubyPackage: (f = jspb.Message.getField(msg, 45)) == null ? undefined : f,
        features: (f = msg.getFeatures()) && proto.google.protobuf.FeatureSet.toObject(includeInstance, f),
        uninterpretedOptionList: jspb.Message.toObjectList(msg.getUninterpretedOptionList(), proto.google.protobuf.UninterpretedOption.toObject, includeInstance)
      };
    jspb.Message.toObjectExtension( /** @type {!jspb.Message} */msg, obj, proto.google.protobuf.FileOptions.extensions, proto.google.protobuf.FileOptions.prototype.getExtension, includeInstance);
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.FileOptions}
 */
proto.google.protobuf.FileOptions.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.FileOptions();
  return proto.google.protobuf.FileOptions.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.FileOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.FileOptions}
 */
proto.google.protobuf.FileOptions.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setJavaPackage(value);
        break;
      case 8:
        var value = /** @type {string} */reader.readString();
        msg.setJavaOuterClassname(value);
        break;
      case 10:
        var value = /** @type {boolean} */reader.readBool();
        msg.setJavaMultipleFiles(value);
        break;
      case 20:
        var value = /** @type {boolean} */reader.readBool();
        msg.setJavaGenerateEqualsAndHash(value);
        break;
      case 27:
        var value = /** @type {boolean} */reader.readBool();
        msg.setJavaStringCheckUtf8(value);
        break;
      case 9:
        var value = /** @type {!proto.google.protobuf.FileOptions.OptimizeMode} */reader.readEnum();
        msg.setOptimizeFor(value);
        break;
      case 11:
        var value = /** @type {string} */reader.readString();
        msg.setGoPackage(value);
        break;
      case 16:
        var value = /** @type {boolean} */reader.readBool();
        msg.setCcGenericServices(value);
        break;
      case 17:
        var value = /** @type {boolean} */reader.readBool();
        msg.setJavaGenericServices(value);
        break;
      case 18:
        var value = /** @type {boolean} */reader.readBool();
        msg.setPyGenericServices(value);
        break;
      case 23:
        var value = /** @type {boolean} */reader.readBool();
        msg.setDeprecated(value);
        break;
      case 31:
        var value = /** @type {boolean} */reader.readBool();
        msg.setCcEnableArenas(value);
        break;
      case 36:
        var value = /** @type {string} */reader.readString();
        msg.setObjcClassPrefix(value);
        break;
      case 37:
        var value = /** @type {string} */reader.readString();
        msg.setCsharpNamespace(value);
        break;
      case 39:
        var value = /** @type {string} */reader.readString();
        msg.setSwiftPrefix(value);
        break;
      case 40:
        var value = /** @type {string} */reader.readString();
        msg.setPhpClassPrefix(value);
        break;
      case 41:
        var value = /** @type {string} */reader.readString();
        msg.setPhpNamespace(value);
        break;
      case 44:
        var value = /** @type {string} */reader.readString();
        msg.setPhpMetadataNamespace(value);
        break;
      case 45:
        var value = /** @type {string} */reader.readString();
        msg.setRubyPackage(value);
        break;
      case 50:
        var value = new proto.google.protobuf.FeatureSet();
        reader.readMessage(value, proto.google.protobuf.FeatureSet.deserializeBinaryFromReader);
        msg.setFeatures(value);
        break;
      case 999:
        var value = new proto.google.protobuf.UninterpretedOption();
        reader.readMessage(value, proto.google.protobuf.UninterpretedOption.deserializeBinaryFromReader);
        msg.addUninterpretedOption(value);
        break;
      default:
        jspb.Message.readBinaryExtension(msg, reader, proto.google.protobuf.FileOptions.extensionsBinary, proto.google.protobuf.FileOptions.prototype.getExtension, proto.google.protobuf.FileOptions.prototype.setExtension);
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.FileOptions.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.FileOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.FileOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.FileOptions.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeString(1, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 8);
  if (f != null) {
    writer.writeString(8, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 10);
  if (f != null) {
    writer.writeBool(10, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 20);
  if (f != null) {
    writer.writeBool(20, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 27);
  if (f != null) {
    writer.writeBool(27, f);
  }
  f = /** @type {!proto.google.protobuf.FileOptions.OptimizeMode} */jspb.Message.getField(message, 9);
  if (f != null) {
    writer.writeEnum(9, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 11);
  if (f != null) {
    writer.writeString(11, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 16);
  if (f != null) {
    writer.writeBool(16, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 17);
  if (f != null) {
    writer.writeBool(17, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 18);
  if (f != null) {
    writer.writeBool(18, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 23);
  if (f != null) {
    writer.writeBool(23, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 31);
  if (f != null) {
    writer.writeBool(31, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 36);
  if (f != null) {
    writer.writeString(36, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 37);
  if (f != null) {
    writer.writeString(37, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 39);
  if (f != null) {
    writer.writeString(39, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 40);
  if (f != null) {
    writer.writeString(40, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 41);
  if (f != null) {
    writer.writeString(41, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 44);
  if (f != null) {
    writer.writeString(44, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 45);
  if (f != null) {
    writer.writeString(45, f);
  }
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(50, f, proto.google.protobuf.FeatureSet.serializeBinaryToWriter);
  }
  f = message.getUninterpretedOptionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(999, f, proto.google.protobuf.UninterpretedOption.serializeBinaryToWriter);
  }
  jspb.Message.serializeBinaryExtensions(message, writer, proto.google.protobuf.FileOptions.extensionsBinary, proto.google.protobuf.FileOptions.prototype.getExtension);
};

/**
 * @enum {number}
 */
proto.google.protobuf.FileOptions.OptimizeMode = {
  SPEED: 1,
  CODE_SIZE: 2,
  LITE_RUNTIME: 3
};

/**
 * optional string java_package = 1;
 * @return {string}
 */
proto.google.protobuf.FileOptions.prototype.getJavaPackage = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setJavaPackage = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearJavaPackage = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasJavaPackage = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string java_outer_classname = 8;
 * @return {string}
 */
proto.google.protobuf.FileOptions.prototype.getJavaOuterClassname = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 8, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setJavaOuterClassname = function (value) {
  return jspb.Message.setField(this, 8, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearJavaOuterClassname = function () {
  return jspb.Message.setField(this, 8, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasJavaOuterClassname = function () {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional bool java_multiple_files = 10;
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.getJavaMultipleFiles = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 10, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setJavaMultipleFiles = function (value) {
  return jspb.Message.setField(this, 10, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearJavaMultipleFiles = function () {
  return jspb.Message.setField(this, 10, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasJavaMultipleFiles = function () {
  return jspb.Message.getField(this, 10) != null;
};

/**
 * optional bool java_generate_equals_and_hash = 20;
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.getJavaGenerateEqualsAndHash = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 20, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setJavaGenerateEqualsAndHash = function (value) {
  return jspb.Message.setField(this, 20, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearJavaGenerateEqualsAndHash = function () {
  return jspb.Message.setField(this, 20, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasJavaGenerateEqualsAndHash = function () {
  return jspb.Message.getField(this, 20) != null;
};

/**
 * optional bool java_string_check_utf8 = 27;
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.getJavaStringCheckUtf8 = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 27, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setJavaStringCheckUtf8 = function (value) {
  return jspb.Message.setField(this, 27, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearJavaStringCheckUtf8 = function () {
  return jspb.Message.setField(this, 27, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasJavaStringCheckUtf8 = function () {
  return jspb.Message.getField(this, 27) != null;
};

/**
 * optional OptimizeMode optimize_for = 9;
 * @return {!proto.google.protobuf.FileOptions.OptimizeMode}
 */
proto.google.protobuf.FileOptions.prototype.getOptimizeFor = function () {
  return /** @type {!proto.google.protobuf.FileOptions.OptimizeMode} */jspb.Message.getFieldWithDefault(this, 9, 1);
};

/**
 * @param {!proto.google.protobuf.FileOptions.OptimizeMode} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setOptimizeFor = function (value) {
  return jspb.Message.setField(this, 9, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearOptimizeFor = function () {
  return jspb.Message.setField(this, 9, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasOptimizeFor = function () {
  return jspb.Message.getField(this, 9) != null;
};

/**
 * optional string go_package = 11;
 * @return {string}
 */
proto.google.protobuf.FileOptions.prototype.getGoPackage = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 11, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setGoPackage = function (value) {
  return jspb.Message.setField(this, 11, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearGoPackage = function () {
  return jspb.Message.setField(this, 11, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasGoPackage = function () {
  return jspb.Message.getField(this, 11) != null;
};

/**
 * optional bool cc_generic_services = 16;
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.getCcGenericServices = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 16, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setCcGenericServices = function (value) {
  return jspb.Message.setField(this, 16, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearCcGenericServices = function () {
  return jspb.Message.setField(this, 16, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasCcGenericServices = function () {
  return jspb.Message.getField(this, 16) != null;
};

/**
 * optional bool java_generic_services = 17;
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.getJavaGenericServices = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 17, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setJavaGenericServices = function (value) {
  return jspb.Message.setField(this, 17, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearJavaGenericServices = function () {
  return jspb.Message.setField(this, 17, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasJavaGenericServices = function () {
  return jspb.Message.getField(this, 17) != null;
};

/**
 * optional bool py_generic_services = 18;
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.getPyGenericServices = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 18, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setPyGenericServices = function (value) {
  return jspb.Message.setField(this, 18, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearPyGenericServices = function () {
  return jspb.Message.setField(this, 18, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasPyGenericServices = function () {
  return jspb.Message.getField(this, 18) != null;
};

/**
 * optional bool deprecated = 23;
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.getDeprecated = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 23, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setDeprecated = function (value) {
  return jspb.Message.setField(this, 23, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearDeprecated = function () {
  return jspb.Message.setField(this, 23, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasDeprecated = function () {
  return jspb.Message.getField(this, 23) != null;
};

/**
 * optional bool cc_enable_arenas = 31;
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.getCcEnableArenas = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 31, true);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setCcEnableArenas = function (value) {
  return jspb.Message.setField(this, 31, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearCcEnableArenas = function () {
  return jspb.Message.setField(this, 31, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasCcEnableArenas = function () {
  return jspb.Message.getField(this, 31) != null;
};

/**
 * optional string objc_class_prefix = 36;
 * @return {string}
 */
proto.google.protobuf.FileOptions.prototype.getObjcClassPrefix = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 36, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setObjcClassPrefix = function (value) {
  return jspb.Message.setField(this, 36, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearObjcClassPrefix = function () {
  return jspb.Message.setField(this, 36, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasObjcClassPrefix = function () {
  return jspb.Message.getField(this, 36) != null;
};

/**
 * optional string csharp_namespace = 37;
 * @return {string}
 */
proto.google.protobuf.FileOptions.prototype.getCsharpNamespace = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 37, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setCsharpNamespace = function (value) {
  return jspb.Message.setField(this, 37, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearCsharpNamespace = function () {
  return jspb.Message.setField(this, 37, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasCsharpNamespace = function () {
  return jspb.Message.getField(this, 37) != null;
};

/**
 * optional string swift_prefix = 39;
 * @return {string}
 */
proto.google.protobuf.FileOptions.prototype.getSwiftPrefix = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 39, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setSwiftPrefix = function (value) {
  return jspb.Message.setField(this, 39, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearSwiftPrefix = function () {
  return jspb.Message.setField(this, 39, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasSwiftPrefix = function () {
  return jspb.Message.getField(this, 39) != null;
};

/**
 * optional string php_class_prefix = 40;
 * @return {string}
 */
proto.google.protobuf.FileOptions.prototype.getPhpClassPrefix = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 40, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setPhpClassPrefix = function (value) {
  return jspb.Message.setField(this, 40, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearPhpClassPrefix = function () {
  return jspb.Message.setField(this, 40, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasPhpClassPrefix = function () {
  return jspb.Message.getField(this, 40) != null;
};

/**
 * optional string php_namespace = 41;
 * @return {string}
 */
proto.google.protobuf.FileOptions.prototype.getPhpNamespace = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 41, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setPhpNamespace = function (value) {
  return jspb.Message.setField(this, 41, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearPhpNamespace = function () {
  return jspb.Message.setField(this, 41, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasPhpNamespace = function () {
  return jspb.Message.getField(this, 41) != null;
};

/**
 * optional string php_metadata_namespace = 44;
 * @return {string}
 */
proto.google.protobuf.FileOptions.prototype.getPhpMetadataNamespace = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 44, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setPhpMetadataNamespace = function (value) {
  return jspb.Message.setField(this, 44, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearPhpMetadataNamespace = function () {
  return jspb.Message.setField(this, 44, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasPhpMetadataNamespace = function () {
  return jspb.Message.getField(this, 44) != null;
};

/**
 * optional string ruby_package = 45;
 * @return {string}
 */
proto.google.protobuf.FileOptions.prototype.getRubyPackage = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 45, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.setRubyPackage = function (value) {
  return jspb.Message.setField(this, 45, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearRubyPackage = function () {
  return jspb.Message.setField(this, 45, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasRubyPackage = function () {
  return jspb.Message.getField(this, 45) != null;
};

/**
 * optional FeatureSet features = 50;
 * @return {?proto.google.protobuf.FeatureSet}
 */
proto.google.protobuf.FileOptions.prototype.getFeatures = function () {
  return /** @type{?proto.google.protobuf.FeatureSet} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FeatureSet, 50)
  );
};

/**
 * @param {?proto.google.protobuf.FeatureSet|undefined} value
 * @return {!proto.google.protobuf.FileOptions} returns this
*/
proto.google.protobuf.FileOptions.prototype.setFeatures = function (value) {
  return jspb.Message.setWrapperField(this, 50, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearFeatures = function () {
  return this.setFeatures(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FileOptions.prototype.hasFeatures = function () {
  return jspb.Message.getField(this, 50) != null;
};

/**
 * repeated UninterpretedOption uninterpreted_option = 999;
 * @return {!Array<!proto.google.protobuf.UninterpretedOption>}
 */
proto.google.protobuf.FileOptions.prototype.getUninterpretedOptionList = function () {
  return /** @type{!Array<!proto.google.protobuf.UninterpretedOption>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.UninterpretedOption, 999)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.UninterpretedOption>} value
 * @return {!proto.google.protobuf.FileOptions} returns this
*/
proto.google.protobuf.FileOptions.prototype.setUninterpretedOptionList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 999, value);
};

/**
 * @param {!proto.google.protobuf.UninterpretedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.UninterpretedOption}
 */
proto.google.protobuf.FileOptions.prototype.addUninterpretedOption = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 999, opt_value, proto.google.protobuf.UninterpretedOption, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.FileOptions} returns this
 */
proto.google.protobuf.FileOptions.prototype.clearUninterpretedOptionList = function () {
  return this.setUninterpretedOptionList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.MessageOptions.repeatedFields_ = [999];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.MessageOptions.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.MessageOptions.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.MessageOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.MessageOptions.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        messageSetWireFormat: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        noStandardDescriptorAccessor: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
        deprecated: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
        mapEntry: (f = jspb.Message.getBooleanField(msg, 7)) == null ? undefined : f,
        deprecatedLegacyJsonFieldConflicts: (f = jspb.Message.getBooleanField(msg, 11)) == null ? undefined : f,
        features: (f = msg.getFeatures()) && proto.google.protobuf.FeatureSet.toObject(includeInstance, f),
        uninterpretedOptionList: jspb.Message.toObjectList(msg.getUninterpretedOptionList(), proto.google.protobuf.UninterpretedOption.toObject, includeInstance)
      };
    jspb.Message.toObjectExtension( /** @type {!jspb.Message} */msg, obj, proto.google.protobuf.MessageOptions.extensions, proto.google.protobuf.MessageOptions.prototype.getExtension, includeInstance);
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.MessageOptions}
 */
proto.google.protobuf.MessageOptions.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.MessageOptions();
  return proto.google.protobuf.MessageOptions.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.MessageOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.MessageOptions}
 */
proto.google.protobuf.MessageOptions.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */reader.readBool();
        msg.setMessageSetWireFormat(value);
        break;
      case 2:
        var value = /** @type {boolean} */reader.readBool();
        msg.setNoStandardDescriptorAccessor(value);
        break;
      case 3:
        var value = /** @type {boolean} */reader.readBool();
        msg.setDeprecated(value);
        break;
      case 7:
        var value = /** @type {boolean} */reader.readBool();
        msg.setMapEntry(value);
        break;
      case 11:
        var value = /** @type {boolean} */reader.readBool();
        msg.setDeprecatedLegacyJsonFieldConflicts(value);
        break;
      case 12:
        var value = new proto.google.protobuf.FeatureSet();
        reader.readMessage(value, proto.google.protobuf.FeatureSet.deserializeBinaryFromReader);
        msg.setFeatures(value);
        break;
      case 999:
        var value = new proto.google.protobuf.UninterpretedOption();
        reader.readMessage(value, proto.google.protobuf.UninterpretedOption.deserializeBinaryFromReader);
        msg.addUninterpretedOption(value);
        break;
      default:
        jspb.Message.readBinaryExtension(msg, reader, proto.google.protobuf.MessageOptions.extensionsBinary, proto.google.protobuf.MessageOptions.prototype.getExtension, proto.google.protobuf.MessageOptions.prototype.setExtension);
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.MessageOptions.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.MessageOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.MessageOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.MessageOptions.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {boolean} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeBool(1, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeBool(2, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeBool(3, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 7);
  if (f != null) {
    writer.writeBool(7, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 11);
  if (f != null) {
    writer.writeBool(11, f);
  }
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(12, f, proto.google.protobuf.FeatureSet.serializeBinaryToWriter);
  }
  f = message.getUninterpretedOptionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(999, f, proto.google.protobuf.UninterpretedOption.serializeBinaryToWriter);
  }
  jspb.Message.serializeBinaryExtensions(message, writer, proto.google.protobuf.MessageOptions.extensionsBinary, proto.google.protobuf.MessageOptions.prototype.getExtension);
};

/**
 * optional bool message_set_wire_format = 1;
 * @return {boolean}
 */
proto.google.protobuf.MessageOptions.prototype.getMessageSetWireFormat = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 1, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.MessageOptions} returns this
 */
proto.google.protobuf.MessageOptions.prototype.setMessageSetWireFormat = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.MessageOptions} returns this
 */
proto.google.protobuf.MessageOptions.prototype.clearMessageSetWireFormat = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MessageOptions.prototype.hasMessageSetWireFormat = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional bool no_standard_descriptor_accessor = 2;
 * @return {boolean}
 */
proto.google.protobuf.MessageOptions.prototype.getNoStandardDescriptorAccessor = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 2, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.MessageOptions} returns this
 */
proto.google.protobuf.MessageOptions.prototype.setNoStandardDescriptorAccessor = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.MessageOptions} returns this
 */
proto.google.protobuf.MessageOptions.prototype.clearNoStandardDescriptorAccessor = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MessageOptions.prototype.hasNoStandardDescriptorAccessor = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional bool deprecated = 3;
 * @return {boolean}
 */
proto.google.protobuf.MessageOptions.prototype.getDeprecated = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 3, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.MessageOptions} returns this
 */
proto.google.protobuf.MessageOptions.prototype.setDeprecated = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.MessageOptions} returns this
 */
proto.google.protobuf.MessageOptions.prototype.clearDeprecated = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MessageOptions.prototype.hasDeprecated = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional bool map_entry = 7;
 * @return {boolean}
 */
proto.google.protobuf.MessageOptions.prototype.getMapEntry = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 7, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.MessageOptions} returns this
 */
proto.google.protobuf.MessageOptions.prototype.setMapEntry = function (value) {
  return jspb.Message.setField(this, 7, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.MessageOptions} returns this
 */
proto.google.protobuf.MessageOptions.prototype.clearMapEntry = function () {
  return jspb.Message.setField(this, 7, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MessageOptions.prototype.hasMapEntry = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional bool deprecated_legacy_json_field_conflicts = 11;
 * @return {boolean}
 */
proto.google.protobuf.MessageOptions.prototype.getDeprecatedLegacyJsonFieldConflicts = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 11, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.MessageOptions} returns this
 */
proto.google.protobuf.MessageOptions.prototype.setDeprecatedLegacyJsonFieldConflicts = function (value) {
  return jspb.Message.setField(this, 11, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.MessageOptions} returns this
 */
proto.google.protobuf.MessageOptions.prototype.clearDeprecatedLegacyJsonFieldConflicts = function () {
  return jspb.Message.setField(this, 11, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MessageOptions.prototype.hasDeprecatedLegacyJsonFieldConflicts = function () {
  return jspb.Message.getField(this, 11) != null;
};

/**
 * optional FeatureSet features = 12;
 * @return {?proto.google.protobuf.FeatureSet}
 */
proto.google.protobuf.MessageOptions.prototype.getFeatures = function () {
  return /** @type{?proto.google.protobuf.FeatureSet} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FeatureSet, 12)
  );
};

/**
 * @param {?proto.google.protobuf.FeatureSet|undefined} value
 * @return {!proto.google.protobuf.MessageOptions} returns this
*/
proto.google.protobuf.MessageOptions.prototype.setFeatures = function (value) {
  return jspb.Message.setWrapperField(this, 12, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.MessageOptions} returns this
 */
proto.google.protobuf.MessageOptions.prototype.clearFeatures = function () {
  return this.setFeatures(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MessageOptions.prototype.hasFeatures = function () {
  return jspb.Message.getField(this, 12) != null;
};

/**
 * repeated UninterpretedOption uninterpreted_option = 999;
 * @return {!Array<!proto.google.protobuf.UninterpretedOption>}
 */
proto.google.protobuf.MessageOptions.prototype.getUninterpretedOptionList = function () {
  return /** @type{!Array<!proto.google.protobuf.UninterpretedOption>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.UninterpretedOption, 999)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.UninterpretedOption>} value
 * @return {!proto.google.protobuf.MessageOptions} returns this
*/
proto.google.protobuf.MessageOptions.prototype.setUninterpretedOptionList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 999, value);
};

/**
 * @param {!proto.google.protobuf.UninterpretedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.UninterpretedOption}
 */
proto.google.protobuf.MessageOptions.prototype.addUninterpretedOption = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 999, opt_value, proto.google.protobuf.UninterpretedOption, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.MessageOptions} returns this
 */
proto.google.protobuf.MessageOptions.prototype.clearUninterpretedOptionList = function () {
  return this.setUninterpretedOptionList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.FieldOptions.repeatedFields_ = [19, 20, 999];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.FieldOptions.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.FieldOptions.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.FieldOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.FieldOptions.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        ctype: jspb.Message.getFieldWithDefault(msg, 1, 0),
        packed: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
        jstype: jspb.Message.getFieldWithDefault(msg, 6, 0),
        lazy: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
        unverifiedLazy: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
        deprecated: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
        weak: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
        debugRedact: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
        retention: (f = jspb.Message.getField(msg, 17)) == null ? undefined : f,
        targetsList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
        editionDefaultsList: jspb.Message.toObjectList(msg.getEditionDefaultsList(), proto.google.protobuf.FieldOptions.EditionDefault.toObject, includeInstance),
        features: (f = msg.getFeatures()) && proto.google.protobuf.FeatureSet.toObject(includeInstance, f),
        featureSupport: (f = msg.getFeatureSupport()) && proto.google.protobuf.FieldOptions.FeatureSupport.toObject(includeInstance, f),
        uninterpretedOptionList: jspb.Message.toObjectList(msg.getUninterpretedOptionList(), proto.google.protobuf.UninterpretedOption.toObject, includeInstance)
      };
    jspb.Message.toObjectExtension( /** @type {!jspb.Message} */msg, obj, proto.google.protobuf.FieldOptions.extensions, proto.google.protobuf.FieldOptions.prototype.getExtension, includeInstance);
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.FieldOptions}
 */
proto.google.protobuf.FieldOptions.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.FieldOptions();
  return proto.google.protobuf.FieldOptions.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.FieldOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.FieldOptions}
 */
proto.google.protobuf.FieldOptions.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.google.protobuf.FieldOptions.CType} */reader.readEnum();
        msg.setCtype(value);
        break;
      case 2:
        var value = /** @type {boolean} */reader.readBool();
        msg.setPacked(value);
        break;
      case 6:
        var value = /** @type {!proto.google.protobuf.FieldOptions.JSType} */reader.readEnum();
        msg.setJstype(value);
        break;
      case 5:
        var value = /** @type {boolean} */reader.readBool();
        msg.setLazy(value);
        break;
      case 15:
        var value = /** @type {boolean} */reader.readBool();
        msg.setUnverifiedLazy(value);
        break;
      case 3:
        var value = /** @type {boolean} */reader.readBool();
        msg.setDeprecated(value);
        break;
      case 10:
        var value = /** @type {boolean} */reader.readBool();
        msg.setWeak(value);
        break;
      case 16:
        var value = /** @type {boolean} */reader.readBool();
        msg.setDebugRedact(value);
        break;
      case 17:
        var value = /** @type {!proto.google.protobuf.FieldOptions.OptionRetention} */reader.readEnum();
        msg.setRetention(value);
        break;
      case 19:
        var values = /** @type {!Array<!proto.google.protobuf.FieldOptions.OptionTargetType>} */reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()];
        for (var i = 0; i < values.length; i++) {
          msg.addTargets(values[i]);
        }
        break;
      case 20:
        var value = new proto.google.protobuf.FieldOptions.EditionDefault();
        reader.readMessage(value, proto.google.protobuf.FieldOptions.EditionDefault.deserializeBinaryFromReader);
        msg.addEditionDefaults(value);
        break;
      case 21:
        var value = new proto.google.protobuf.FeatureSet();
        reader.readMessage(value, proto.google.protobuf.FeatureSet.deserializeBinaryFromReader);
        msg.setFeatures(value);
        break;
      case 22:
        var value = new proto.google.protobuf.FieldOptions.FeatureSupport();
        reader.readMessage(value, proto.google.protobuf.FieldOptions.FeatureSupport.deserializeBinaryFromReader);
        msg.setFeatureSupport(value);
        break;
      case 999:
        var value = new proto.google.protobuf.UninterpretedOption();
        reader.readMessage(value, proto.google.protobuf.UninterpretedOption.deserializeBinaryFromReader);
        msg.addUninterpretedOption(value);
        break;
      default:
        jspb.Message.readBinaryExtension(msg, reader, proto.google.protobuf.FieldOptions.extensionsBinary, proto.google.protobuf.FieldOptions.prototype.getExtension, proto.google.protobuf.FieldOptions.prototype.setExtension);
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.FieldOptions.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.FieldOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.FieldOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.FieldOptions.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.protobuf.FieldOptions.CType} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeEnum(1, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeBool(2, f);
  }
  f = /** @type {!proto.google.protobuf.FieldOptions.JSType} */jspb.Message.getField(message, 6);
  if (f != null) {
    writer.writeEnum(6, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 5);
  if (f != null) {
    writer.writeBool(5, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 15);
  if (f != null) {
    writer.writeBool(15, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeBool(3, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 10);
  if (f != null) {
    writer.writeBool(10, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 16);
  if (f != null) {
    writer.writeBool(16, f);
  }
  f = /** @type {!proto.google.protobuf.FieldOptions.OptionRetention} */jspb.Message.getField(message, 17);
  if (f != null) {
    writer.writeEnum(17, f);
  }
  f = message.getTargetsList();
  if (f.length > 0) {
    writer.writeRepeatedEnum(19, f);
  }
  f = message.getEditionDefaultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(20, f, proto.google.protobuf.FieldOptions.EditionDefault.serializeBinaryToWriter);
  }
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(21, f, proto.google.protobuf.FeatureSet.serializeBinaryToWriter);
  }
  f = message.getFeatureSupport();
  if (f != null) {
    writer.writeMessage(22, f, proto.google.protobuf.FieldOptions.FeatureSupport.serializeBinaryToWriter);
  }
  f = message.getUninterpretedOptionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(999, f, proto.google.protobuf.UninterpretedOption.serializeBinaryToWriter);
  }
  jspb.Message.serializeBinaryExtensions(message, writer, proto.google.protobuf.FieldOptions.extensionsBinary, proto.google.protobuf.FieldOptions.prototype.getExtension);
};

/**
 * @enum {number}
 */
proto.google.protobuf.FieldOptions.CType = {
  STRING: 0,
  CORD: 1,
  STRING_PIECE: 2
};

/**
 * @enum {number}
 */
proto.google.protobuf.FieldOptions.JSType = {
  JS_NORMAL: 0,
  JS_STRING: 1,
  JS_NUMBER: 2
};

/**
 * @enum {number}
 */
proto.google.protobuf.FieldOptions.OptionRetention = {
  RETENTION_UNKNOWN: 0,
  RETENTION_RUNTIME: 1,
  RETENTION_SOURCE: 2
};

/**
 * @enum {number}
 */
proto.google.protobuf.FieldOptions.OptionTargetType = {
  TARGET_TYPE_UNKNOWN: 0,
  TARGET_TYPE_FILE: 1,
  TARGET_TYPE_EXTENSION_RANGE: 2,
  TARGET_TYPE_MESSAGE: 3,
  TARGET_TYPE_FIELD: 4,
  TARGET_TYPE_ONEOF: 5,
  TARGET_TYPE_ENUM: 6,
  TARGET_TYPE_ENUM_ENTRY: 7,
  TARGET_TYPE_SERVICE: 8,
  TARGET_TYPE_METHOD: 9
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.FieldOptions.EditionDefault.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.FieldOptions.EditionDefault.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.FieldOptions.EditionDefault} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.FieldOptions.EditionDefault.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        edition: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        value: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.FieldOptions.EditionDefault}
 */
proto.google.protobuf.FieldOptions.EditionDefault.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.FieldOptions.EditionDefault();
  return proto.google.protobuf.FieldOptions.EditionDefault.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.FieldOptions.EditionDefault} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.FieldOptions.EditionDefault}
 */
proto.google.protobuf.FieldOptions.EditionDefault.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 3:
        var value = /** @type {!proto.google.protobuf.Edition} */reader.readEnum();
        msg.setEdition(value);
        break;
      case 2:
        var value = /** @type {string} */reader.readString();
        msg.setValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.FieldOptions.EditionDefault.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.FieldOptions.EditionDefault.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.FieldOptions.EditionDefault} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.FieldOptions.EditionDefault.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.protobuf.Edition} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeEnum(3, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeString(2, f);
  }
};

/**
 * optional Edition edition = 3;
 * @return {!proto.google.protobuf.Edition}
 */
proto.google.protobuf.FieldOptions.EditionDefault.prototype.getEdition = function () {
  return /** @type {!proto.google.protobuf.Edition} */jspb.Message.getFieldWithDefault(this, 3, 0);
};

/**
 * @param {!proto.google.protobuf.Edition} value
 * @return {!proto.google.protobuf.FieldOptions.EditionDefault} returns this
 */
proto.google.protobuf.FieldOptions.EditionDefault.prototype.setEdition = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions.EditionDefault} returns this
 */
proto.google.protobuf.FieldOptions.EditionDefault.prototype.clearEdition = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.EditionDefault.prototype.hasEdition = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional string value = 2;
 * @return {string}
 */
proto.google.protobuf.FieldOptions.EditionDefault.prototype.getValue = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 2, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FieldOptions.EditionDefault} returns this
 */
proto.google.protobuf.FieldOptions.EditionDefault.prototype.setValue = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions.EditionDefault} returns this
 */
proto.google.protobuf.FieldOptions.EditionDefault.prototype.clearValue = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.EditionDefault.prototype.hasValue = function () {
  return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.FieldOptions.FeatureSupport.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.FieldOptions.FeatureSupport.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.FieldOptions.FeatureSupport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.FieldOptions.FeatureSupport.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        editionIntroduced: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        editionDeprecated: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        deprecationWarning: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        editionRemoved: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.FieldOptions.FeatureSupport}
 */
proto.google.protobuf.FieldOptions.FeatureSupport.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.FieldOptions.FeatureSupport();
  return proto.google.protobuf.FieldOptions.FeatureSupport.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.FieldOptions.FeatureSupport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.FieldOptions.FeatureSupport}
 */
proto.google.protobuf.FieldOptions.FeatureSupport.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.google.protobuf.Edition} */reader.readEnum();
        msg.setEditionIntroduced(value);
        break;
      case 2:
        var value = /** @type {!proto.google.protobuf.Edition} */reader.readEnum();
        msg.setEditionDeprecated(value);
        break;
      case 3:
        var value = /** @type {string} */reader.readString();
        msg.setDeprecationWarning(value);
        break;
      case 4:
        var value = /** @type {!proto.google.protobuf.Edition} */reader.readEnum();
        msg.setEditionRemoved(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.FieldOptions.FeatureSupport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.FieldOptions.FeatureSupport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.FieldOptions.FeatureSupport.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.protobuf.Edition} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeEnum(1, f);
  }
  f = /** @type {!proto.google.protobuf.Edition} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeEnum(2, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeString(3, f);
  }
  f = /** @type {!proto.google.protobuf.Edition} */jspb.Message.getField(message, 4);
  if (f != null) {
    writer.writeEnum(4, f);
  }
};

/**
 * optional Edition edition_introduced = 1;
 * @return {!proto.google.protobuf.Edition}
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.getEditionIntroduced = function () {
  return /** @type {!proto.google.protobuf.Edition} */jspb.Message.getFieldWithDefault(this, 1, 0);
};

/**
 * @param {!proto.google.protobuf.Edition} value
 * @return {!proto.google.protobuf.FieldOptions.FeatureSupport} returns this
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.setEditionIntroduced = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions.FeatureSupport} returns this
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.clearEditionIntroduced = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.hasEditionIntroduced = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Edition edition_deprecated = 2;
 * @return {!proto.google.protobuf.Edition}
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.getEditionDeprecated = function () {
  return /** @type {!proto.google.protobuf.Edition} */jspb.Message.getFieldWithDefault(this, 2, 0);
};

/**
 * @param {!proto.google.protobuf.Edition} value
 * @return {!proto.google.protobuf.FieldOptions.FeatureSupport} returns this
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.setEditionDeprecated = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions.FeatureSupport} returns this
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.clearEditionDeprecated = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.hasEditionDeprecated = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string deprecation_warning = 3;
 * @return {string}
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.getDeprecationWarning = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 3, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.FieldOptions.FeatureSupport} returns this
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.setDeprecationWarning = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions.FeatureSupport} returns this
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.clearDeprecationWarning = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.hasDeprecationWarning = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Edition edition_removed = 4;
 * @return {!proto.google.protobuf.Edition}
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.getEditionRemoved = function () {
  return /** @type {!proto.google.protobuf.Edition} */jspb.Message.getFieldWithDefault(this, 4, 0);
};

/**
 * @param {!proto.google.protobuf.Edition} value
 * @return {!proto.google.protobuf.FieldOptions.FeatureSupport} returns this
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.setEditionRemoved = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions.FeatureSupport} returns this
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.clearEditionRemoved = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.FeatureSupport.prototype.hasEditionRemoved = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional CType ctype = 1;
 * @return {!proto.google.protobuf.FieldOptions.CType}
 */
proto.google.protobuf.FieldOptions.prototype.getCtype = function () {
  return /** @type {!proto.google.protobuf.FieldOptions.CType} */jspb.Message.getFieldWithDefault(this, 1, 0);
};

/**
 * @param {!proto.google.protobuf.FieldOptions.CType} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.setCtype = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearCtype = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.hasCtype = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional bool packed = 2;
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.getPacked = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 2, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.setPacked = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearPacked = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.hasPacked = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional JSType jstype = 6;
 * @return {!proto.google.protobuf.FieldOptions.JSType}
 */
proto.google.protobuf.FieldOptions.prototype.getJstype = function () {
  return /** @type {!proto.google.protobuf.FieldOptions.JSType} */jspb.Message.getFieldWithDefault(this, 6, 0);
};

/**
 * @param {!proto.google.protobuf.FieldOptions.JSType} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.setJstype = function (value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearJstype = function () {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.hasJstype = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional bool lazy = 5;
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.getLazy = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 5, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.setLazy = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearLazy = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.hasLazy = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional bool unverified_lazy = 15;
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.getUnverifiedLazy = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 15, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.setUnverifiedLazy = function (value) {
  return jspb.Message.setField(this, 15, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearUnverifiedLazy = function () {
  return jspb.Message.setField(this, 15, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.hasUnverifiedLazy = function () {
  return jspb.Message.getField(this, 15) != null;
};

/**
 * optional bool deprecated = 3;
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.getDeprecated = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 3, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.setDeprecated = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearDeprecated = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.hasDeprecated = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional bool weak = 10;
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.getWeak = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 10, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.setWeak = function (value) {
  return jspb.Message.setField(this, 10, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearWeak = function () {
  return jspb.Message.setField(this, 10, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.hasWeak = function () {
  return jspb.Message.getField(this, 10) != null;
};

/**
 * optional bool debug_redact = 16;
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.getDebugRedact = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 16, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.setDebugRedact = function (value) {
  return jspb.Message.setField(this, 16, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearDebugRedact = function () {
  return jspb.Message.setField(this, 16, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.hasDebugRedact = function () {
  return jspb.Message.getField(this, 16) != null;
};

/**
 * optional OptionRetention retention = 17;
 * @return {!proto.google.protobuf.FieldOptions.OptionRetention}
 */
proto.google.protobuf.FieldOptions.prototype.getRetention = function () {
  return /** @type {!proto.google.protobuf.FieldOptions.OptionRetention} */jspb.Message.getFieldWithDefault(this, 17, 0);
};

/**
 * @param {!proto.google.protobuf.FieldOptions.OptionRetention} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.setRetention = function (value) {
  return jspb.Message.setField(this, 17, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearRetention = function () {
  return jspb.Message.setField(this, 17, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.hasRetention = function () {
  return jspb.Message.getField(this, 17) != null;
};

/**
 * repeated OptionTargetType targets = 19;
 * @return {!Array<!proto.google.protobuf.FieldOptions.OptionTargetType>}
 */
proto.google.protobuf.FieldOptions.prototype.getTargetsList = function () {
  return /** @type {!Array<!proto.google.protobuf.FieldOptions.OptionTargetType>} */jspb.Message.getRepeatedField(this, 19);
};

/**
 * @param {!Array<!proto.google.protobuf.FieldOptions.OptionTargetType>} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.setTargetsList = function (value) {
  return jspb.Message.setField(this, 19, value || []);
};

/**
 * @param {!proto.google.protobuf.FieldOptions.OptionTargetType} value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.addTargets = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearTargetsList = function () {
  return this.setTargetsList([]);
};

/**
 * repeated EditionDefault edition_defaults = 20;
 * @return {!Array<!proto.google.protobuf.FieldOptions.EditionDefault>}
 */
proto.google.protobuf.FieldOptions.prototype.getEditionDefaultsList = function () {
  return /** @type{!Array<!proto.google.protobuf.FieldOptions.EditionDefault>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.FieldOptions.EditionDefault, 20)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.FieldOptions.EditionDefault>} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
*/
proto.google.protobuf.FieldOptions.prototype.setEditionDefaultsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};

/**
 * @param {!proto.google.protobuf.FieldOptions.EditionDefault=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.FieldOptions.EditionDefault}
 */
proto.google.protobuf.FieldOptions.prototype.addEditionDefaults = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.google.protobuf.FieldOptions.EditionDefault, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearEditionDefaultsList = function () {
  return this.setEditionDefaultsList([]);
};

/**
 * optional FeatureSet features = 21;
 * @return {?proto.google.protobuf.FeatureSet}
 */
proto.google.protobuf.FieldOptions.prototype.getFeatures = function () {
  return /** @type{?proto.google.protobuf.FeatureSet} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FeatureSet, 21)
  );
};

/**
 * @param {?proto.google.protobuf.FeatureSet|undefined} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
*/
proto.google.protobuf.FieldOptions.prototype.setFeatures = function (value) {
  return jspb.Message.setWrapperField(this, 21, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearFeatures = function () {
  return this.setFeatures(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.hasFeatures = function () {
  return jspb.Message.getField(this, 21) != null;
};

/**
 * optional FeatureSupport feature_support = 22;
 * @return {?proto.google.protobuf.FieldOptions.FeatureSupport}
 */
proto.google.protobuf.FieldOptions.prototype.getFeatureSupport = function () {
  return /** @type{?proto.google.protobuf.FieldOptions.FeatureSupport} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FieldOptions.FeatureSupport, 22)
  );
};

/**
 * @param {?proto.google.protobuf.FieldOptions.FeatureSupport|undefined} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
*/
proto.google.protobuf.FieldOptions.prototype.setFeatureSupport = function (value) {
  return jspb.Message.setWrapperField(this, 22, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearFeatureSupport = function () {
  return this.setFeatureSupport(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FieldOptions.prototype.hasFeatureSupport = function () {
  return jspb.Message.getField(this, 22) != null;
};

/**
 * repeated UninterpretedOption uninterpreted_option = 999;
 * @return {!Array<!proto.google.protobuf.UninterpretedOption>}
 */
proto.google.protobuf.FieldOptions.prototype.getUninterpretedOptionList = function () {
  return /** @type{!Array<!proto.google.protobuf.UninterpretedOption>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.UninterpretedOption, 999)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.UninterpretedOption>} value
 * @return {!proto.google.protobuf.FieldOptions} returns this
*/
proto.google.protobuf.FieldOptions.prototype.setUninterpretedOptionList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 999, value);
};

/**
 * @param {!proto.google.protobuf.UninterpretedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.UninterpretedOption}
 */
proto.google.protobuf.FieldOptions.prototype.addUninterpretedOption = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 999, opt_value, proto.google.protobuf.UninterpretedOption, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.FieldOptions} returns this
 */
proto.google.protobuf.FieldOptions.prototype.clearUninterpretedOptionList = function () {
  return this.setUninterpretedOptionList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.OneofOptions.repeatedFields_ = [999];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.OneofOptions.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.OneofOptions.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.OneofOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.OneofOptions.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        features: (f = msg.getFeatures()) && proto.google.protobuf.FeatureSet.toObject(includeInstance, f),
        uninterpretedOptionList: jspb.Message.toObjectList(msg.getUninterpretedOptionList(), proto.google.protobuf.UninterpretedOption.toObject, includeInstance)
      };
    jspb.Message.toObjectExtension( /** @type {!jspb.Message} */msg, obj, proto.google.protobuf.OneofOptions.extensions, proto.google.protobuf.OneofOptions.prototype.getExtension, includeInstance);
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.OneofOptions}
 */
proto.google.protobuf.OneofOptions.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.OneofOptions();
  return proto.google.protobuf.OneofOptions.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.OneofOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.OneofOptions}
 */
proto.google.protobuf.OneofOptions.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.google.protobuf.FeatureSet();
        reader.readMessage(value, proto.google.protobuf.FeatureSet.deserializeBinaryFromReader);
        msg.setFeatures(value);
        break;
      case 999:
        var value = new proto.google.protobuf.UninterpretedOption();
        reader.readMessage(value, proto.google.protobuf.UninterpretedOption.deserializeBinaryFromReader);
        msg.addUninterpretedOption(value);
        break;
      default:
        jspb.Message.readBinaryExtension(msg, reader, proto.google.protobuf.OneofOptions.extensionsBinary, proto.google.protobuf.OneofOptions.prototype.getExtension, proto.google.protobuf.OneofOptions.prototype.setExtension);
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.OneofOptions.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.OneofOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.OneofOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.OneofOptions.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(1, f, proto.google.protobuf.FeatureSet.serializeBinaryToWriter);
  }
  f = message.getUninterpretedOptionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(999, f, proto.google.protobuf.UninterpretedOption.serializeBinaryToWriter);
  }
  jspb.Message.serializeBinaryExtensions(message, writer, proto.google.protobuf.OneofOptions.extensionsBinary, proto.google.protobuf.OneofOptions.prototype.getExtension);
};

/**
 * optional FeatureSet features = 1;
 * @return {?proto.google.protobuf.FeatureSet}
 */
proto.google.protobuf.OneofOptions.prototype.getFeatures = function () {
  return /** @type{?proto.google.protobuf.FeatureSet} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FeatureSet, 1)
  );
};

/**
 * @param {?proto.google.protobuf.FeatureSet|undefined} value
 * @return {!proto.google.protobuf.OneofOptions} returns this
*/
proto.google.protobuf.OneofOptions.prototype.setFeatures = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.OneofOptions} returns this
 */
proto.google.protobuf.OneofOptions.prototype.clearFeatures = function () {
  return this.setFeatures(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.OneofOptions.prototype.hasFeatures = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * repeated UninterpretedOption uninterpreted_option = 999;
 * @return {!Array<!proto.google.protobuf.UninterpretedOption>}
 */
proto.google.protobuf.OneofOptions.prototype.getUninterpretedOptionList = function () {
  return /** @type{!Array<!proto.google.protobuf.UninterpretedOption>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.UninterpretedOption, 999)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.UninterpretedOption>} value
 * @return {!proto.google.protobuf.OneofOptions} returns this
*/
proto.google.protobuf.OneofOptions.prototype.setUninterpretedOptionList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 999, value);
};

/**
 * @param {!proto.google.protobuf.UninterpretedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.UninterpretedOption}
 */
proto.google.protobuf.OneofOptions.prototype.addUninterpretedOption = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 999, opt_value, proto.google.protobuf.UninterpretedOption, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.OneofOptions} returns this
 */
proto.google.protobuf.OneofOptions.prototype.clearUninterpretedOptionList = function () {
  return this.setUninterpretedOptionList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.EnumOptions.repeatedFields_ = [999];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.EnumOptions.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.EnumOptions.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.EnumOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.EnumOptions.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        allowAlias: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
        deprecated: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
        deprecatedLegacyJsonFieldConflicts: (f = jspb.Message.getBooleanField(msg, 6)) == null ? undefined : f,
        features: (f = msg.getFeatures()) && proto.google.protobuf.FeatureSet.toObject(includeInstance, f),
        uninterpretedOptionList: jspb.Message.toObjectList(msg.getUninterpretedOptionList(), proto.google.protobuf.UninterpretedOption.toObject, includeInstance)
      };
    jspb.Message.toObjectExtension( /** @type {!jspb.Message} */msg, obj, proto.google.protobuf.EnumOptions.extensions, proto.google.protobuf.EnumOptions.prototype.getExtension, includeInstance);
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.EnumOptions}
 */
proto.google.protobuf.EnumOptions.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.EnumOptions();
  return proto.google.protobuf.EnumOptions.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.EnumOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.EnumOptions}
 */
proto.google.protobuf.EnumOptions.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 2:
        var value = /** @type {boolean} */reader.readBool();
        msg.setAllowAlias(value);
        break;
      case 3:
        var value = /** @type {boolean} */reader.readBool();
        msg.setDeprecated(value);
        break;
      case 6:
        var value = /** @type {boolean} */reader.readBool();
        msg.setDeprecatedLegacyJsonFieldConflicts(value);
        break;
      case 7:
        var value = new proto.google.protobuf.FeatureSet();
        reader.readMessage(value, proto.google.protobuf.FeatureSet.deserializeBinaryFromReader);
        msg.setFeatures(value);
        break;
      case 999:
        var value = new proto.google.protobuf.UninterpretedOption();
        reader.readMessage(value, proto.google.protobuf.UninterpretedOption.deserializeBinaryFromReader);
        msg.addUninterpretedOption(value);
        break;
      default:
        jspb.Message.readBinaryExtension(msg, reader, proto.google.protobuf.EnumOptions.extensionsBinary, proto.google.protobuf.EnumOptions.prototype.getExtension, proto.google.protobuf.EnumOptions.prototype.setExtension);
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.EnumOptions.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.EnumOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.EnumOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.EnumOptions.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {boolean} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeBool(2, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeBool(3, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 6);
  if (f != null) {
    writer.writeBool(6, f);
  }
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(7, f, proto.google.protobuf.FeatureSet.serializeBinaryToWriter);
  }
  f = message.getUninterpretedOptionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(999, f, proto.google.protobuf.UninterpretedOption.serializeBinaryToWriter);
  }
  jspb.Message.serializeBinaryExtensions(message, writer, proto.google.protobuf.EnumOptions.extensionsBinary, proto.google.protobuf.EnumOptions.prototype.getExtension);
};

/**
 * optional bool allow_alias = 2;
 * @return {boolean}
 */
proto.google.protobuf.EnumOptions.prototype.getAllowAlias = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 2, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.EnumOptions} returns this
 */
proto.google.protobuf.EnumOptions.prototype.setAllowAlias = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.EnumOptions} returns this
 */
proto.google.protobuf.EnumOptions.prototype.clearAllowAlias = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumOptions.prototype.hasAllowAlias = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional bool deprecated = 3;
 * @return {boolean}
 */
proto.google.protobuf.EnumOptions.prototype.getDeprecated = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 3, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.EnumOptions} returns this
 */
proto.google.protobuf.EnumOptions.prototype.setDeprecated = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.EnumOptions} returns this
 */
proto.google.protobuf.EnumOptions.prototype.clearDeprecated = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumOptions.prototype.hasDeprecated = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional bool deprecated_legacy_json_field_conflicts = 6;
 * @return {boolean}
 */
proto.google.protobuf.EnumOptions.prototype.getDeprecatedLegacyJsonFieldConflicts = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 6, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.EnumOptions} returns this
 */
proto.google.protobuf.EnumOptions.prototype.setDeprecatedLegacyJsonFieldConflicts = function (value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.EnumOptions} returns this
 */
proto.google.protobuf.EnumOptions.prototype.clearDeprecatedLegacyJsonFieldConflicts = function () {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumOptions.prototype.hasDeprecatedLegacyJsonFieldConflicts = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional FeatureSet features = 7;
 * @return {?proto.google.protobuf.FeatureSet}
 */
proto.google.protobuf.EnumOptions.prototype.getFeatures = function () {
  return /** @type{?proto.google.protobuf.FeatureSet} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FeatureSet, 7)
  );
};

/**
 * @param {?proto.google.protobuf.FeatureSet|undefined} value
 * @return {!proto.google.protobuf.EnumOptions} returns this
*/
proto.google.protobuf.EnumOptions.prototype.setFeatures = function (value) {
  return jspb.Message.setWrapperField(this, 7, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.EnumOptions} returns this
 */
proto.google.protobuf.EnumOptions.prototype.clearFeatures = function () {
  return this.setFeatures(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumOptions.prototype.hasFeatures = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * repeated UninterpretedOption uninterpreted_option = 999;
 * @return {!Array<!proto.google.protobuf.UninterpretedOption>}
 */
proto.google.protobuf.EnumOptions.prototype.getUninterpretedOptionList = function () {
  return /** @type{!Array<!proto.google.protobuf.UninterpretedOption>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.UninterpretedOption, 999)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.UninterpretedOption>} value
 * @return {!proto.google.protobuf.EnumOptions} returns this
*/
proto.google.protobuf.EnumOptions.prototype.setUninterpretedOptionList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 999, value);
};

/**
 * @param {!proto.google.protobuf.UninterpretedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.UninterpretedOption}
 */
proto.google.protobuf.EnumOptions.prototype.addUninterpretedOption = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 999, opt_value, proto.google.protobuf.UninterpretedOption, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.EnumOptions} returns this
 */
proto.google.protobuf.EnumOptions.prototype.clearUninterpretedOptionList = function () {
  return this.setUninterpretedOptionList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.EnumValueOptions.repeatedFields_ = [999];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.EnumValueOptions.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.EnumValueOptions.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.EnumValueOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.EnumValueOptions.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        deprecated: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
        features: (f = msg.getFeatures()) && proto.google.protobuf.FeatureSet.toObject(includeInstance, f),
        debugRedact: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
        featureSupport: (f = msg.getFeatureSupport()) && proto.google.protobuf.FieldOptions.FeatureSupport.toObject(includeInstance, f),
        uninterpretedOptionList: jspb.Message.toObjectList(msg.getUninterpretedOptionList(), proto.google.protobuf.UninterpretedOption.toObject, includeInstance)
      };
    jspb.Message.toObjectExtension( /** @type {!jspb.Message} */msg, obj, proto.google.protobuf.EnumValueOptions.extensions, proto.google.protobuf.EnumValueOptions.prototype.getExtension, includeInstance);
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.EnumValueOptions}
 */
proto.google.protobuf.EnumValueOptions.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.EnumValueOptions();
  return proto.google.protobuf.EnumValueOptions.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.EnumValueOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.EnumValueOptions}
 */
proto.google.protobuf.EnumValueOptions.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */reader.readBool();
        msg.setDeprecated(value);
        break;
      case 2:
        var value = new proto.google.protobuf.FeatureSet();
        reader.readMessage(value, proto.google.protobuf.FeatureSet.deserializeBinaryFromReader);
        msg.setFeatures(value);
        break;
      case 3:
        var value = /** @type {boolean} */reader.readBool();
        msg.setDebugRedact(value);
        break;
      case 4:
        var value = new proto.google.protobuf.FieldOptions.FeatureSupport();
        reader.readMessage(value, proto.google.protobuf.FieldOptions.FeatureSupport.deserializeBinaryFromReader);
        msg.setFeatureSupport(value);
        break;
      case 999:
        var value = new proto.google.protobuf.UninterpretedOption();
        reader.readMessage(value, proto.google.protobuf.UninterpretedOption.deserializeBinaryFromReader);
        msg.addUninterpretedOption(value);
        break;
      default:
        jspb.Message.readBinaryExtension(msg, reader, proto.google.protobuf.EnumValueOptions.extensionsBinary, proto.google.protobuf.EnumValueOptions.prototype.getExtension, proto.google.protobuf.EnumValueOptions.prototype.setExtension);
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.EnumValueOptions.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.EnumValueOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.EnumValueOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.EnumValueOptions.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {boolean} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeBool(1, f);
  }
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(2, f, proto.google.protobuf.FeatureSet.serializeBinaryToWriter);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeBool(3, f);
  }
  f = message.getFeatureSupport();
  if (f != null) {
    writer.writeMessage(4, f, proto.google.protobuf.FieldOptions.FeatureSupport.serializeBinaryToWriter);
  }
  f = message.getUninterpretedOptionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(999, f, proto.google.protobuf.UninterpretedOption.serializeBinaryToWriter);
  }
  jspb.Message.serializeBinaryExtensions(message, writer, proto.google.protobuf.EnumValueOptions.extensionsBinary, proto.google.protobuf.EnumValueOptions.prototype.getExtension);
};

/**
 * optional bool deprecated = 1;
 * @return {boolean}
 */
proto.google.protobuf.EnumValueOptions.prototype.getDeprecated = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 1, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.EnumValueOptions} returns this
 */
proto.google.protobuf.EnumValueOptions.prototype.setDeprecated = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.EnumValueOptions} returns this
 */
proto.google.protobuf.EnumValueOptions.prototype.clearDeprecated = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumValueOptions.prototype.hasDeprecated = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional FeatureSet features = 2;
 * @return {?proto.google.protobuf.FeatureSet}
 */
proto.google.protobuf.EnumValueOptions.prototype.getFeatures = function () {
  return /** @type{?proto.google.protobuf.FeatureSet} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FeatureSet, 2)
  );
};

/**
 * @param {?proto.google.protobuf.FeatureSet|undefined} value
 * @return {!proto.google.protobuf.EnumValueOptions} returns this
*/
proto.google.protobuf.EnumValueOptions.prototype.setFeatures = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.EnumValueOptions} returns this
 */
proto.google.protobuf.EnumValueOptions.prototype.clearFeatures = function () {
  return this.setFeatures(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumValueOptions.prototype.hasFeatures = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional bool debug_redact = 3;
 * @return {boolean}
 */
proto.google.protobuf.EnumValueOptions.prototype.getDebugRedact = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 3, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.EnumValueOptions} returns this
 */
proto.google.protobuf.EnumValueOptions.prototype.setDebugRedact = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.EnumValueOptions} returns this
 */
proto.google.protobuf.EnumValueOptions.prototype.clearDebugRedact = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumValueOptions.prototype.hasDebugRedact = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional FieldOptions.FeatureSupport feature_support = 4;
 * @return {?proto.google.protobuf.FieldOptions.FeatureSupport}
 */
proto.google.protobuf.EnumValueOptions.prototype.getFeatureSupport = function () {
  return /** @type{?proto.google.protobuf.FieldOptions.FeatureSupport} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FieldOptions.FeatureSupport, 4)
  );
};

/**
 * @param {?proto.google.protobuf.FieldOptions.FeatureSupport|undefined} value
 * @return {!proto.google.protobuf.EnumValueOptions} returns this
*/
proto.google.protobuf.EnumValueOptions.prototype.setFeatureSupport = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.EnumValueOptions} returns this
 */
proto.google.protobuf.EnumValueOptions.prototype.clearFeatureSupport = function () {
  return this.setFeatureSupport(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.EnumValueOptions.prototype.hasFeatureSupport = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * repeated UninterpretedOption uninterpreted_option = 999;
 * @return {!Array<!proto.google.protobuf.UninterpretedOption>}
 */
proto.google.protobuf.EnumValueOptions.prototype.getUninterpretedOptionList = function () {
  return /** @type{!Array<!proto.google.protobuf.UninterpretedOption>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.UninterpretedOption, 999)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.UninterpretedOption>} value
 * @return {!proto.google.protobuf.EnumValueOptions} returns this
*/
proto.google.protobuf.EnumValueOptions.prototype.setUninterpretedOptionList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 999, value);
};

/**
 * @param {!proto.google.protobuf.UninterpretedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.UninterpretedOption}
 */
proto.google.protobuf.EnumValueOptions.prototype.addUninterpretedOption = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 999, opt_value, proto.google.protobuf.UninterpretedOption, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.EnumValueOptions} returns this
 */
proto.google.protobuf.EnumValueOptions.prototype.clearUninterpretedOptionList = function () {
  return this.setUninterpretedOptionList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.ServiceOptions.repeatedFields_ = [999];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.ServiceOptions.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.ServiceOptions.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.ServiceOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.ServiceOptions.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        features: (f = msg.getFeatures()) && proto.google.protobuf.FeatureSet.toObject(includeInstance, f),
        deprecated: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
        uninterpretedOptionList: jspb.Message.toObjectList(msg.getUninterpretedOptionList(), proto.google.protobuf.UninterpretedOption.toObject, includeInstance)
      };
    jspb.Message.toObjectExtension( /** @type {!jspb.Message} */msg, obj, proto.google.protobuf.ServiceOptions.extensions, proto.google.protobuf.ServiceOptions.prototype.getExtension, includeInstance);
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.ServiceOptions}
 */
proto.google.protobuf.ServiceOptions.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.ServiceOptions();
  return proto.google.protobuf.ServiceOptions.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.ServiceOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.ServiceOptions}
 */
proto.google.protobuf.ServiceOptions.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 34:
        var value = new proto.google.protobuf.FeatureSet();
        reader.readMessage(value, proto.google.protobuf.FeatureSet.deserializeBinaryFromReader);
        msg.setFeatures(value);
        break;
      case 33:
        var value = /** @type {boolean} */reader.readBool();
        msg.setDeprecated(value);
        break;
      case 999:
        var value = new proto.google.protobuf.UninterpretedOption();
        reader.readMessage(value, proto.google.protobuf.UninterpretedOption.deserializeBinaryFromReader);
        msg.addUninterpretedOption(value);
        break;
      default:
        jspb.Message.readBinaryExtension(msg, reader, proto.google.protobuf.ServiceOptions.extensionsBinary, proto.google.protobuf.ServiceOptions.prototype.getExtension, proto.google.protobuf.ServiceOptions.prototype.setExtension);
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.ServiceOptions.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.ServiceOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.ServiceOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.ServiceOptions.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(34, f, proto.google.protobuf.FeatureSet.serializeBinaryToWriter);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 33);
  if (f != null) {
    writer.writeBool(33, f);
  }
  f = message.getUninterpretedOptionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(999, f, proto.google.protobuf.UninterpretedOption.serializeBinaryToWriter);
  }
  jspb.Message.serializeBinaryExtensions(message, writer, proto.google.protobuf.ServiceOptions.extensionsBinary, proto.google.protobuf.ServiceOptions.prototype.getExtension);
};

/**
 * optional FeatureSet features = 34;
 * @return {?proto.google.protobuf.FeatureSet}
 */
proto.google.protobuf.ServiceOptions.prototype.getFeatures = function () {
  return /** @type{?proto.google.protobuf.FeatureSet} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FeatureSet, 34)
  );
};

/**
 * @param {?proto.google.protobuf.FeatureSet|undefined} value
 * @return {!proto.google.protobuf.ServiceOptions} returns this
*/
proto.google.protobuf.ServiceOptions.prototype.setFeatures = function (value) {
  return jspb.Message.setWrapperField(this, 34, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.ServiceOptions} returns this
 */
proto.google.protobuf.ServiceOptions.prototype.clearFeatures = function () {
  return this.setFeatures(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.ServiceOptions.prototype.hasFeatures = function () {
  return jspb.Message.getField(this, 34) != null;
};

/**
 * optional bool deprecated = 33;
 * @return {boolean}
 */
proto.google.protobuf.ServiceOptions.prototype.getDeprecated = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 33, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.ServiceOptions} returns this
 */
proto.google.protobuf.ServiceOptions.prototype.setDeprecated = function (value) {
  return jspb.Message.setField(this, 33, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.ServiceOptions} returns this
 */
proto.google.protobuf.ServiceOptions.prototype.clearDeprecated = function () {
  return jspb.Message.setField(this, 33, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.ServiceOptions.prototype.hasDeprecated = function () {
  return jspb.Message.getField(this, 33) != null;
};

/**
 * repeated UninterpretedOption uninterpreted_option = 999;
 * @return {!Array<!proto.google.protobuf.UninterpretedOption>}
 */
proto.google.protobuf.ServiceOptions.prototype.getUninterpretedOptionList = function () {
  return /** @type{!Array<!proto.google.protobuf.UninterpretedOption>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.UninterpretedOption, 999)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.UninterpretedOption>} value
 * @return {!proto.google.protobuf.ServiceOptions} returns this
*/
proto.google.protobuf.ServiceOptions.prototype.setUninterpretedOptionList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 999, value);
};

/**
 * @param {!proto.google.protobuf.UninterpretedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.UninterpretedOption}
 */
proto.google.protobuf.ServiceOptions.prototype.addUninterpretedOption = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 999, opt_value, proto.google.protobuf.UninterpretedOption, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.ServiceOptions} returns this
 */
proto.google.protobuf.ServiceOptions.prototype.clearUninterpretedOptionList = function () {
  return this.setUninterpretedOptionList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.MethodOptions.repeatedFields_ = [999];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.MethodOptions.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.MethodOptions.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.MethodOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.MethodOptions.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        deprecated: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
        idempotencyLevel: jspb.Message.getFieldWithDefault(msg, 34, 0),
        features: (f = msg.getFeatures()) && proto.google.protobuf.FeatureSet.toObject(includeInstance, f),
        uninterpretedOptionList: jspb.Message.toObjectList(msg.getUninterpretedOptionList(), proto.google.protobuf.UninterpretedOption.toObject, includeInstance)
      };
    jspb.Message.toObjectExtension( /** @type {!jspb.Message} */msg, obj, proto.google.protobuf.MethodOptions.extensions, proto.google.protobuf.MethodOptions.prototype.getExtension, includeInstance);
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.MethodOptions}
 */
proto.google.protobuf.MethodOptions.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.MethodOptions();
  return proto.google.protobuf.MethodOptions.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.MethodOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.MethodOptions}
 */
proto.google.protobuf.MethodOptions.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 33:
        var value = /** @type {boolean} */reader.readBool();
        msg.setDeprecated(value);
        break;
      case 34:
        var value = /** @type {!proto.google.protobuf.MethodOptions.IdempotencyLevel} */reader.readEnum();
        msg.setIdempotencyLevel(value);
        break;
      case 35:
        var value = new proto.google.protobuf.FeatureSet();
        reader.readMessage(value, proto.google.protobuf.FeatureSet.deserializeBinaryFromReader);
        msg.setFeatures(value);
        break;
      case 999:
        var value = new proto.google.protobuf.UninterpretedOption();
        reader.readMessage(value, proto.google.protobuf.UninterpretedOption.deserializeBinaryFromReader);
        msg.addUninterpretedOption(value);
        break;
      default:
        jspb.Message.readBinaryExtension(msg, reader, proto.google.protobuf.MethodOptions.extensionsBinary, proto.google.protobuf.MethodOptions.prototype.getExtension, proto.google.protobuf.MethodOptions.prototype.setExtension);
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.MethodOptions.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.MethodOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.MethodOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.MethodOptions.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {boolean} */jspb.Message.getField(message, 33);
  if (f != null) {
    writer.writeBool(33, f);
  }
  f = /** @type {!proto.google.protobuf.MethodOptions.IdempotencyLevel} */jspb.Message.getField(message, 34);
  if (f != null) {
    writer.writeEnum(34, f);
  }
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(35, f, proto.google.protobuf.FeatureSet.serializeBinaryToWriter);
  }
  f = message.getUninterpretedOptionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(999, f, proto.google.protobuf.UninterpretedOption.serializeBinaryToWriter);
  }
  jspb.Message.serializeBinaryExtensions(message, writer, proto.google.protobuf.MethodOptions.extensionsBinary, proto.google.protobuf.MethodOptions.prototype.getExtension);
};

/**
 * @enum {number}
 */
proto.google.protobuf.MethodOptions.IdempotencyLevel = {
  IDEMPOTENCY_UNKNOWN: 0,
  NO_SIDE_EFFECTS: 1,
  IDEMPOTENT: 2
};

/**
 * optional bool deprecated = 33;
 * @return {boolean}
 */
proto.google.protobuf.MethodOptions.prototype.getDeprecated = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 33, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.MethodOptions} returns this
 */
proto.google.protobuf.MethodOptions.prototype.setDeprecated = function (value) {
  return jspb.Message.setField(this, 33, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.MethodOptions} returns this
 */
proto.google.protobuf.MethodOptions.prototype.clearDeprecated = function () {
  return jspb.Message.setField(this, 33, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MethodOptions.prototype.hasDeprecated = function () {
  return jspb.Message.getField(this, 33) != null;
};

/**
 * optional IdempotencyLevel idempotency_level = 34;
 * @return {!proto.google.protobuf.MethodOptions.IdempotencyLevel}
 */
proto.google.protobuf.MethodOptions.prototype.getIdempotencyLevel = function () {
  return /** @type {!proto.google.protobuf.MethodOptions.IdempotencyLevel} */jspb.Message.getFieldWithDefault(this, 34, 0);
};

/**
 * @param {!proto.google.protobuf.MethodOptions.IdempotencyLevel} value
 * @return {!proto.google.protobuf.MethodOptions} returns this
 */
proto.google.protobuf.MethodOptions.prototype.setIdempotencyLevel = function (value) {
  return jspb.Message.setField(this, 34, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.MethodOptions} returns this
 */
proto.google.protobuf.MethodOptions.prototype.clearIdempotencyLevel = function () {
  return jspb.Message.setField(this, 34, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MethodOptions.prototype.hasIdempotencyLevel = function () {
  return jspb.Message.getField(this, 34) != null;
};

/**
 * optional FeatureSet features = 35;
 * @return {?proto.google.protobuf.FeatureSet}
 */
proto.google.protobuf.MethodOptions.prototype.getFeatures = function () {
  return /** @type{?proto.google.protobuf.FeatureSet} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FeatureSet, 35)
  );
};

/**
 * @param {?proto.google.protobuf.FeatureSet|undefined} value
 * @return {!proto.google.protobuf.MethodOptions} returns this
*/
proto.google.protobuf.MethodOptions.prototype.setFeatures = function (value) {
  return jspb.Message.setWrapperField(this, 35, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.MethodOptions} returns this
 */
proto.google.protobuf.MethodOptions.prototype.clearFeatures = function () {
  return this.setFeatures(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.MethodOptions.prototype.hasFeatures = function () {
  return jspb.Message.getField(this, 35) != null;
};

/**
 * repeated UninterpretedOption uninterpreted_option = 999;
 * @return {!Array<!proto.google.protobuf.UninterpretedOption>}
 */
proto.google.protobuf.MethodOptions.prototype.getUninterpretedOptionList = function () {
  return /** @type{!Array<!proto.google.protobuf.UninterpretedOption>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.UninterpretedOption, 999)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.UninterpretedOption>} value
 * @return {!proto.google.protobuf.MethodOptions} returns this
*/
proto.google.protobuf.MethodOptions.prototype.setUninterpretedOptionList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 999, value);
};

/**
 * @param {!proto.google.protobuf.UninterpretedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.UninterpretedOption}
 */
proto.google.protobuf.MethodOptions.prototype.addUninterpretedOption = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 999, opt_value, proto.google.protobuf.UninterpretedOption, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.MethodOptions} returns this
 */
proto.google.protobuf.MethodOptions.prototype.clearUninterpretedOptionList = function () {
  return this.setUninterpretedOptionList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.UninterpretedOption.repeatedFields_ = [2];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.UninterpretedOption.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.UninterpretedOption.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.UninterpretedOption} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.UninterpretedOption.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        nameList: jspb.Message.toObjectList(msg.getNameList(), proto.google.protobuf.UninterpretedOption.NamePart.toObject, includeInstance),
        identifierValue: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        positiveIntValue: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
        negativeIntValue: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
        doubleValue: (f = jspb.Message.getOptionalFloatingPointField(msg, 6)) == null ? undefined : f,
        stringValue: msg.getStringValue_asB64(),
        aggregateValue: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.UninterpretedOption}
 */
proto.google.protobuf.UninterpretedOption.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.UninterpretedOption();
  return proto.google.protobuf.UninterpretedOption.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.UninterpretedOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.UninterpretedOption}
 */
proto.google.protobuf.UninterpretedOption.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 2:
        var value = new proto.google.protobuf.UninterpretedOption.NamePart();
        reader.readMessage(value, proto.google.protobuf.UninterpretedOption.NamePart.deserializeBinaryFromReader);
        msg.addName(value);
        break;
      case 3:
        var value = /** @type {string} */reader.readString();
        msg.setIdentifierValue(value);
        break;
      case 4:
        var value = /** @type {number} */reader.readUint64();
        msg.setPositiveIntValue(value);
        break;
      case 5:
        var value = /** @type {number} */reader.readInt64();
        msg.setNegativeIntValue(value);
        break;
      case 6:
        var value = /** @type {number} */reader.readDouble();
        msg.setDoubleValue(value);
        break;
      case 7:
        var value = /** @type {!Uint8Array} */reader.readBytes();
        msg.setStringValue(value);
        break;
      case 8:
        var value = /** @type {string} */reader.readString();
        msg.setAggregateValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.UninterpretedOption.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.UninterpretedOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.UninterpretedOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.UninterpretedOption.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getNameList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, proto.google.protobuf.UninterpretedOption.NamePart.serializeBinaryToWriter);
  }
  f = /** @type {string} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeString(3, f);
  }
  f = /** @type {number} */jspb.Message.getField(message, 4);
  if (f != null) {
    writer.writeUint64(4, f);
  }
  f = /** @type {number} */jspb.Message.getField(message, 5);
  if (f != null) {
    writer.writeInt64(5, f);
  }
  f = /** @type {number} */jspb.Message.getField(message, 6);
  if (f != null) {
    writer.writeDouble(6, f);
  }
  f = /** @type {!(string|Uint8Array)} */jspb.Message.getField(message, 7);
  if (f != null) {
    writer.writeBytes(7, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 8);
  if (f != null) {
    writer.writeString(8, f);
  }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.UninterpretedOption.NamePart.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.UninterpretedOption.NamePart.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.UninterpretedOption.NamePart} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.UninterpretedOption.NamePart.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        namePart: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        isExtension: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.UninterpretedOption.NamePart}
 */
proto.google.protobuf.UninterpretedOption.NamePart.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.UninterpretedOption.NamePart();
  return proto.google.protobuf.UninterpretedOption.NamePart.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.UninterpretedOption.NamePart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.UninterpretedOption.NamePart}
 */
proto.google.protobuf.UninterpretedOption.NamePart.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */reader.readString();
        msg.setNamePart(value);
        break;
      case 2:
        var value = /** @type {boolean} */reader.readBool();
        msg.setIsExtension(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.UninterpretedOption.NamePart.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.UninterpretedOption.NamePart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.UninterpretedOption.NamePart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.UninterpretedOption.NamePart.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeString(1, f);
  }
  f = /** @type {boolean} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeBool(2, f);
  }
};

/**
 * required string name_part = 1;
 * @return {string}
 */
proto.google.protobuf.UninterpretedOption.NamePart.prototype.getNamePart = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 1, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.UninterpretedOption.NamePart} returns this
 */
proto.google.protobuf.UninterpretedOption.NamePart.prototype.setNamePart = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.UninterpretedOption.NamePart} returns this
 */
proto.google.protobuf.UninterpretedOption.NamePart.prototype.clearNamePart = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.UninterpretedOption.NamePart.prototype.hasNamePart = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * required bool is_extension = 2;
 * @return {boolean}
 */
proto.google.protobuf.UninterpretedOption.NamePart.prototype.getIsExtension = function () {
  return /** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this, 2, false);
};

/**
 * @param {boolean} value
 * @return {!proto.google.protobuf.UninterpretedOption.NamePart} returns this
 */
proto.google.protobuf.UninterpretedOption.NamePart.prototype.setIsExtension = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.UninterpretedOption.NamePart} returns this
 */
proto.google.protobuf.UninterpretedOption.NamePart.prototype.clearIsExtension = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.UninterpretedOption.NamePart.prototype.hasIsExtension = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * repeated NamePart name = 2;
 * @return {!Array<!proto.google.protobuf.UninterpretedOption.NamePart>}
 */
proto.google.protobuf.UninterpretedOption.prototype.getNameList = function () {
  return /** @type{!Array<!proto.google.protobuf.UninterpretedOption.NamePart>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.UninterpretedOption.NamePart, 2)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.UninterpretedOption.NamePart>} value
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
*/
proto.google.protobuf.UninterpretedOption.prototype.setNameList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.google.protobuf.UninterpretedOption.NamePart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.UninterpretedOption.NamePart}
 */
proto.google.protobuf.UninterpretedOption.prototype.addName = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.protobuf.UninterpretedOption.NamePart, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
 */
proto.google.protobuf.UninterpretedOption.prototype.clearNameList = function () {
  return this.setNameList([]);
};

/**
 * optional string identifier_value = 3;
 * @return {string}
 */
proto.google.protobuf.UninterpretedOption.prototype.getIdentifierValue = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 3, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
 */
proto.google.protobuf.UninterpretedOption.prototype.setIdentifierValue = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
 */
proto.google.protobuf.UninterpretedOption.prototype.clearIdentifierValue = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.UninterpretedOption.prototype.hasIdentifierValue = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional uint64 positive_int_value = 4;
 * @return {number}
 */
proto.google.protobuf.UninterpretedOption.prototype.getPositiveIntValue = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 4, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
 */
proto.google.protobuf.UninterpretedOption.prototype.setPositiveIntValue = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
 */
proto.google.protobuf.UninterpretedOption.prototype.clearPositiveIntValue = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.UninterpretedOption.prototype.hasPositiveIntValue = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional int64 negative_int_value = 5;
 * @return {number}
 */
proto.google.protobuf.UninterpretedOption.prototype.getNegativeIntValue = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 5, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
 */
proto.google.protobuf.UninterpretedOption.prototype.setNegativeIntValue = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
 */
proto.google.protobuf.UninterpretedOption.prototype.clearNegativeIntValue = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.UninterpretedOption.prototype.hasNegativeIntValue = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional double double_value = 6;
 * @return {number}
 */
proto.google.protobuf.UninterpretedOption.prototype.getDoubleValue = function () {
  return /** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
 */
proto.google.protobuf.UninterpretedOption.prototype.setDoubleValue = function (value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
 */
proto.google.protobuf.UninterpretedOption.prototype.clearDoubleValue = function () {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.UninterpretedOption.prototype.hasDoubleValue = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional bytes string_value = 7;
 * @return {!(string|Uint8Array)}
 */
proto.google.protobuf.UninterpretedOption.prototype.getStringValue = function () {
  return /** @type {!(string|Uint8Array)} */jspb.Message.getFieldWithDefault(this, 7, "");
};

/**
 * optional bytes string_value = 7;
 * This is a type-conversion wrapper around `getStringValue()`
 * @return {string}
 */
proto.google.protobuf.UninterpretedOption.prototype.getStringValue_asB64 = function () {
  return /** @type {string} */jspb.Message.bytesAsB64(this.getStringValue());
};

/**
 * optional bytes string_value = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStringValue()`
 * @return {!Uint8Array}
 */
proto.google.protobuf.UninterpretedOption.prototype.getStringValue_asU8 = function () {
  return /** @type {!Uint8Array} */jspb.Message.bytesAsU8(this.getStringValue());
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
 */
proto.google.protobuf.UninterpretedOption.prototype.setStringValue = function (value) {
  return jspb.Message.setField(this, 7, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
 */
proto.google.protobuf.UninterpretedOption.prototype.clearStringValue = function () {
  return jspb.Message.setField(this, 7, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.UninterpretedOption.prototype.hasStringValue = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional string aggregate_value = 8;
 * @return {string}
 */
proto.google.protobuf.UninterpretedOption.prototype.getAggregateValue = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 8, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
 */
proto.google.protobuf.UninterpretedOption.prototype.setAggregateValue = function (value) {
  return jspb.Message.setField(this, 8, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.UninterpretedOption} returns this
 */
proto.google.protobuf.UninterpretedOption.prototype.clearAggregateValue = function () {
  return jspb.Message.setField(this, 8, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.UninterpretedOption.prototype.hasAggregateValue = function () {
  return jspb.Message.getField(this, 8) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.FeatureSet.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.FeatureSet.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.FeatureSet} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.FeatureSet.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        fieldPresence: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        enumType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        repeatedFieldEncoding: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        utf8Validation: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
        messageEncoding: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
        jsonFormat: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
      };
    jspb.Message.toObjectExtension( /** @type {!jspb.Message} */msg, obj, proto.google.protobuf.FeatureSet.extensions, proto.google.protobuf.FeatureSet.prototype.getExtension, includeInstance);
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.FeatureSet}
 */
proto.google.protobuf.FeatureSet.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.FeatureSet();
  return proto.google.protobuf.FeatureSet.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.FeatureSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.FeatureSet}
 */
proto.google.protobuf.FeatureSet.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.google.protobuf.FeatureSet.FieldPresence} */reader.readEnum();
        msg.setFieldPresence(value);
        break;
      case 2:
        var value = /** @type {!proto.google.protobuf.FeatureSet.EnumType} */reader.readEnum();
        msg.setEnumType(value);
        break;
      case 3:
        var value = /** @type {!proto.google.protobuf.FeatureSet.RepeatedFieldEncoding} */reader.readEnum();
        msg.setRepeatedFieldEncoding(value);
        break;
      case 4:
        var value = /** @type {!proto.google.protobuf.FeatureSet.Utf8Validation} */reader.readEnum();
        msg.setUtf8Validation(value);
        break;
      case 5:
        var value = /** @type {!proto.google.protobuf.FeatureSet.MessageEncoding} */reader.readEnum();
        msg.setMessageEncoding(value);
        break;
      case 6:
        var value = /** @type {!proto.google.protobuf.FeatureSet.JsonFormat} */reader.readEnum();
        msg.setJsonFormat(value);
        break;
      default:
        jspb.Message.readBinaryExtension(msg, reader, proto.google.protobuf.FeatureSet.extensionsBinary, proto.google.protobuf.FeatureSet.prototype.getExtension, proto.google.protobuf.FeatureSet.prototype.setExtension);
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.FeatureSet.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.FeatureSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.FeatureSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.FeatureSet.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.protobuf.FeatureSet.FieldPresence} */jspb.Message.getField(message, 1);
  if (f != null) {
    writer.writeEnum(1, f);
  }
  f = /** @type {!proto.google.protobuf.FeatureSet.EnumType} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeEnum(2, f);
  }
  f = /** @type {!proto.google.protobuf.FeatureSet.RepeatedFieldEncoding} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeEnum(3, f);
  }
  f = /** @type {!proto.google.protobuf.FeatureSet.Utf8Validation} */jspb.Message.getField(message, 4);
  if (f != null) {
    writer.writeEnum(4, f);
  }
  f = /** @type {!proto.google.protobuf.FeatureSet.MessageEncoding} */jspb.Message.getField(message, 5);
  if (f != null) {
    writer.writeEnum(5, f);
  }
  f = /** @type {!proto.google.protobuf.FeatureSet.JsonFormat} */jspb.Message.getField(message, 6);
  if (f != null) {
    writer.writeEnum(6, f);
  }
  jspb.Message.serializeBinaryExtensions(message, writer, proto.google.protobuf.FeatureSet.extensionsBinary, proto.google.protobuf.FeatureSet.prototype.getExtension);
};

/**
 * @enum {number}
 */
proto.google.protobuf.FeatureSet.FieldPresence = {
  FIELD_PRESENCE_UNKNOWN: 0,
  EXPLICIT: 1,
  IMPLICIT: 2,
  LEGACY_REQUIRED: 3
};

/**
 * @enum {number}
 */
proto.google.protobuf.FeatureSet.EnumType = {
  ENUM_TYPE_UNKNOWN: 0,
  OPEN: 1,
  CLOSED: 2
};

/**
 * @enum {number}
 */
proto.google.protobuf.FeatureSet.RepeatedFieldEncoding = {
  REPEATED_FIELD_ENCODING_UNKNOWN: 0,
  PACKED: 1,
  EXPANDED: 2
};

/**
 * @enum {number}
 */
proto.google.protobuf.FeatureSet.Utf8Validation = {
  UTF8_VALIDATION_UNKNOWN: 0,
  VERIFY: 2,
  NONE: 3
};

/**
 * @enum {number}
 */
proto.google.protobuf.FeatureSet.MessageEncoding = {
  MESSAGE_ENCODING_UNKNOWN: 0,
  LENGTH_PREFIXED: 1,
  DELIMITED: 2
};

/**
 * @enum {number}
 */
proto.google.protobuf.FeatureSet.JsonFormat = {
  JSON_FORMAT_UNKNOWN: 0,
  ALLOW: 1,
  LEGACY_BEST_EFFORT: 2
};

/**
 * optional FieldPresence field_presence = 1;
 * @return {!proto.google.protobuf.FeatureSet.FieldPresence}
 */
proto.google.protobuf.FeatureSet.prototype.getFieldPresence = function () {
  return /** @type {!proto.google.protobuf.FeatureSet.FieldPresence} */jspb.Message.getFieldWithDefault(this, 1, 0);
};

/**
 * @param {!proto.google.protobuf.FeatureSet.FieldPresence} value
 * @return {!proto.google.protobuf.FeatureSet} returns this
 */
proto.google.protobuf.FeatureSet.prototype.setFieldPresence = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FeatureSet} returns this
 */
proto.google.protobuf.FeatureSet.prototype.clearFieldPresence = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FeatureSet.prototype.hasFieldPresence = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional EnumType enum_type = 2;
 * @return {!proto.google.protobuf.FeatureSet.EnumType}
 */
proto.google.protobuf.FeatureSet.prototype.getEnumType = function () {
  return /** @type {!proto.google.protobuf.FeatureSet.EnumType} */jspb.Message.getFieldWithDefault(this, 2, 0);
};

/**
 * @param {!proto.google.protobuf.FeatureSet.EnumType} value
 * @return {!proto.google.protobuf.FeatureSet} returns this
 */
proto.google.protobuf.FeatureSet.prototype.setEnumType = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FeatureSet} returns this
 */
proto.google.protobuf.FeatureSet.prototype.clearEnumType = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FeatureSet.prototype.hasEnumType = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional RepeatedFieldEncoding repeated_field_encoding = 3;
 * @return {!proto.google.protobuf.FeatureSet.RepeatedFieldEncoding}
 */
proto.google.protobuf.FeatureSet.prototype.getRepeatedFieldEncoding = function () {
  return /** @type {!proto.google.protobuf.FeatureSet.RepeatedFieldEncoding} */jspb.Message.getFieldWithDefault(this, 3, 0);
};

/**
 * @param {!proto.google.protobuf.FeatureSet.RepeatedFieldEncoding} value
 * @return {!proto.google.protobuf.FeatureSet} returns this
 */
proto.google.protobuf.FeatureSet.prototype.setRepeatedFieldEncoding = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FeatureSet} returns this
 */
proto.google.protobuf.FeatureSet.prototype.clearRepeatedFieldEncoding = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FeatureSet.prototype.hasRepeatedFieldEncoding = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Utf8Validation utf8_validation = 4;
 * @return {!proto.google.protobuf.FeatureSet.Utf8Validation}
 */
proto.google.protobuf.FeatureSet.prototype.getUtf8Validation = function () {
  return /** @type {!proto.google.protobuf.FeatureSet.Utf8Validation} */jspb.Message.getFieldWithDefault(this, 4, 0);
};

/**
 * @param {!proto.google.protobuf.FeatureSet.Utf8Validation} value
 * @return {!proto.google.protobuf.FeatureSet} returns this
 */
proto.google.protobuf.FeatureSet.prototype.setUtf8Validation = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FeatureSet} returns this
 */
proto.google.protobuf.FeatureSet.prototype.clearUtf8Validation = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FeatureSet.prototype.hasUtf8Validation = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional MessageEncoding message_encoding = 5;
 * @return {!proto.google.protobuf.FeatureSet.MessageEncoding}
 */
proto.google.protobuf.FeatureSet.prototype.getMessageEncoding = function () {
  return /** @type {!proto.google.protobuf.FeatureSet.MessageEncoding} */jspb.Message.getFieldWithDefault(this, 5, 0);
};

/**
 * @param {!proto.google.protobuf.FeatureSet.MessageEncoding} value
 * @return {!proto.google.protobuf.FeatureSet} returns this
 */
proto.google.protobuf.FeatureSet.prototype.setMessageEncoding = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FeatureSet} returns this
 */
proto.google.protobuf.FeatureSet.prototype.clearMessageEncoding = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FeatureSet.prototype.hasMessageEncoding = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional JsonFormat json_format = 6;
 * @return {!proto.google.protobuf.FeatureSet.JsonFormat}
 */
proto.google.protobuf.FeatureSet.prototype.getJsonFormat = function () {
  return /** @type {!proto.google.protobuf.FeatureSet.JsonFormat} */jspb.Message.getFieldWithDefault(this, 6, 0);
};

/**
 * @param {!proto.google.protobuf.FeatureSet.JsonFormat} value
 * @return {!proto.google.protobuf.FeatureSet} returns this
 */
proto.google.protobuf.FeatureSet.prototype.setJsonFormat = function (value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FeatureSet} returns this
 */
proto.google.protobuf.FeatureSet.prototype.clearJsonFormat = function () {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FeatureSet.prototype.hasJsonFormat = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.FeatureSetDefaults.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.FeatureSetDefaults.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.FeatureSetDefaults.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.FeatureSetDefaults} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.FeatureSetDefaults.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        defaultsList: jspb.Message.toObjectList(msg.getDefaultsList(), proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.toObject, includeInstance),
        minimumEdition: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
        maximumEdition: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.FeatureSetDefaults}
 */
proto.google.protobuf.FeatureSetDefaults.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.FeatureSetDefaults();
  return proto.google.protobuf.FeatureSetDefaults.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.FeatureSetDefaults} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.FeatureSetDefaults}
 */
proto.google.protobuf.FeatureSetDefaults.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault();
        reader.readMessage(value, proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.deserializeBinaryFromReader);
        msg.addDefaults(value);
        break;
      case 4:
        var value = /** @type {!proto.google.protobuf.Edition} */reader.readEnum();
        msg.setMinimumEdition(value);
        break;
      case 5:
        var value = /** @type {!proto.google.protobuf.Edition} */reader.readEnum();
        msg.setMaximumEdition(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.FeatureSetDefaults.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.FeatureSetDefaults.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.FeatureSetDefaults} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.FeatureSetDefaults.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDefaultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.serializeBinaryToWriter);
  }
  f = /** @type {!proto.google.protobuf.Edition} */jspb.Message.getField(message, 4);
  if (f != null) {
    writer.writeEnum(4, f);
  }
  f = /** @type {!proto.google.protobuf.Edition} */jspb.Message.getField(message, 5);
  if (f != null) {
    writer.writeEnum(5, f);
  }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        edition: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        overridableFeatures: (f = msg.getOverridableFeatures()) && proto.google.protobuf.FeatureSet.toObject(includeInstance, f),
        fixedFeatures: (f = msg.getFixedFeatures()) && proto.google.protobuf.FeatureSet.toObject(includeInstance, f)
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault}
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault();
  return proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault}
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 3:
        var value = /** @type {!proto.google.protobuf.Edition} */reader.readEnum();
        msg.setEdition(value);
        break;
      case 4:
        var value = new proto.google.protobuf.FeatureSet();
        reader.readMessage(value, proto.google.protobuf.FeatureSet.deserializeBinaryFromReader);
        msg.setOverridableFeatures(value);
        break;
      case 5:
        var value = new proto.google.protobuf.FeatureSet();
        reader.readMessage(value, proto.google.protobuf.FeatureSet.deserializeBinaryFromReader);
        msg.setFixedFeatures(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {!proto.google.protobuf.Edition} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeEnum(3, f);
  }
  f = message.getOverridableFeatures();
  if (f != null) {
    writer.writeMessage(4, f, proto.google.protobuf.FeatureSet.serializeBinaryToWriter);
  }
  f = message.getFixedFeatures();
  if (f != null) {
    writer.writeMessage(5, f, proto.google.protobuf.FeatureSet.serializeBinaryToWriter);
  }
};

/**
 * optional Edition edition = 3;
 * @return {!proto.google.protobuf.Edition}
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.getEdition = function () {
  return /** @type {!proto.google.protobuf.Edition} */jspb.Message.getFieldWithDefault(this, 3, 0);
};

/**
 * @param {!proto.google.protobuf.Edition} value
 * @return {!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault} returns this
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.setEdition = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault} returns this
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.clearEdition = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.hasEdition = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional FeatureSet overridable_features = 4;
 * @return {?proto.google.protobuf.FeatureSet}
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.getOverridableFeatures = function () {
  return /** @type{?proto.google.protobuf.FeatureSet} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FeatureSet, 4)
  );
};

/**
 * @param {?proto.google.protobuf.FeatureSet|undefined} value
 * @return {!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault} returns this
*/
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.setOverridableFeatures = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault} returns this
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.clearOverridableFeatures = function () {
  return this.setOverridableFeatures(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.hasOverridableFeatures = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional FeatureSet fixed_features = 5;
 * @return {?proto.google.protobuf.FeatureSet}
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.getFixedFeatures = function () {
  return /** @type{?proto.google.protobuf.FeatureSet} */(
    jspb.Message.getWrapperField(this, proto.google.protobuf.FeatureSet, 5)
  );
};

/**
 * @param {?proto.google.protobuf.FeatureSet|undefined} value
 * @return {!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault} returns this
*/
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.setFixedFeatures = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault} returns this
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.clearFixedFeatures = function () {
  return this.setFixedFeatures(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.prototype.hasFixedFeatures = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * repeated FeatureSetEditionDefault defaults = 1;
 * @return {!Array<!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault>}
 */
proto.google.protobuf.FeatureSetDefaults.prototype.getDefaultsList = function () {
  return /** @type{!Array<!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault, 1)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault>} value
 * @return {!proto.google.protobuf.FeatureSetDefaults} returns this
*/
proto.google.protobuf.FeatureSetDefaults.prototype.setDefaultsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault}
 */
proto.google.protobuf.FeatureSetDefaults.prototype.addDefaults = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.FeatureSetDefaults} returns this
 */
proto.google.protobuf.FeatureSetDefaults.prototype.clearDefaultsList = function () {
  return this.setDefaultsList([]);
};

/**
 * optional Edition minimum_edition = 4;
 * @return {!proto.google.protobuf.Edition}
 */
proto.google.protobuf.FeatureSetDefaults.prototype.getMinimumEdition = function () {
  return /** @type {!proto.google.protobuf.Edition} */jspb.Message.getFieldWithDefault(this, 4, 0);
};

/**
 * @param {!proto.google.protobuf.Edition} value
 * @return {!proto.google.protobuf.FeatureSetDefaults} returns this
 */
proto.google.protobuf.FeatureSetDefaults.prototype.setMinimumEdition = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FeatureSetDefaults} returns this
 */
proto.google.protobuf.FeatureSetDefaults.prototype.clearMinimumEdition = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FeatureSetDefaults.prototype.hasMinimumEdition = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional Edition maximum_edition = 5;
 * @return {!proto.google.protobuf.Edition}
 */
proto.google.protobuf.FeatureSetDefaults.prototype.getMaximumEdition = function () {
  return /** @type {!proto.google.protobuf.Edition} */jspb.Message.getFieldWithDefault(this, 5, 0);
};

/**
 * @param {!proto.google.protobuf.Edition} value
 * @return {!proto.google.protobuf.FeatureSetDefaults} returns this
 */
proto.google.protobuf.FeatureSetDefaults.prototype.setMaximumEdition = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.FeatureSetDefaults} returns this
 */
proto.google.protobuf.FeatureSetDefaults.prototype.clearMaximumEdition = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.FeatureSetDefaults.prototype.hasMaximumEdition = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.SourceCodeInfo.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.SourceCodeInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.SourceCodeInfo.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.SourceCodeInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.SourceCodeInfo.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        locationList: jspb.Message.toObjectList(msg.getLocationList(), proto.google.protobuf.SourceCodeInfo.Location.toObject, includeInstance)
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.SourceCodeInfo}
 */
proto.google.protobuf.SourceCodeInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.SourceCodeInfo();
  return proto.google.protobuf.SourceCodeInfo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.SourceCodeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.SourceCodeInfo}
 */
proto.google.protobuf.SourceCodeInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.google.protobuf.SourceCodeInfo.Location();
        reader.readMessage(value, proto.google.protobuf.SourceCodeInfo.Location.deserializeBinaryFromReader);
        msg.addLocation(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.SourceCodeInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.SourceCodeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.SourceCodeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.SourceCodeInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLocationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, proto.google.protobuf.SourceCodeInfo.Location.serializeBinaryToWriter);
  }
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.SourceCodeInfo.Location.repeatedFields_ = [1, 2, 6];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.SourceCodeInfo.Location.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.SourceCodeInfo.Location.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.SourceCodeInfo.Location} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.SourceCodeInfo.Location.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        pathList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
        spanList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
        leadingComments: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        trailingComments: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
        leadingDetachedCommentsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.SourceCodeInfo.Location}
 */
proto.google.protobuf.SourceCodeInfo.Location.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.SourceCodeInfo.Location();
  return proto.google.protobuf.SourceCodeInfo.Location.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.SourceCodeInfo.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.SourceCodeInfo.Location}
 */
proto.google.protobuf.SourceCodeInfo.Location.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var values = /** @type {!Array<number>} */reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()];
        for (var i = 0; i < values.length; i++) {
          msg.addPath(values[i]);
        }
        break;
      case 2:
        var values = /** @type {!Array<number>} */reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()];
        for (var i = 0; i < values.length; i++) {
          msg.addSpan(values[i]);
        }
        break;
      case 3:
        var value = /** @type {string} */reader.readString();
        msg.setLeadingComments(value);
        break;
      case 4:
        var value = /** @type {string} */reader.readString();
        msg.setTrailingComments(value);
        break;
      case 6:
        var value = /** @type {string} */reader.readString();
        msg.addLeadingDetachedComments(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.SourceCodeInfo.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.SourceCodeInfo.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.SourceCodeInfo.Location.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPathList();
  if (f.length > 0) {
    writer.writePackedInt32(1, f);
  }
  f = message.getSpanList();
  if (f.length > 0) {
    writer.writePackedInt32(2, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeString(3, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 4);
  if (f != null) {
    writer.writeString(4, f);
  }
  f = message.getLeadingDetachedCommentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(6, f);
  }
};

/**
 * repeated int32 path = 1;
 * @return {!Array<number>}
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.getPathList = function () {
  return /** @type {!Array<number>} */jspb.Message.getRepeatedField(this, 1);
};

/**
 * @param {!Array<number>} value
 * @return {!proto.google.protobuf.SourceCodeInfo.Location} returns this
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.setPathList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.SourceCodeInfo.Location} returns this
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.addPath = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.SourceCodeInfo.Location} returns this
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.clearPathList = function () {
  return this.setPathList([]);
};

/**
 * repeated int32 span = 2;
 * @return {!Array<number>}
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.getSpanList = function () {
  return /** @type {!Array<number>} */jspb.Message.getRepeatedField(this, 2);
};

/**
 * @param {!Array<number>} value
 * @return {!proto.google.protobuf.SourceCodeInfo.Location} returns this
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.setSpanList = function (value) {
  return jspb.Message.setField(this, 2, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.SourceCodeInfo.Location} returns this
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.addSpan = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.SourceCodeInfo.Location} returns this
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.clearSpanList = function () {
  return this.setSpanList([]);
};

/**
 * optional string leading_comments = 3;
 * @return {string}
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.getLeadingComments = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 3, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.SourceCodeInfo.Location} returns this
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.setLeadingComments = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.SourceCodeInfo.Location} returns this
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.clearLeadingComments = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.hasLeadingComments = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional string trailing_comments = 4;
 * @return {string}
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.getTrailingComments = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 4, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.SourceCodeInfo.Location} returns this
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.setTrailingComments = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.SourceCodeInfo.Location} returns this
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.clearTrailingComments = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.hasTrailingComments = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * repeated string leading_detached_comments = 6;
 * @return {!Array<string>}
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.getLeadingDetachedCommentsList = function () {
  return /** @type {!Array<string>} */jspb.Message.getRepeatedField(this, 6);
};

/**
 * @param {!Array<string>} value
 * @return {!proto.google.protobuf.SourceCodeInfo.Location} returns this
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.setLeadingDetachedCommentsList = function (value) {
  return jspb.Message.setField(this, 6, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.SourceCodeInfo.Location} returns this
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.addLeadingDetachedComments = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.SourceCodeInfo.Location} returns this
 */
proto.google.protobuf.SourceCodeInfo.Location.prototype.clearLeadingDetachedCommentsList = function () {
  return this.setLeadingDetachedCommentsList([]);
};

/**
 * repeated Location location = 1;
 * @return {!Array<!proto.google.protobuf.SourceCodeInfo.Location>}
 */
proto.google.protobuf.SourceCodeInfo.prototype.getLocationList = function () {
  return /** @type{!Array<!proto.google.protobuf.SourceCodeInfo.Location>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.SourceCodeInfo.Location, 1)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.SourceCodeInfo.Location>} value
 * @return {!proto.google.protobuf.SourceCodeInfo} returns this
*/
proto.google.protobuf.SourceCodeInfo.prototype.setLocationList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.google.protobuf.SourceCodeInfo.Location=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.SourceCodeInfo.Location}
 */
proto.google.protobuf.SourceCodeInfo.prototype.addLocation = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.protobuf.SourceCodeInfo.Location, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.SourceCodeInfo} returns this
 */
proto.google.protobuf.SourceCodeInfo.prototype.clearLocationList = function () {
  return this.setLocationList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.GeneratedCodeInfo.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.GeneratedCodeInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.GeneratedCodeInfo.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.GeneratedCodeInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.GeneratedCodeInfo.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        annotationList: jspb.Message.toObjectList(msg.getAnnotationList(), proto.google.protobuf.GeneratedCodeInfo.Annotation.toObject, includeInstance)
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.GeneratedCodeInfo}
 */
proto.google.protobuf.GeneratedCodeInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.GeneratedCodeInfo();
  return proto.google.protobuf.GeneratedCodeInfo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.GeneratedCodeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.GeneratedCodeInfo}
 */
proto.google.protobuf.GeneratedCodeInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.google.protobuf.GeneratedCodeInfo.Annotation();
        reader.readMessage(value, proto.google.protobuf.GeneratedCodeInfo.Annotation.deserializeBinaryFromReader);
        msg.addAnnotation(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.GeneratedCodeInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.GeneratedCodeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.GeneratedCodeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.GeneratedCodeInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAnnotationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, proto.google.protobuf.GeneratedCodeInfo.Annotation.serializeBinaryToWriter);
  }
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.toObject = function (opt_includeInstance) {
    return proto.google.protobuf.GeneratedCodeInfo.Annotation.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.google.protobuf.GeneratedCodeInfo.Annotation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.google.protobuf.GeneratedCodeInfo.Annotation.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        pathList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
        sourceFile: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        begin: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        end: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
        semantic: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f
      };
    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation}
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.google.protobuf.GeneratedCodeInfo.Annotation();
  return proto.google.protobuf.GeneratedCodeInfo.Annotation.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.google.protobuf.GeneratedCodeInfo.Annotation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation}
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var values = /** @type {!Array<number>} */reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()];
        for (var i = 0; i < values.length; i++) {
          msg.addPath(values[i]);
        }
        break;
      case 2:
        var value = /** @type {string} */reader.readString();
        msg.setSourceFile(value);
        break;
      case 3:
        var value = /** @type {number} */reader.readInt32();
        msg.setBegin(value);
        break;
      case 4:
        var value = /** @type {number} */reader.readInt32();
        msg.setEnd(value);
        break;
      case 5:
        var value = /** @type {!proto.google.protobuf.GeneratedCodeInfo.Annotation.Semantic} */reader.readEnum();
        msg.setSemantic(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.google.protobuf.GeneratedCodeInfo.Annotation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.google.protobuf.GeneratedCodeInfo.Annotation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPathList();
  if (f.length > 0) {
    writer.writePackedInt32(1, f);
  }
  f = /** @type {string} */jspb.Message.getField(message, 2);
  if (f != null) {
    writer.writeString(2, f);
  }
  f = /** @type {number} */jspb.Message.getField(message, 3);
  if (f != null) {
    writer.writeInt32(3, f);
  }
  f = /** @type {number} */jspb.Message.getField(message, 4);
  if (f != null) {
    writer.writeInt32(4, f);
  }
  f = /** @type {!proto.google.protobuf.GeneratedCodeInfo.Annotation.Semantic} */jspb.Message.getField(message, 5);
  if (f != null) {
    writer.writeEnum(5, f);
  }
};

/**
 * @enum {number}
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.Semantic = {
  NONE: 0,
  SET: 1,
  ALIAS: 2
};

/**
 * repeated int32 path = 1;
 * @return {!Array<number>}
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.getPathList = function () {
  return /** @type {!Array<number>} */jspb.Message.getRepeatedField(this, 1);
};

/**
 * @param {!Array<number>} value
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation} returns this
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.setPathList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation} returns this
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.addPath = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation} returns this
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.clearPathList = function () {
  return this.setPathList([]);
};

/**
 * optional string source_file = 2;
 * @return {string}
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.getSourceFile = function () {
  return /** @type {string} */jspb.Message.getFieldWithDefault(this, 2, "");
};

/**
 * @param {string} value
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation} returns this
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.setSourceFile = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation} returns this
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.clearSourceFile = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.hasSourceFile = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional int32 begin = 3;
 * @return {number}
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.getBegin = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 3, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation} returns this
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.setBegin = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation} returns this
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.clearBegin = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.hasBegin = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional int32 end = 4;
 * @return {number}
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.getEnd = function () {
  return /** @type {number} */jspb.Message.getFieldWithDefault(this, 4, 0);
};

/**
 * @param {number} value
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation} returns this
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.setEnd = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation} returns this
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.clearEnd = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.hasEnd = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional Semantic semantic = 5;
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation.Semantic}
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.getSemantic = function () {
  return /** @type {!proto.google.protobuf.GeneratedCodeInfo.Annotation.Semantic} */jspb.Message.getFieldWithDefault(this, 5, 0);
};

/**
 * @param {!proto.google.protobuf.GeneratedCodeInfo.Annotation.Semantic} value
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation} returns this
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.setSemantic = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation} returns this
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.clearSemantic = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.google.protobuf.GeneratedCodeInfo.Annotation.prototype.hasSemantic = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * repeated Annotation annotation = 1;
 * @return {!Array<!proto.google.protobuf.GeneratedCodeInfo.Annotation>}
 */
proto.google.protobuf.GeneratedCodeInfo.prototype.getAnnotationList = function () {
  return /** @type{!Array<!proto.google.protobuf.GeneratedCodeInfo.Annotation>} */(
    jspb.Message.getRepeatedWrapperField(this, proto.google.protobuf.GeneratedCodeInfo.Annotation, 1)
  );
};

/**
 * @param {!Array<!proto.google.protobuf.GeneratedCodeInfo.Annotation>} value
 * @return {!proto.google.protobuf.GeneratedCodeInfo} returns this
*/
proto.google.protobuf.GeneratedCodeInfo.prototype.setAnnotationList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.google.protobuf.GeneratedCodeInfo.Annotation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.GeneratedCodeInfo.Annotation}
 */
proto.google.protobuf.GeneratedCodeInfo.prototype.addAnnotation = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.protobuf.GeneratedCodeInfo.Annotation, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.google.protobuf.GeneratedCodeInfo} returns this
 */
proto.google.protobuf.GeneratedCodeInfo.prototype.clearAnnotationList = function () {
  return this.setAnnotationList([]);
};

/**
 * @enum {number}
 */
proto.google.protobuf.Edition = {
  EDITION_UNKNOWN: 0,
  EDITION_LEGACY: 900,
  EDITION_PROTO2: 998,
  EDITION_PROTO3: 999,
  EDITION_2023: 1000,
  EDITION_2024: 1001,
  EDITION_1_TEST_ONLY: 1,
  EDITION_2_TEST_ONLY: 2,
  EDITION_99997_TEST_ONLY: 99997,
  EDITION_99998_TEST_ONLY: 99998,
  EDITION_99999_TEST_ONLY: 99999,
  EDITION_MAX: 2147483647
};
goog.object.extend(exports, proto.google.protobuf);