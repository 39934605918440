export const environment = {
  production: false,
  firebase: {
    projectId: 'raiden-console-web-dev',
    appId: '1:705427966898:web:902315ea36b0dd376332ab',
    storageBucket: 'raiden-console-web-dev.appspot.com',
    apiKey: 'AIzaSyCFm2_dV_cVOSB6BOiGINUHDCqvYPc-T4M',
    authDomain: 'raiden-console-web-dev.firebaseapp.com',
    messagingSenderId: '705427966898',
    measurementId: 'G-RD5BE5CZX2',
  },
  apiUrl: 'https://consolewebapi-dev.raiden.energy'
};
