// package: org.console.web_api.grpc
// file: console_webapi.proto

var console_webapi_pb = require("./console_webapi_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var AppApi = (function () {
  function AppApi() {}
  AppApi.serviceName = "org.console.web_api.grpc.AppApi";
  return AppApi;
}());

AppApi.Ping = {
  methodName: "Ping",
  service: AppApi,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: console_webapi_pb.PingResponse
};

AppApi.SignIn = {
  methodName: "SignIn",
  service: AppApi,
  requestStream: false,
  responseStream: false,
  requestType: console_webapi_pb.SignInRequest,
  responseType: console_webapi_pb.SignInResponse
};

AppApi.CreateBusiness = {
  methodName: "CreateBusiness",
  service: AppApi,
  requestStream: false,
  responseStream: false,
  requestType: console_webapi_pb.CreateBusinessRequest,
  responseType: console_webapi_pb.CreateBusinessResponse
};

AppApi.UpdateBusiness = {
  methodName: "UpdateBusiness",
  service: AppApi,
  requestStream: false,
  responseStream: false,
  requestType: console_webapi_pb.UpdateBusinessRequest,
  responseType: google_protobuf_empty_pb.Empty
};

AppApi.DeleteBusiness = {
  methodName: "DeleteBusiness",
  service: AppApi,
  requestStream: false,
  responseStream: false,
  requestType: console_webapi_pb.DeleteBusinessRequest,
  responseType: google_protobuf_empty_pb.Empty
};

AppApi.LogEntries = {
  methodName: "LogEntries",
  service: AppApi,
  requestStream: false,
  responseStream: false,
  requestType: console_webapi_pb.LogEntriesRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.AppApi = AppApi;

function AppApiClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

AppApiClient.prototype.ping = function ping(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AppApi.Ping, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AppApiClient.prototype.signIn = function signIn(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AppApi.SignIn, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AppApiClient.prototype.createBusiness = function createBusiness(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AppApi.CreateBusiness, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AppApiClient.prototype.updateBusiness = function updateBusiness(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AppApi.UpdateBusiness, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AppApiClient.prototype.deleteBusiness = function deleteBusiness(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AppApi.DeleteBusiness, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AppApiClient.prototype.logEntries = function logEntries(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AppApi.LogEntries, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.AppApiClient = AppApiClient;

